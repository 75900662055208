import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router';
import store from './store';
import VueClipboard from 'vue-clipboard2';

import './assets/css/reset.css';

// 全局引入请求方法
import { api } from './assets/js/api.js';
Vue.use(ElementUI);
Vue.use(VueClipboard);
Vue.prototype.$ = api;
Vue.config.productionTip = false;
var red='red';
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')