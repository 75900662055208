<template>
	<div class="orderAdmin">
		<div class="addContainer" v-if="isAdd">
			<div class="box">
				<div class="title">
					<div class="closeBtn" @click.stop="isAdd=false">
						x
					</div>
				</div>

				<el-select v-model="user_selected" clearable filterable="true" placeholder="请选择用户" class="users">
					<el-option v-for="item in users" :key="item.id" :label="'ID: '+item.id+' |昵称: '+item.nickname"
						:value="item.id">

						<el-avatar :src="item.avatar" class="avatar" :size="'small'"></el-avatar>
						<span class="info">
							{{'ID: '+item.id+' |昵称: '+item.nickname}}
						</span>

					</el-option>
				</el-select>
				<el-select v-model="subject_selected" clearable placeholder="请选择题库" class="subjects"
					@change="getOptions">
					<el-option v-for="item in subjects" :key="item.id" :label="'ID: '+item.id+' |名称: '+item.title"
						:value="item.id">
					</el-option>
				</el-select>

				<el-select v-model="option_selected" clearable placeholder="请选择激活码类型" class="options">
					<el-option v-for="item in options" :key="item.id" :label="item.title+' '+item.price"
						:value="item.id">
					</el-option>
				</el-select>


				<el-col :span="12">
					<el-date-picker v-model="date_from_selected" value-format="timestamp" type="date" placeholder="选择开始日期" class="fromDate">
					</el-date-picker>
				</el-col>
				<el-col :span="12">
					<el-date-picker v-model="date_to_selected" value-format="timestamp" type="date" placeholder="选择过期日期" class="toDate">
					</el-date-picker>
				</el-col>

				<el-button type="danger" round class="createOrder" @click.stop="addNewOrder">创建</el-button>
			</div>
		</div>
		<top-bar></top-bar>
		<div class="orderContent">
			<left-bar></left-bar>
			<div class="orderRight">
				<div class="rightCenter">
					<p>订单管理</p>
					<div class="centerContent">
						<div class="title">

							<div class="orderStyle" @click="orderStyleBtn">
								{{orderStyle}}
								<div class="arrow"></div>
								<div class="orderStyleBar" v-show="orderStyleBar">
									<div class="styleItem" v-for="(item,index) in orderStyleList"
										@click.stop="orderItemBtn(item,index)" :key="index"
										:class="{selectItem:selectItem==index?true:false}">
										{{item.val}}
									</div>
								</div>
							</div>


							<div class="selectSubject" @click="selectSubjectBtn">
								{{subjectName}} <span v-if="subjectName==''">库名称</span>
								<div class="arrow"></div>
								<div class="subjectBar" v-show="subjectBar">
									<div class="onSubject" :class="{selectItem:selectSubject==-1?true:false}"
										@click="onBtn">空</div>
									<div class="subjectItem" v-for="(item,index) in subjectList" :key="index"
										@click.stop="subjectItemBtn(item,index)"
										:class="{selectItem:selectSubject==index?true:false}">

										{{item.title}}
									</div>
								</div>
							</div>


							<div class="selectUser">
								<input type="text" placeholder="请输入用户名" @input="searchUserBtn" v-model="userName"
									@click="cancaelBtn">
								<div class="userBar" v-show="userBar">
									<div class="userItem" v-for="(item,index) in userList" :key="index"
										@click="userItemBtn(item)">
										<div class="userImg">
											<img :src="item.avatar==''?image:item.avatar" alt="">
										</div>
										<div class="userText">{{item.nickname}}</div>
									</div>
								</div>
							</div>


							<div class="searchBar">
								<input type="text" placeholder="请输入订单编号" v-model="searchText">
								<!-- <div class="search" @click="searchBtn"></div> -->
							</div>

							<div class="calendar">
								<div class="calendarLef">
									<div class="block">
										<el-date-picker class="picker1" v-model="value1" type="date" placeholder="选择日期"
											value-format="timestamp">
										</el-date-picker>
									</div>
								</div>
								<div class="line"></div>
								<div class="calendarRight">
									<div class="block">
										<el-date-picker class="picker1" v-model="value2" type="date" placeholder="选择日期"
											value-format="timestamp">
										</el-date-picker>
									</div>
								</div>
							</div>
							<div class="addBtn" @click="addOrder">
								开通题库
							</div>
							<div class="searchBtn" @click="searchBtn" @keyup.enter="searchBtn">搜索</div>
						</div>
						<div class="listTitle">
							<div class="ID">ID</div>
							<div class="userId">用户昵称</div>
							<div class="orderNumber">订单编号</div>
							<div class="orderName">商品名称</div>
							<div class="payTime">支付时间</div>
							<div class="money">金额(元)</div>
							<div class="payStyle">支付方式</div>
							<div class="buyStyle">购买状态</div>
							<div class="stopTime">截止时间</div>
							<div class="operate">操作</div>
						</div>
						<div class="item" v-for="(item,index) in orderList" :key="index">
							<div class="itemId">{{index+1+(currentPage-1)*7}}</div>
							<div class="itemuserID">
								<img :src="item.user.avatar==''?image:item.user.avatar" alt="">
								<span>{{item.user.nickname}}</span>
							</div>
							<div class="itemOrder" @mouseup="copyOrderno(item.order_no)"
								v-clipboard:copy="item.order_no" :title="item.order_no">{{item.order_no}}</div>
							<div class="itemName" :title="item.goods_name">{{item.goods_name}}</div>
							<div class="itemTime">{{item.created_at | formatDate}}</div>
							<div class="itemMoney">{{item.order_money}}</div>
							<div class="itemPuyStyle">{{item.pay_type==1?'微信':'激活码'}}</div>
							<div class="itemStyle1" v-if="item.status==1?true:false">未支付</div>
							<div class="itemStyle2" v-if="item.status==2?true:false">已完成</div>
							<div class="itemStyle3" v-if="item.status==3?true:false">已取消</div>
							<div class="itemStyle4" v-if="item.status==4?true:false">已退款</div>
							<div class="stopTime"> {{item.deadline}}</div>
							<!-- <div class="edit"></div> -->
							<div class="delete"></div>
						</div>
						<page-bar :currentPage="currentPage" :total="total" :count="count" @jumpNumber="jumpNumber"
							@nextPage="nextPage" @prePage="prePage" @pageTo="pageTo"></page-bar>
						<div class="loadingPanel" :style="{'opacity':loadingOpacity}"
							v-show="loadingOpacity<=0?false:true">
							<img src="../assets/img/home/onLoad.gif" />
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import topBar from '../components/nav.vue'
	import leftBar from '../components/left.vue'
	import pageBar from '../components/pagebarContainer.vue'
	import successBar from '../components/success.vue'
	import errorBar from '../components/error.vue'
	export default {
		components: {
			topBar,
			leftBar,
			pageBar,
			successBar,
			errorBar
		},
		filters: {
			formatDate(time) {
				var date = new Date(time);
				var y = date.getFullYear();
				var MM = date.getMonth() + 1;
				MM = MM < 10 ? "0" + MM : MM;
				var d = date.getDate();
				d = d < 10 ? "0" + d : d;
				var h = date.getHours();
				h = h < 10 ? "0" + h : h;
				var m = date.getMinutes();
				m = m < 10 ? "0" + m : m;
				var s = date.getSeconds();
				s = s < 10 ? "0" + s : s;
				return y + "-" + MM + "-" + d + " " + h + ":" + m;
			}
		},
		data() {
			return {
				orderStyleList: [{
					val: '未支付',
					id: 1
				}, {
					val: '已完成',
					id: 2
				}, {
					val: '已取消',
					id: 3
				}, {
					val: '已退款',
					id: 4
				}, {
					val: '所有',
					id: 5
				}],
				selectItem: 4,
				orderStyle: '所有',
				orderStyleBar: false,
				status: 5,
				currentPage: 1,
				total: 0,
				count: 7,
				orderList: [],
				selectSubject: -1,
				subjectList: [],
				subjectName: '',
				subjectId: '',
				subjectBar: false,
				image: require('../assets/img/home/userImg.png'),
				searchText: '',
				userName: '',
				userList: [],
				userBar: false,
				userId: '',
				loadingOpacity: 1, //loading 透明度
				value1: '',
				value2: '',
				isAdd: false,
				users: [],
				subjects: [],
				options: [],
				subject_selected: null,
				user_selected: null,
				date_from_selected: null,
				date_to_selected: null,
				option_selected: null
			}
		},
		methods: {
			getOptions() {
				var param = new FormData();
				param.append('subject_id', this.subject_selected);
				this.$.sunjectData(param).then(res => {

					this.options = res.subjectInfo.options;
				});
			},
			addNewOrder() {
				// console.log(this.subject_selected);
				// console.log(this.user_selected);
				// console.log(this.date_from_selected);
				// console.log(this.date_to_selected);
				var flag1 = this.subject_selected != null ? true : false;
				var flag2 = this.user_selected != null ? true : false;
				var flag3 = this.date_from_selected != null ? true : false;
				var flag4 = this.date_to_selected != null ? true : false;
				var flag5 = this.option_selected != null ? true : false;
				if (flag1 && flag2 && flag3 && flag4 && flag5) {
					var param = new FormData();
					param.append('subject_id', this.subject_selected);
					param.append('user_id', this.user_selected);
					param.append('option_id', this.option_selected);
					param.append('pay_time', this.date_from_selected/1000);
					param.append('deadline', this.date_to_selected/1000);
					
					this.$.addUserOrderByAdmin(param).then((res) => {
						if(res.code==200&&res.msg=='ok'){
							this.isAdd=false;
							this.orderListBtn();
						}
					});

				} else {
					this.$notify.error({
						title: '消息',
						message: '参数不全。'
					});
				}


			},
			addOrder() {
				this.isAdd = true;
			},
			copyOrderno(orderNo) {
				this.$notify.info({
					title: '消息',
					message: '订单号已经拷贝到粘贴板。'
				});

			},
			//搜索用户名
			searchUserBtn() {
				var param = new FormData();
				param.append('nickname', this.userName);
				this.$.searchUser(param).then(res => {
					console.log(res, '用户')
					this.userList = res.userInfo;
					this.userBar = true;
				})
				if (this.userName == '') {
					console.log(123456)
					this.userBar = false;
					this.userId = '';
				}
			},
			cancaelBtn() {
				this.userBar = false;
			},
			userItemBtn(item) {
				this.userName = item.nickname;
				this.userId = item.id;
				this.userBar = false;
			},
			orderItemBtn(item, index) {
				this.selectItem = index;
				this.orderStyle = item.val;
				this.orderStyleBar = false;
				this.status = item.id;
				this.orderListBtn();
				console.log(1);
			},
			orderStyleBtn() {
				this.orderStyleBar = !this.orderStyleBar;
			},
			//获取订单列表
			orderListBtn() {
				var self = this;

				var param = new FormData();
				param.append('status', this.status);
				param.append('page', this.currentPage);
				param.append('order_no', this.searchText);
				param.append('subject_id', this.subjectId);
				param.append('user_id', this.userId);
				param.append('count', this.count);
				param.append('from', this.value1 == null ? '' : this.value1);
				param.append('to', this.value2 == null ? '' : this.value2);
				this.$.orderList(param).then(res => {
					this.loadingOpacity = 1;
					console.log(res, '订单信息');
					this.total = res.ordersInfo.total;
					this.orderList = res.ordersInfo.data;
					console.log(this.orderList, 'dsfd96325871');
					var value = 100;
					var timer = setInterval(function() {
						value -= 10;
						self.loadingOpacity = value / 100;
						if (value <= 0) {
							clearInterval(timer);
						}
					}, 10);
				})
			},

			//选择库
			selectSubjectBtn() {
				this.subjectBar = !this.subjectBar
			},
			subjectItemBtn(item, index) {
				this.subjectName = item.title;
				this.selectSubject = index;
				this.subjectId = item.id;
				this.subjectBar = false;
				this.orderListBtn();
			},
			onBtn() {
				this.subjectName = '';
				this.subjectId = '';
				this.orderListBtn();
			},
			nextPage() {
				this.currentPage++;
				if (this.currentPage >= this.pageSize) {
					this.currentPage = this.pageSize;
				}
				this.orderListBtn();
			},
			pageTo(index) {
				this.currentPage = index;
				this.orderListBtn();

			},
			prePage() {
				this.currentPage--;
				if (this.currentPage <= 1) {
					this.currentPage = 1;
				}
				this.orderListBtn();
			},
			jumpNumber(val) {
				if (val == '') {
					val = 1
				}
				if (val >= Math.ceil(this.total / this.count)) {
					val = Math.ceil(this.total / this.count)
				}
				this.currentPage = val / 1;
				console.log(this.currentPage, '456123')
				this.orderListBtn()
			},
			//搜索
			searchBtn() {
				this.orderListBtn();
				console.log(this.orderList, 'dsfdsfdsfdsfdsf')
				console.log(this.value1, this.value2, '=======================')
			},
		},
		mounted() {
			this.orderListBtn();
			//获取题库列表
			var param = new FormData();
			param.append('page', 1);
			param.append('count', 10);
			this.$.subjectList(param).then(res => {

				var total = res.subjectInfo.total;

				var formData = new FormData();
				formData.append('page', 1);
				formData.append('count', total);
				this.$.subjectList(formData).then(res => {

					this.subjectList = res.subjectInfo.data;
					this.subjects = res.subjectInfo.data;
				})


			})
			var param = new FormData();
			param.append('page', 1);
			param.append('count', 9999)
			this.$.userList(param).then(res => {

				this.users = res.userInfo.data;

			})


		},
		created() {
			var self = this;
			document.onkeydown = function(e) {
				if (e.keyCode == 13) {
					self.searchBtn();
				}
			}
		}
	}
</script>

<style lang='less' scoped>
	.orderAdmin {
		width: 100%;
		height: 100vh;
		background-color: #f2f2f2;

		.addContainer {
			position: absolute;
			width: 100%;
			height: 100%;
			margin: 0 auto;
			background-color: #00000022;
			z-index: 99;

			.box {
				width: 600px;
				height: 400px;
				border-radius: 4px;
				background-color: white;
				margin: 0 auto;
				margin-top: 108px;

				.title {
					position: relative;
					height: 50px;
					widths: 100%;
					border-bottom: 1px solid silver;

					.closeBtn {
						font-size: 16px;
						width: 30px;
						height: 30px;
						border-radius: 50%;
						line-height: 28px;
						border: 1px solid silver;
						position: absolute;
						top: 9.5px;
						right: 10px;
						color: #F15764;
						cursor: pointer;
					}
				}


				.users,
				.subjects,
				.options,
				.fromDate,
				.toDate {
					display: block;
					margin: 10px;

				}



				.createOrder {
					width: 200px;
				}



			}

		}


		.orderContent {
			width: 100%;
			// height: 100vh;
			margin-top: 60px;
			background-color: #F2F2F2;
			display: flex;

			.orderRight {
				flex: 1;
				height: 100%;
				margin-left: 195px;



				.rightCenter {
					width: 1570px;
					height: 100%;
					margin: auto;

					p {
						font-size: 20px;
						color: #333;
						text-align: left;
						margin-top: 30px;
						margin-bottom: 20px;
					}

					.centerContent {
						width: 1570px;
						height: 902px;
						background-color: #ffff;
						border-radius: 10px;
						margin-top: 14px;
						position: relative;

						.title {
							width: 1490px;
							height: 107px;
							margin: auto;
							border-bottom: 1px solid #ccc;



							.searchBar {
								width: 238px;
								height: 100%;
								float: left;
								margin-left: 35px;
								position: relative;

								input {
									width: 100%;
									height: 44px;
									border: 1px solid #ccc;
									box-sizing: border-box;
									border-radius: 2px;
									outline: none;
									position: absolute;
									top: 50%;
									left: 0;
									transform: translateY(-50%);
									font-size: 18px;
									text-indent: 22px;
								}

								.search {
									width: .24rem;
									height: .24rem;
									background-image: url('../assets/img/home/search.png');
									background-repeat: no-repeat;
									background-size: 100% 100%;
									position: absolute;
									top: 50%;
									left: 341px;
									transform: translateY(-50%);
									cursor: pointer;
								}
							}

							.selectSubject {
								width: 167px;
								height: 44px;
								border: 1px solid #ccc;
								border-radius: 4px;
								float: left;
								margin-top: 31px;
								position: relative;
								cursor: pointer;
								font-size: 18px;
								color: #333;
								line-height: 44px;
								text-align: left;
								text-indent: 20px;
								margin-left: 30px;

								span {
									color: #ccc;
								}

								.arrow {
									width: 16px;
									height: 100%;
									background-image: url('../assets/img/home/arrow.png');
									background-repeat: no-repeat;
									background-size: 100% 8px;
									background-position: center;
									position: absolute;
									right: 15px;
									top: 0;
								}

								.subjectBar {
									width: 100%;
									height: 200px;
									background-color: #fff;
									position: absolute;
									top: 46px;
									left: 0;
									border-radius: 4px;
									overflow: hidden;
									box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
									overflow: auto;

									.onSubject {
										font-size: 16px;
										color: #999;
										line-height: 30px;
										margin-top: 2px;
										text-align: left;
										text-indent: 20px;
									}

									.selectItem {
										background-color: rgba(241, 87, 100, .2);
										color: #F15764;
									}

									.subjectItem {
										width: 100%;
										width: 100%;
										height: 30px;
										font-size: 16px;
										color: #333;
										line-height: 30px;
										margin-top: 2px;
										text-align: left;
										text-indent: 20px;
									}

									.selectItem {
										background-color: rgba(241, 87, 100, .2);
										color: #F15764;
									}
								}

								::-webkit-scrollbar {
									width: 0;
								}
							}

							.orderStyle {
								width: 146px;
								height: 44px;
								border: 1px solid #ccc;
								border-radius: 4px;
								float: left;
								margin-top: 31px;
								position: relative;
								cursor: pointer;
								font-size: 18px;
								color: #333;
								line-height: 44px;
								text-align: left;
								text-indent: 20px;

								.arrow {
									width: 16px;
									height: 100%;
									background-image: url('../assets/img/home/arrow.png');
									background-repeat: no-repeat;
									background-size: 100% 8px;
									background-position: center;
									position: absolute;
									right: 15px;
									top: 0;
								}

								.orderStyleBar {
									width: 100%;
									height: 160px;
									background-color: #fff;
									position: absolute;
									top: 46px;
									left: 0;
									border-radius: 4px;
									overflow: hidden;
									box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);

									.styleItem {
										width: 100%;
										height: 30px;
										font-size: 16px;
										color: #333;
										line-height: 30px;
										margin-top: 2px;
									}

									.selectItem {
										background-color: rgba(241, 87, 100, .2);
										color: #F15764;
									}
								}
							}

							.selectUser {
								width: 190px;
								height: 100%;
								float: left;
								margin-left: 30px;
								position: relative;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;

								input {
									width: 100%;
									height: 44px;
									border: 1px solid #ccc;
									box-sizing: border-box;
									border-radius: 4px;
									outline: none;
									position: absolute;
									top: 50%;
									left: 0;
									transform: translateY(-50%);
									font-size: 18px;
									text-indent: 22px;
									cursor: pointer;
								}

								.userBar {
									width: 100%;
									height: 200px;
									background-color: #fff;
									position: absolute;
									top: 75px;
									left: 0;
									border-radius: 4px;
									overflow: hidden;
									box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
									overflow: auto;

									.userItem {
										width: 100%;
										height: 40px;
										display: flex;
										margin-top: 10px;

										.userImg {
											width: 30px;
											height: 30px;
											border-radius: 50%;
											float: left;
											position: relative;
											margin-left: 20px;
											margin-top: 5px;

											img {
												width: 100%;
												height: 100%;
												border-radius: 50%;
												position: absolute;
												top: 0;
												left: 0;
											}
										}

										.userText {
											margin-left: 20px;
											flex: 1;
											font-size: 16px;
											color: #333;
											line-height: 40px;
											text-align: left;
											text-overflow: ellipsis;
											overflow: hidden;
											white-space: nowrap;
											cursor: pointer;
										}
									}
								}

								::-webkit-scrollbar {
									width: 0;
								}
							}

							.calendar {
								width: 360px;
								height: 44px;
								margin-top: 33px;
								position: relative;
								float: left;
								margin-left: 30px;


								.calendarLef,
								.calendarRight {
									width: 158px;
									height: 100%;
									float: left;

									.block {
										width: 100%;
										height: 100%;
										position: relative;

										.picker1 {
											width: 100%;
											height: 100%;
											position: absolute;
											top: 0;
											left: 0;
										}
									}
								}

								.line {
									width: 29px;
									height: 1px;
									background-color: #707070;
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
								}

								.calendarRight {
									float: right;
								}
							}

							.searchBtn {
								width: 125px;
								height: 44px;
								background-color: #F15764;
								margin-top: 31px;
								float: right;
								color: #fff;
								font-size: 18px;
								line-height: 44px;
								border-radius: 4px;
								cursor: pointer;
								margin-right: 10px;
							}

							.addBtn {
								width: 100px;
								height: 44px;
								text-align: center;
								font-size: 18px;
								float: right;
								background-color: #F15764;
								color: white;
								margin-top: 31px;
								border-radius: 4px;
								cursor: pointer;
								line-height: 44px;
							}
						}

						.listTitle {
							width: 1490px;
							height: 60px;
							background-color: #F2F2F2;
							margin: 36px auto;

							.ID {
								font-size: 16px;
								color: #333;
								line-height: 60px;
								margin-left: 26px;
								float: left;
								cursor: pointer;
							}

							.userId {
								font-size: 16px;
								color: #333;
								line-height: 60px;
								margin-left: 60px;
								float: left;
								cursor: pointer;
							}

							.orderNumber {
								font-size: 16px;
								color: #333;
								line-height: 60px;
								margin-left: 83px;
								float: left;
								cursor: pointer;
							}

							.orderName {
								font-size: 16px;
								color: #333;
								line-height: 60px;
								margin-left: 190px;
								float: left;
								cursor: pointer;
							}

							.payTime {
								font-size: 16px;
								color: #333;
								line-height: 60px;
								margin-left: 118px;
								float: left;
								cursor: pointer;
							}

							.money {
								font-size: 16px;
								color: #333;
								line-height: 60px;
								margin-left: 73px;
								float: left;
								cursor: pointer;
							}

							.payStyle {
								font-size: 16px;
								color: #333;
								line-height: 60px;
								margin-left: 60px;
								float: left;
								cursor: pointer;
							}

							.buyStyle {
								font-size: 16px;
								color: #333;
								line-height: 60px;
								margin-left: 70px;
								float: left;
								cursor: pointer;
							}

							.stopTime {
								font-size: 16px;
								color: #333;
								line-height: 60px;
								margin-left: 87px;
								float: left;
								cursor: pointer;
							}

							.operate {
								font-size: 16px;
								color: #333;
								line-height: 60px;
								margin-left: 106px;
								float: left;
								cursor: pointer;
							}
						}

						.item {
							width: 1490px;
							height: 66px;
							margin: 12px auto 18px;

							.itemId {
								width: 68px;
								height: 100%;
								font-size: 16px;
								color: #333;
								line-height: 66px;
								float: left;
							}

							.itemuserID {
								width: 95px;
								height: 100%;

								float: left;
								margin-left: 27px;
								text-align: left;
								display: flex;

								img {
									width: 30px;
									height: 30px;
									border-radius: 50%;
									margin-top: 18px;
									float: left;
								}

								span {
									display: block;
									margin-left: 5px;
									flex: 1;
									height: 100%;
									font-size: 16px;
									color: #333;
									line-height: 66px;
									text-overflow: ellipsis;
									overflow: hidden;
									white-space: nowrap;
								}
							}

							.itemOrder {
								width: 196px;
								height: 100%;
								font-size: 16px;
								color: #333;
								line-height: 66px;
								float: left;
								margin-left: 63px;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
								text-align: left;
							}

							.itemName {
								width: 109px;
								height: 100%;
								font-size: 16px;
								color: #333;
								line-height: 66px;
								float: left;
								margin-left: 55px;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
								text-align: left;
							}

							.itemTime {
								box-sizing: border-box;
								width: 90px;
								height: 100%;
								font-size: 16px;
								color: #333;
								line-height: 25px;
								float: left;
								margin-left: 62px;
								padding-top: 10px;
							}

							.itemMoney {
								width: 60px;
								height: 100%;
								font-size: 16px;
								color: #333;
								line-height: 66px;
								float: left;
								margin-left: 55px;
							}

							.itemPuyStyle {
								width: 60px;
								height: 100%;
								font-size: 16px;
								color: #333;
								line-height: 66px;
								float: left;
								margin-left: 64px;
							}

							.itemStyle1 {
								width: 84px;
								height: 30px;
								background-color: rgba(255, 205, 0, .3);
								color: #FF7E00;
								border-radius: 15px;
								line-height: 30px;
								margin-top: 18px;
								float: left;
								margin-left: 67px;
								font-size: 16px;
							}

							.itemStyle2 {
								width: 84px;
								height: 30px;
								background-color: #D8FAF5;
								color: #33998F;
								border-radius: 15px;
								line-height: 30px;
								margin-top: 18px;
								float: left;
								margin-left: 67px;
								font-size: 16px;
							}

							.itemStyle3 {
								width: 84px;
								height: 30px;
								background-color: #FEEDEF;
								color: #993333;
								border-radius: 15px;
								line-height: 30px;
								margin-top: 18px;
								float: left;
								margin-left: 67px;
								font-size: 16px;
							}

							.itemStyle4 {
								width: 84px;
								height: 30px;
								background-color: #FAD8D8;
								color: #FF0000;
								border-radius: 15px;
								line-height: 30px;
								margin-top: 18px;
								float: left;
								margin-left: 67px;
								font-size: 16px;
							}

							.stopTime {
								box-sizing: border-box;
								width: 90px;
								height: 100%;
								font-size: 16px;
								color: #333;
								line-height: 25px;
								float: left;
								margin-left: 66px;
								padding-top: 10px;
							}

							// .edit{

							// }
							.delete {
								width: 18px;
								height: 100%;
								float: left;
								background-image: url('../assets/img/home/delete.png');
								background-repeat: no-repeat;
								background-size: 100% 20px;
								background-position: center;
								margin-left: 95px;
								cursor: pointer;
							}
						}

						.loadingPanel {
							position: absolute;
							width: 100%;
							height: 709px;
							background-color: white;
							z-index: 999;
							pointer-events: none;
							display: flex;
							flex-wrap: nowrap;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							z-index: 999;
							pointer-events: auto;
							left: 0;
							top: 108px;

							img {
								width: .6rem;

							}
						}
					}
				}

			}
		}
	}
</style>
