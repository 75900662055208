<template>
	<div class="feedback">
		<top-bar></top-bar>
		<input type="file" ref="filesImg" @change="selectedImg"
			style="position:absolute;width:0;height: 0;display: inline-block;" />
		<input type="file" ref="filesVideo" @change="selectedVideo"
			style="position:absolute;width:0;height: 0;display: inline-block;" />
		<div class="feedbackContent">
			<left-bar></left-bar>
			<div class="contentRight">
				<div class="center">
					<p>题库管理 > <span>错误反馈</span></p>
					<div class="content">
						<div class="centerTitle">
							<div class="selectSubject" @click="searchSubjectBtn">
								<div class="nodate">{{subjectTitle}}</div>
								<div class="botArrow"></div>
								<div class="selectBar" v-show="subjectBarShow">
									<div class="allSubject" :class="{ActiveItem:subjectItem==-1?true:false}"
										@click="allSubjectBtn">全部题库</div>
									<div class="setItem" v-for="(item,index) in subjectList" :key="index"
										:class="{ActiveItem:subjectItem==index?true:false}"
										@click="subjectItemBtn(item,index)">
										<div class="selectName">{{item.title}}</div>
										<div class="selectDetalis">{{item.description}}</div>
									</div>
								</div>
							</div>
							<div class="selectChapter" @click="searchChapterBtn" v-show="chapterList.length>0">
								<div class="nodate">{{chapterTitle==''?'选择章':chapterTitle}}</div>
								<div class="botArrow"></div>
								<div class="selectBar" v-show="chapterBarShow">
									<div class="setItem" v-for="(item,index) in chapterList" :key="index"
										:class="{ActiveItem:chapterItem==index?true:false}"
										@click="chapterItemBtn(item,index)">
										<div class="selectName">{{item.title}}</div>
										<div class="selectDetalis">{{item.description}}</div>
									</div>
								</div>
							</div>
							<div class="selectSection" @click="searchSectionBtn" v-show="sectionList.length>0">
								<div class="nodate">{{sectionTitle==''?'选择节':sectionTitle}}</div>
								<div class="botArrow"></div>
								<div class="selectBar" v-show="sectionBarShow">
									<div class="setItem" v-for="(item,index) in sectionList" :key="index"
										:class="{ActiveItem:sectionItem==index?true:false}"
										@click="sectionItemBtn(item,index)">
										<div class="selectName">{{item.title}}</div>
										<div class="selectDetalis">{{item.description}}</div>
									</div>
								</div>
							</div>
							<div class="isSolved">
								<div class="innerContent">
									<div class="no">
										未解决
									</div>
									<div class="yes">
										已解决
									</div>
								</div>
								<div class="btns" @click="switchSolved" :class="{'solved':solved}">
								</div>
							</div>
							<div class="searchInput" @click="searchBtn">
								<div class="innerText">
									搜索
								</div>
								<div class="searchImg"></div>
							</div>
						</div>
						<div class="listTitle">
							<div class="titleId">ID</div>
							<div class="titleUser">用户昵称</div>
							<div class="information">
								章节信息
							</div>
							<div class="titleQuestionID">题ID</div>
							<div class="titleQuestion">题目</div>
							<div class="titleError">错误报告</div>
							<div class="titleSolve">是否解决</div>
							<div class="titleItem">反馈时间</div>
							<div class="operate">操作</div>
						</div>
						<div class="listItem" v-for="(item,index) in errorList" :key="index">
							<div class="itemID">{{index+1 +(currentPage-1)*11}}</div>
							<div class="itemUser">{{item.user.nickname}}</div>
							<div class="information" @click="getQuestionInfo(item)">
								<div class="letter">
									信
								</div>
							</div>
							<div class="itemQuestionId">{{item.question_id}}</div>
							<div class="itemQuestion">
								<span v-if="item.question==null?false:true"
									:title="item.question.title">{{item.question.title}}</span>
							</div>
							<div class="itemError" :title="item.report">{{item.report}}</div>
							<div class="itemSolve">{{item.status==1?'否':'是'}}</div>
							<div class="itemTime">{{item.created_at | formatDate}}</div>
							<div class="delete" @click="deleteBtn(item)"></div>
							<div class="solve" @click="solve(item)"></div>
							<div class="edit" @click="editBtn(item)"></div>
						</div>

						<div class="loadingPanel" :style="{'opacity':loadingOpacity}"
							v-show="loadingOpacity<=0?false:true">
							<img src="../assets/img/home/onLoad.gif" />
						</div>
						<page-bar :currentPage="currentPage" :total="total" :count="count" @jumpNumber="jumpNumber"
							@nextPage="nextPage" @prePage="prePage" @pageTo="pageTo"></page-bar>
					</div>
				</div>
			</div>
		</div>
		<div class="popBar" v-show="popShow">
			<div class="deleteBar" v-show="deleteBar">
				<div class="title">提示信息</div>
				<div class="deleteContent">确定要删除错误反馈吗？</div>
				<div class="bot">
					<div class="sureBtn" @click="sureBtn">确定</div>
					<div class="cance" @click="cancelBtn">取消</div>
				</div>
			</div>
			<div class="questionInfoPanel" v-if="questionInfoShow">
				<div class="title">题目章节信息</div>
				<div class="closeBtn" @click="closeQuestionInfoPanel">×</div>
				<div class="content">
					<p><b>题库id:</b> {{questionInfo.subject[0].id}}</p>
					<p><b>题库名:</b> {{questionInfo.subject[0].title}}</p>
					<p><b>章id:</b> {{questionInfo.chapter[0].id}}</p>
					<p><b>章名:</b> {{questionInfo.chapter[0].title}}</p>
					<p><b>节id:</b> {{questionInfo.section.length==0?'-':questionInfo.section[0].id}}</p>
					<p><b>节名:</b> {{questionInfo.section.length==0?'-':questionInfo.section[0].title}}</p>
				</div>

			</div>
			<div class="editStyleBar" v-show="editStyleBar">
				<div class="title">提示信息</div>
				<div class="editContent">
					<div class="edit1" @click="styleBtn(1)">
						<div class="radio" :class="{selectColor:type==1?true:false}">
							<div class="inside"></div>
						</div>
						<p>未解决</p>
					</div>
					<div class="edit2" @click="styleBtn(2)">
						<div class="radio" :class="{selectColor:type==2?true:false}">
							<div class="inside"></div>
						</div>
						<p>已解决</p>
					</div>
				</div>
				<div class="bot">
					<div class="editSure" @click="editSure">确定</div>
					<div class="editCancel" @click="editCancel">取消</div>
				</div>
			</div>
		</div>
		<success-bar :tipsText="success" v-if="successShow"></success-bar>
		<error-bar :tipsText="error" v-if="errorShow"></error-bar>
		<div class="editBar" v-show="editBar">
			<div class="editContent">
				<div class="questionOne">
					<div class="questionId">
						<div class="id">ID</div>
						<div class="idInput">{{questionId}}</div>
					</div>
					<div class="questionname">
						<div class="name">题目</div>
						<div class="nameInput">
							<input type="text" v-model="title" :class="{noQuestionTitle:noTitle}">
						</div>
					</div>
				</div>
				<div class="questionTwo">
					<div class="questionStyle">
						<div class="styleText">题型</div>
						<div class="styleSelect" @click="styleSelectBtn">
							{{select}}
							<div class="selectArrow"></div>
							<div class="selectBar" v-show="selectBar">
								<div class="selectItem" v-for="(item,index) in questionStyle" :key="index"
									:class="{selectBg:type==index?true:false}" @click.stop="selectItemBtn(index,item)">
									{{item}}
								</div>
							</div>
						</div>
					</div>
					<div class="difficulty">
						<div class="difficultyText">难易度</div>
						<div class="difficultySelect" @click="difficultyBtn">
							{{difficulty}}
							<div class="selectArrow"></div>
							<div class="selectBar" v-show="difficultyBar">
								<div class="selectItem" v-for="(item,index) in difficultyList" :key="index"
									:class="{selectBg:difficultyIndex==index?true:false}"
									@click.stop="difficultyItemBtn(index,item)">{{item}}</div>
							</div>
						</div>
					</div>
					<div class="questionScore">
						<div class="scoreTect">分值</div>
						<input type="text" v-model="score">
					</div>
				</div>
				<div class="questionThree">
					<div class="optionA">
						<p>选项A</p>
						<input type="text" v-model="option_a">
					</div>
					<div class="optionD">
						<p>选项D</p>
						<input type="text" v-model="option_d">
					</div>
					<div class="optionB">
						<p>选项B</p>
						<input type="text" v-model="option_b">
					</div>
					<div class="optionE">
						<p>选项E</p>
						<input type="text" v-model="option_e">
					</div>
					<div class="optionC">
						<p>选项C</p>
						<input type="text" v-model="option_c">
					</div>
					<div class="optionF">
						<p>选项F</p>
						<input type="text" v-model="option_f">
					</div>

				</div>
				<div class="fourBox">
					<div class="questionFour">
						<div class="answer">答案</div>
						<textarea v-model="answer"></textarea>
					</div>

					<div class="questionFive">
						<div class="questionAnalysis">解析</div>
						<textarea v-model="analysis"></textarea>
					</div>
				</div>
				<div class="sixBox">
					<div class="annotation">
						<p>注释</p>
						<input type="text" v-model="annotation">
					</div>
					<div class="source">
						<p>来源</p>
						<input type="text" v-model="quote">
					</div>
				</div>
				<div class="boxFive">
					<div class="image">
						<div class="imgText">上传截图</div>
						<div class="imgContent">
							<img :src="imgBoxSrc" alt="">
						</div>
						<div class="imgBtn" @click="uploadImg">修改图片</div>
					</div>

					<div class="video">
						<div class="videoText">视频解析</div>
						<div class="videoImg">
							<video :src="videoBoxSrc" controls></video>
						</div>
						<div class="addVideo" @click="uploadVideo">修改视频</div>
					</div>

				</div>
				<div class="questionBtn">
					<div class="questionSure" @click="questionSureBtn">确定</div>
					<div class="questionCancel" @click="cancelEditBtn">取消</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import topBar from '../components/nav.vue'
	import leftBar from '../components/left.vue'
	import pageBar from '../components/pagebarContainer.vue'
	import successBar from '../components/success.vue'
	import errorBar from '../components/error.vue'
	import deleteBar from '../components/delete.vue'
	import Config from '../assets/js/img'
	import CryptoJS from '../assets/js/crypto-js'
	export default {
		components: {
			topBar,
			leftBar,
			pageBar,
			successBar,
			errorBar,
			deleteBar
		},
		filters: {
			formatDate(time) {
				var date = new Date(time);
				var y = date.getFullYear();
				var MM = date.getMonth() + 1;
				MM = MM < 10 ? "0" + MM : MM;
				var d = date.getDate();
				d = d < 10 ? "0" + d : d;
				var h = date.getHours();
				h = h < 10 ? "0" + h : h;
				var m = date.getMinutes();
				m = m < 10 ? "0" + m : m;
				var s = date.getSeconds();
				s = s < 10 ? "0" + s : s;
				return y + "-" + MM + "-" + d + " " + h + ":" + m;
			}
		},
		data() {
			return {
				errorShow: false,
				successShow: false,
				success: '删除成功',
				error: '删除失败',
				editStyleBar: false, //提示信息，已解决  为解决
				popShow: false, //弹窗
				deleteBar: false, //删除  子组件
				editBar: false, //编辑弹窗
				subjectBarShow: false,
				chapterBarShow: false,
				sectionBarShow: false,
				questionInfoShow: false,
				questionInfo: {},
				subjectTitle: '',
				subjectItem: 0,
				chapterTitle: '',
				chapterItem: 0,
				sectionTitle: '',
				sectionItem: 0,
				searchQuestion: '',
				subjectList: [],
				chapterList: [],
				sectionList: [],
				currentPage: 1,
				total: 30,
				count: 11,
				errorList: [],
				errorId: '',
				type: 1,
				title: '',
				select: '',
				//修改题
				questionId: '',
				title: '', //名称
				type: 0, //题类型
				score: '', //分值
				answer: '', //答案
				option_a: '', //选项
				option_b: '', //选项
				option_c: '', //选项
				option_d: '', //选项
				option_e: '', //选项
				option_f: '', //选项
				analysis: '', //解析
				searchQuestion: '', //搜索
				difficultyIndex: 0, //难易度
				annotation: '', //注释
				quote: '', //来源
				difficultyBar: false,
				difficultyList: ['简单', '一般', '困难'],
				difficulty: '简单',
				questionStyleBar: 1,
				noTitle: false,
				questionFill: '',
				loadingOpacity: 1, //loading 透明度
				videoFill: '', //video文件
				imageFill: '', //img文件
				imgBoxSrc: require('../assets/img/img.png'),
				videoBoxSrc: require('../assets/img/img.png'),
				CDN: Config.CDN,
				select: '单选题',
				questionStyle: ['单选', '多选', '不定项选择', '判断', '填空', '问答'],
				selectBar: false,
				charpetErrorId: '',
				subjectErrorId: '',
				sectionErrorId: '',
				solved: false //错误反馈是否解决
			}
		},
		methods: {
			switchSolved() {
				this.solved = !this.solved;
			},
			//解密
			secret(string, code, operation) {
				code = CryptoJS.MD5(code).toString();
				var iv = CryptoJS.enc.Utf8.parse(code.substring(0, 16));
				var key = CryptoJS.enc.Utf8.parse(code.substring(16));
				if (operation) {
					return CryptoJS.AES.decrypt(string, key, {
						iv: iv,
						padding: CryptoJS.pad.Pkcs7
					}).toString(CryptoJS.enc.Utf8);
				}
				return CryptoJS.AES.encrypt(string, key, {
					iv: iv,
					mode: CryptoJS.mode.CBC,
					padding: CryptoJS.pad.Pkcs7
				}).toString();
			},
			editBtn(item) {
				console.log(item, '-----------------------------')
				this.imageFill = '';
				this.videoFill = '';
				this.editBar = true;
				this.questionId = item.question.id;
				this.questionStyleBar = 1;
				this.charpetErrorId = item.question.chapter_id;
				this.sectionErrorId = item.question.section_id
				//获取题信息
				var param = new FormData();
				param.append('id', this.questionId)
				this.$.questionData(param).then(res => {
					console.log(res, '题信息')
					this.title = res.questionInfo.title;
					this.select = this.questionStyle[res.questionInfo.type - 1];
					this.type = res.questionInfo.type - 1
					this.score = res.questionInfo.score;
					this.answer = res.questionInfo.answer;
					var _m = this.secret('www.redgezi.cn', 'www.redgezi.cn', false)
					this.option_a = this.secret(res.questionInfo.option_a, _m, true);
					console.log(res.questionInfo.option_a, '789789889879');
					console.log(this.secret(this.option_a, _m, false), '56565656454545')
					this.option_b = res.questionInfo.option_b;
					this.option_c = res.questionInfo.option_c;
					this.option_d = res.questionInfo.option_d;
					this.option_e = res.questionInfo.option_e;
					this.option_f = res.questionInfo.option_f;
					this.analysis = res.questionInfo.analysis;
					this.difficulty = this.difficultyList[res.questionInfo.difficulty - 1];
					this.annotation = res.questionInfo.annotation;
					this.quote = res.questionInfo.quote;
					this.videoBoxSrc = this.CDN + res.questionInfo.video;
					this.imgBoxSrc = this.CDN + res.questionInfo.screenshot;
				})
			},
			styleSelectBtn() {
				this.selectBar = !this.selectBar;
			},
			difficultyBtn() {
				this.difficultyBar = !this.difficultyBar;
			},
			//上传视频
			uploadVideo() {
				this.$refs.filesVideo.click();
			},
			//上传截图
			uploadImg() {
				this.$refs.filesImg.click();
			},
			//上传图片
			selectedImg(e) {
				var self = this
				this.imageFill = e.target.files[0];
				console.log(this.imageFill, '1235456');

				var reader = new FileReader();
				reader.readAsDataURL(e.target.files[0]);
				reader.onload = function(e) {

					self.imgBoxSrc = e.target.result;
				};
			},

			videoSrc(file) {
				var url = null;

				if (window.createObjectURL != undefined) { // basic

					url = window.createObjectURL(file);

				} else if (window.URL != undefined) { // mozilla(firefox)

					url = window.URL.createObjectURL(file);

				} else if (window.webkitURL != undefined) { // webkit or chrome

					url = window.webkitURL.createObjectURL(file);

				}

				return url;
			},
			//上传视频
			selectedVideo(e) {

				var self = this
				this.videoFill = e.target.files[0];
				console.log(this.videoFill, '1235456');
				self.videoBoxSrc = this.videoSrc(e.target.files[0]);

				console.log('src---------', self.videoBoxSrc);
				// var reader = new FileReader();
				// reader.readAsDataURL(e.target.files[0]);
				// reader.onload = function (e) {

				//     self.videoBoxSrc =   e.target.result;
				// };
			},
			nextPage() {
				this.currentPage++;
				if (this.currentPage >= this.pageSize) {
					this.currentPage = this.pageSize;
				}
				//获取题列表
				this.errorListBtn();
			},
			pageTo(index) {
				this.currentPage = index;
				//获取题列表
				this.errorListBtn();
			},
			prePage() {
				this.currentPage--;
				if (this.currentPage <= 1) {
					this.currentPage = 1;
				}
				this.errorListBtn();
			},
			jumpNumber(val) {
				if (val == '') {
					val = 1
				}
				if (val >= Math.ceil(this.total / this.count)) {
					val = Math.ceil(this.total / this.count)
				}
				this.currentPage = val / 1;
				//获取题列表
				this.errorListBtn();
			},
			searchSubjectBtn() {
				this.subjectBarShow = !this.subjectBarShow;
				this.chapterBarShow = false;
			},
			searchChapterBtn() {
				this.chapterBarShow = !this.chapterBarShow;
				this.subjectBarShow = false;
			},
			searchSectionBtn() {
				this.sectionBarShow = !this.sectionBarShow
			},
			searchBtn() {
				this.errorListBtn();
			},
			//修改
			questionSureBtn() {
				if (this.title != '') {
					this.noTitle = false;
					var param = new FormData();
					param.append('id', this.questionId);
					param.append('chapter_id', this.charpetErrorId);
					param.append('section_id', this.sectionErrorId == 0 ? '' : this.sectionErrorId);
					param.append('type', this.type + 1);
					// param.append('')
					param.append('title', this.title);
					param.append('score', this.score);
					// var _m = this.secret('www.redgezi.cn', 'www.redgezi.cn', false);
					param.append('option_a', this.option_a);
					param.append('option_b', this.option_b.trim());
					param.append('option_c', this.option_c.trim());
					param.append('option_d', this.option_d.trim());
					param.append('option_e', this.option_e.trim());
					param.append('option_f', this.option_f.trim());
					param.append('answer', this.answer);
					param.append('analysis', this.analysis);
					param.append('video', this.videoFill);
					param.append('difficulty', this.difficultyIndex + 1);
					param.append('annotation', this.annotation == null ? '' : this.annotation);
					param.append('screenshot', this.imageFill);
					param.append('quote', this.quote == null || this.quote == 'null' ? '' : this.quote)
					this.$.editQuestion(param).then(res => {
						console.log(res, '编辑题')
						console.log(this.type + 1, '编辑题++++++++++++++++++++++')
						var self = this;
						if (res.code == 200 && res.msg == 'ok') {
							this.success = '编辑成功'
							this.successShow = true;
							var time1 = setTimeout(function() {
								self.successShow = false;
								clearTimeout(time1)
							}, 2000);
							this.errorListBtn();
						} else {
							this.error = '编辑失败'
							this.errorShow = true;
							var time2 = setTimeout(function() {
								self.errorShow = false;
								clearTimeout(time2)
							}, 2000);
						}
					})
					console.log(localStorage.getItem('question_chapterId'));
					this.editBar = false;
				} else {
					this.noTitle = reue;
					this.error = '编辑失败'
					this.errorShow = true;
					var time2 = setTimeout(function() {
						self.errorShow = false;
						clearTimeout(time2)
					}, 2000);
				}

			},
			cancelEditBtn() {
				this.editBar = false;
			},
			//获取库列表
			subjectListBtn() {
				var param = new FormData();
				param.append('page', 1);
				param.append('count', 99999);
				this.$.subjectList(param).then(res => {
					this.subjectList = res.subjectInfo.data;
					this.subjectTitle = localStorage.getItem('error_subjectTitle');
					this.subjectId = localStorage.getItem('error_subjectId');
					if (this.subjectId != '') {
						var param = new FormData();
						param.append('subject_id', this.subjectId);
						param.append('page', 1);
						param.append('count', 1000000);

						this.$.chapterList(param).then(res => {
							this.chapterList = res.chapterInfo.data;
							this.chapterTitle = localStorage.getItem('error_chapterTitle');
							this.chapterId = localStorage.getItem('error_chapterId');
							if (this.chapterId != '') {
								var paramsection = new FormData()
								paramsection.append('subject_id', this.subjectId);
								paramsection.append('chapter_id', this.chapterId);
								paramsection.append('page', 1);
								paramsection.append('count', 9999);
								this.$.sectionList(paramsection).then(res => {
									this.sectionList = res.sectionInfo.data;
									this.sectionTitle = localStorage.getItem('error_sectionTitle');
									this.sectionId = localStorage.getItem('error_sectionId');
								})
							}
						})
					}

				})
			},
			//获取章列表 选择库
			subjectItemBtn(item, index) {
				localStorage.setItem('error_subjectTitle', item.title);
				localStorage.setItem('error_subjectId', item.id);
				localStorage.setItem('error_subjectItem', index);
				localStorage.setItem('error_chapterTitle', '');
				localStorage.setItem('error_chapterId', '');
				localStorage.setItem('error_sectionTitle', '');
				localStorage.setItem('error_sectionId', '');
				this.chapterTitle = '';
				this.sectionTitle = '';
				this.subjectTitle = localStorage.getItem('error_subjectTitle');
				this.subjectId = localStorage.getItem('error_subjectId');
				this.subjectItem = localStorage.getItem('error_subjectItem');
				//获取章列表
				var param = new FormData();
				param.append('subject_id', this.subjectId);
				param.append('page', 1);
				param.append('count', 99999);
				var status = this.solved ? 2 : 1;
				param.append('status', status);

				this.$.chapterList(param).then(res => {
					this.chapterList = res.chapterInfo.data;
				})
			},
			//全部题库
			allSubjectBtn() {
				this.solved=false;
				this.subjectItem = -1;
				this.subjectTitle = '全部题库';
				this.chapterList = [];
				this.sectionList = [];
				localStorage.setItem('error_subjectTitle', '全部题库');
				localStorage.setItem('error_subjectId', '');
				localStorage.setItem('error_subjectItem', -1);
				localStorage.setItem('error_chapterTitle', '');
				localStorage.setItem('error_chapterId', '');
				localStorage.setItem('error_sectionTitle', '');
				localStorage.setItem('error_sectionId', '');
				this.chapterTitle = '';
				this.chapterTitle = '';
				this.sectionTitle = '';
				//获取纠错列表
				this.errorListBtn();
			},
			//获取节列表
			chapterItemBtn(item, index) {
				localStorage.setItem('error_chapterTitle', item.title);
				localStorage.setItem('error_chapterId', item.id);
				localStorage.setItem('error_chapterItem', index);
				localStorage.setItem('error_sectionTitle', '');
				localStorage.setItem('error_sectionId', '');
				this.sectionTitle = '';
				this.chapterTitle = localStorage.getItem('error_chapterTitle');
				this.chapterId = localStorage.getItem('error_chapterId');
				this.chapterItem = localStorage.getItem('error_chapterItem');
				//获取节总数
				var paramsection = new FormData()
				paramsection.append('subject_id', this.subjectId);
				paramsection.append('chapter_id', this.chapterId);
				paramsection.append('page', 1);
				paramsection.append('count', 99999);
				var status = this.solved ? 2 : 1;
				paramsection.append('status', status);
				this.$.sectionList(paramsection).then(res => {
					this.sectionList = res.sectionInfo.data;

				})
			},
			//获取题列表 选择节
			sectionItemBtn(item, index) {
				localStorage.setItem('error_sectionTitle', item.title);
				localStorage.setItem('error_sectionId', item.id);
				localStorage.setItem('error_sectionItem', index);
				this.sectionTitle = localStorage.getItem('error_sectionTitle');
				this.sectionId = localStorage.getItem('error_sectionId');
				this.sectionItem = localStorage.getItem('error_sectionItem');
			},
			//获取纠错列表
			errorListBtn() {
				var self = this;
				var formData = new FormData();
				formData.append('subject_id', localStorage.getItem('error_subjectId'));
				formData.append('chapter_id', localStorage.getItem('error_chapterId'));
				formData.append('section_id', localStorage.getItem('error_sectionId'));
				formData.append('page', this.currentPage);
				formData.append('count', this.count);
				var status = this.solved ? 2 : 1;
				formData.append('status', status);

				this.$.errorList(formData).then(res => {
					this.loadingOpacity = 1;
					console.log(res, '错题纠正列表')
					this.errorList = res.reportInfo.data;
					this.total = res.reportInfo.total;
					var value = 100;
					var timer = setInterval(function() {
						value -= 10;
						self.loadingOpacity = value / 100;
						if (value <= 0) {
							clearInterval(timer);
						}
					}, 10);
				})
			},
			getQuestionInfo(item) {

				var formData = new FormData();
				formData.append('id', item.question_id);
				var self = this;
				this.$.questionInfo(formData).then(res => {

					self.questionInfo = res.questionInfo;
					this.popShow = true;
					this.questionInfoShow = true;



				});
			},
			closeQuestionInfoPanel() {
				this.popShow = false;
				this.questionInfoShow = false;
			},
			deleteBtn(item) {
				this.popShow = true;
				this.deleteBar = true;
				this.editStyleBar = false;
				this.errorId = item.id;
			},
			//删除  弹框确认
			sureBtn() {
				this.popShow = false;
				var formData = new FormData();
				formData.append('id', this.errorId);
				this.$.deleteErrorList(formData).then(res => {
					console.log(res, '删除');
					this.successShow = true;
					var time1 = setTimeout(function() {
						self.successShow = false;
						clearTimeout(time1);
					})
					this.errorListBtn()
				})
			},
			cancelBtn() {
				this.popShow = false;
			},
			//打开编辑弹窗
			solve(item) {
				this.popShow = true;
				this.deleteBar = false;
				this.editStyleBar = true;
				this.errorId = item.id;
				console.log(this.errorId, '456231')
			},
			styleBtn(val) {
				this.type = val;
			},
			//编辑状态
			editSure() {
				this.popShow = false;
				var formatDate = new FormData();
				formatDate.append('id', this.errorId);
				formatDate.append('status', this.type);
				this.$.editErrolList(formatDate).then(res => {
					console.log(res, '编辑成功')
					this.errorListBtn();
				})
			},
			editCancel() {
				this.popShow = false;
			}
		},
		mounted() {
			this.subjectListBtn();
			this.subjectItem = localStorage.getItem('error_subjectItem');
			this.chapterItem = localStorage.getItem('error_chapterItem');
			this.sectionItem = localStorage.getItem('error_sectionItem');
			this.errorListBtn();
		}
	}
</script>

<style lang='less' scoped>
	.feedback {
		width: 100%;
		height: 1080px;
		background-color: #F2F2F2;

		.feedbackContent {
			width: 100%;
			height: 100vh;
			margin-top: 60px;
			background-color: #F2F2F2;
			display: flex;

			.contentRight {
				flex: 1;
				margin-left: 195px;
				height: 1000px;

				.center {
					width: 1570px;
					height: 100%;
					margin: auto;

					p {
						font-size: 20px;
						color: #333;
						text-align: left;
						margin-top: 24px;
						margin-bottom: 20px;
						cursor: pointer;

						span {
							color: #ccc;
						}
					}

					.content {
						width: 1570px;
						height: 920px;
						margin-top: 20px;
						background-color: #fff;
						border-radius: 10px;
						position: relative;

						// margin-top: 20px;
						.centerTitle {
							width: 1490px;
							height: 107px;
							border-bottom: 1px solid #ccc;
							margin: auto;
							position: relative;

							.selectSubject,
							.selectChapter,
							.selectSection {
								width: 181px;
								height: 44px;
								border: 1px solid #ccc;
								box-sizing: border-box;
								border-radius: 4px;
								float: left;
								margin-right: 24px;
								margin-top: 28px;
								position: relative;
								cursor: pointer;

								.botArrow {
									width: 15px;
									height: 100%;
									position: absolute;
									right: 15px;
									top: 0;
									background-image: url('../assets/img/home/botArrow.png');
									background-repeat: no-repeat;
									background-size: 100% 9px;
									background-position: center;
								}

								.nodate {
									font-size: 14px;
									color: #333;
									line-height: 44px;
								}

								.selectBar {
									position: absolute;
									top: 50px;
									width: 289px;
									height: 375px;
									left: 0;
									z-index: 99999;
									background-color: #fff;
									border-radius: 10px;
									box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
									overflow: auto;

									.setItem {
										width: 100%;
										height: 57px;
										margin-top: 8px;
										overflow: hidden;

										.selectName {
											width: 100%;
											font-size: 14px;
											color: #333;
											margin-top: 7px;
											text-align: left;
											text-indent: 25px;
											text-overflow: ellipsis;
											overflow: hidden;
											white-space: nowrap;
										}

										.selectDetalis {
											width: 100%;
											font-size: 12px;
											color: #999;
											margin-top: 6px;
											text-align: left;
											text-indent: 25px;
											text-overflow: ellipsis;
											overflow: hidden;
											white-space: nowrap;
										}
									}

									.allSubject {
										width: 100%;
										height: 57px;
										margin-top: 8px;
										overflow: hidden;
										font-size: 14px;
										color: #333;
										line-height: .57rem;
										text-align: left;
										text-indent: 25px;
									}

									.ActiveItem {
										background-color: rgba(241, 241, 241, .5);
									}
								}

								::-webkit-scrollbar {
									width: 2px;
									background-color: transparent;
								}

								::-webkit-scrollbar-thumb {
									background-color: #CCCCCC;
									border-radius: 10px;
								}
							}

							.selectSection {
								width: 300px;
							}

							.isSolved {
								width: 140px;
								height: 38px;
								border: 1px solid #ffa798;
								position: relative;
								float: left;
								margin-top: 30px;
								border-radius: 4px;
								background-color: #fff;
								user-select: none;

								.innerContent {
									margin: 0 auto;
									font-size: 16px;
									line-height: 38px;
									color: #F15764;

									.no {

										display: inline-block;
									}

									.yes {
										margin-left: 14px;
										display: inline-block;
									}
								}

								.btns {
									cursor: pointer;
									border-radius: 4px;
									position: absolute;
									top: 0px;
									width: 70px;
									height: 36px;
									margin: 1px;
									background-color: #ffa798;
									left: 68px;
								}

								.solved {
									left: 1px;
								}
							}

							.searchInput {
								width: 100px;
								height: 44px;
								margin-top: 28px;
								position: relative;
								float: left;
								user-select: none;
								margin-left: 20px;
								cursor: pointer;

								.innerText {
									text-align: left;
									text-indent: 20px;
									width: 100%;
									height: 100%;
									border: 1px solid #ccc;
									box-sizing: border-box;
									border-radius: 4px;
									float: left;
									outline: none;
									font-size: 16px;
									line-height: 40px;
									color: #333;
								}

								.searchImg {
									width: 24px;
									height: 100%;
									position: absolute;
									right: 10px;
									background-image: url('../assets/img/home/search.png');
									background-repeat: no-repeat;
									background-size: 24px 24px;
									background-position: center;
								}
							}
						}

						.listTitle {
							width: 1491px;
							height: 60px;
							background-color: #f2f2f2;
							border-radius: 4px;
							margin: 36px auto 20px;



							.titleId {
								font-size: 16px;
								color: #333;
								margin-left: 39px;
								line-height: 60px;
								float: left;
							}



							.titleUser {
								font-size: 16px;
								color: #333;
								margin-left: 91px;
								line-height: 60px;
								float: left;
							}

							.information {
								width: 80px;
								text-align: center;
								font-size: 16px;
								color: #333;
								line-height: 60px;
								float: left;
								margin-left: 17px;
							}

							.titleQuestionID {
								font-size: 16px;
								color: #333;
								margin-left: 47px;
								line-height: 60px;
								float: left;
							}

							.titleQuestion {
								font-size: 16px;
								color: #333;
								margin-left: 146px;
								line-height: 60px;
								float: left;
							}

							.titleError {
								font-size: 16px;
								color: #333;
								margin-left: 223px;
								line-height: 60px;
								float: left;
							}

							.titleSolve {
								font-size: 16px;
								color: #333;
								margin-left: 166px;
								line-height: 60px;
								float: left;
							}

							.titleItem {
								font-size: 16px;
								color: #333;
								margin-left: 110px;
								line-height: 60px;
								float: left;
							}

							.operate {
								font-size: 16px;
								color: #333;
								margin-right: 102px;
								line-height: 60px;
								float: right;
							}
						}

						.listItem {
							width: 1490px;
							height: 44px;
							margin: 11px auto;

							.itemID {
								width: 38px;
								height: 100%;
								font-size: 16px;
								color: #333;
								float: left;
								margin-left: 24px;
								line-height: 44px;
							}



							.itemUser {
								width: 88px;
								height: 100%;
								font-size: 16px;
								color: #333;
								float: left;
								margin-left: 72px;
								line-height: 44px;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}

							.information {
								width: 80px;
								height: 100%;

								font-size: 14px;
								color: #333;
								line-height: 20px;
								float: left;

								.letter {
									cursor: pointer;
									margin: 0 auto;
									margin-top: 12px;
									width: 20px;
									height: 20px;
									line-height: 20px;
									border-radius: 50%;
									border: 2px solid #F1576499;
									color: #F15764;
								}
							}

							.itemQuestionId {
								width: 38px;
								height: 100%;
								font-size: 16px;
								color: #333;
								float: left;
								margin-left: 42px;
								line-height: 44px;
							}

							.itemQuestion {
								width: 190px;
								height: 100%;
								font-size: 16px;
								color: #333;
								float: left;
								margin-left: 75px;
								line-height: 44px;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;

							}

							.itemError {
								width: 214px;
								height: 100%;
								font-size: 16px;
								color: #333;
								float: left;
								text-align: left;
								margin-left: 70px;
								line-height: 44px;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}

							.itemSolve {
								width: 18px;
								height: 100%;
								font-size: 16px;
								color: #333;
								float: left;
								margin-left: 105px;
								line-height: 44px;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}

							.itemTime {
								width: 160px;
								height: 100%;
								font-size: 16px;
								color: #333;
								float: left;
								margin-left: 90px;
								line-height: 44px;
							}

							.delete {
								width: 18px;
								height: 100%;
								float: right;
								margin-right: 60px;
								background-image: url('../assets/img/home/delete.png');
								background-repeat: no-repeat;
								background-size: 100% 20px;
								background-position: center;
							}

							.solve {
								width: 30px;
								height: 100%;
								float: right;
								margin-right: 21px;
								background-image: url('../assets/img/solve.png');
								background-repeat: no-repeat;
								background-size: 100% 30px;
								background-position: center;
							}

							.edit {
								width: 21px;
								height: 100%;
								float: right;
								margin-right: 21px;
								background-image: url('../assets/img/home/edit.png');
								background-repeat: no-repeat;
								background-size: 100% 21px;
								background-position: center;
							}
						}

						.loadingPanel {
							position: absolute;
							width: 100%;
							height: 709px;
							background-color: white;
							z-index: 999;
							pointer-events: none;
							display: flex;
							flex-wrap: nowrap;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							z-index: 999;
							pointer-events: auto;
							left: 0;
							top: 108px;

							img {
								width: .6rem;

							}
						}
					}
				}
			}
		}

		.popBar {
			width: 100%;
			height: 100%;
			background-color: rgba(51, 51, 51, .6);
			position: fixed;
			top: 0;
			left: 0;
			z-index: 666;

			.deleteBar {
				width: 564px;
				height: 180px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: #fff;
				border-radius: 4px;

				.title {
					width: 100%;
					height: .42rem;
					border-bottom: 1px solid #ccc;
					font-size: .22rem;
					color: #333;
					line-height: .42rem;
					text-align: left;
					text-indent: .24rem;
				}

				.deleteContent {
					width: 100%;
					height: 16px;
					margin-top: 28px;
					text-indent: 24px;
					font-size: 16px;
					color: #333;
					text-align: left;
				}

				.bot {
					width: 200px;
					height: 36px;
					margin-top: 35px;
					float: right;
					margin-right: 34px;

					.sureBtn {
						width: 84px;
						height: 100%;
						float: left;
						background-color: #F15764;
						color: #fff;
						font-size: 16px;
						line-height: 36px;
						border-radius: 4px;
						cursor: pointer;
					}

					.cance {
						width: 84px;
						height: 100%;
						float: right;
						border: 1px solid #F15764;
						box-sizing: border-box;
						color: #F15764;
						font-size: 16px;
						line-height: 34px;
						border-radius: 4px;
						cursor: pointer;
					}
				}
			}

			.questionInfoPanel {
				width: 460px;
				height: 230px;
				background-color: #fff;
				border-radius: 4px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				.title {
					width: 100%;
					height: .42rem;
					border-bottom: 1px solid #ccc;
					font-size: .18rem;
					color: #333;
					line-height: .42rem;
					text-align: left;
					text-indent: .24rem;
				}

				.closeBtn {
					position: absolute;
					width: 25px;
					height: 25px;
					border: 1px solid silver;
					border-radius: 50%;
					font-size: 20px;
					line-height: 20px;
					color: #999;
					cursor: pointer;
					right: 10px;
					top: 8px;

				}

				.content {
					p {
						padding-left: 20px;
						text-align: left;
						font-size: 16px;
						height: 30px;
						line-height: 30px;

						b {
							color: #F15764;
						}
					}
				}
			}

			.editStyleBar {
				width: 460px;
				height: 190px;
				background-color: #fff;
				border-radius: 4px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				.title {
					width: 100%;
					height: .42rem;
					border-bottom: 1px solid #ccc;
					font-size: .22rem;
					color: #333;
					line-height: .42rem;
					text-align: left;
					text-indent: .24rem;
				}

				.editContent {
					width: 100%;
					height: 16px;
					margin-top: 29px;

					.edit1 {
						width: 82px;
						height: 100%;
						float: left;
						margin-left: 101px;
						cursor: pointer;

						.radio {
							width: 14px;
							height: 14px;
							border-radius: 50%;
							border: 1px solid #ccc;
							margin-top: 1px;
							float: left;
							position: relative;

							.inside {
								width: 8px;
								height: 8px;
								// background-color: red;
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								border-radius: 50%;
								background-color: #fff;
								z-index: 2;
							}
						}

						.selectColor {
							background-color: #F15764;
							border: 1px solid #F15764;
						}

						p {
							font-size: 16px;
							color: #333;
							float: right;
						}
					}

					.edit2 {
						width: 82px;
						height: 100%;
						float: left;
						margin-left: 78px;
						cursor: pointer;

						.radio {
							width: 14px;
							height: 14px;
							border-radius: 50%;
							border: 1px solid #ccc;
							margin-top: 1px;
							float: left;
							position: relative;

							.inside {
								width: 8px;
								height: 8px;
								// background-color: red;
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								border-radius: 50%;
								background-color: #fff;
								z-index: 2;
							}
						}

						.selectColor {
							background-color: #F15764;
							border: 1px solid #F15764;
						}

						p {
							font-size: 16px;
							color: #333;
							float: right;
						}
					}
				}

				.bot {
					width: 100%;
					height: 36px;
					margin-top: 37px;

					.editSure {
						width: 84px;
						height: 100%;
						float: left;
						margin-left: 131px;
						background-color: #F15764;
						color: #fff;
						line-height: 36px;
						font-size: 16px;
						cursor: pointer;
					}

					.editCancel {
						width: 84px;
						height: 100%;
						float: left;
						margin-left: 30px;
						border: 1px solid #F15764;
						color: #F15764;
						box-sizing: border-box;
						line-height: 34px;
						font-size: 16px;
						border-radius: 4px;
						cursor: pointer;
					}
				}
			}
		}

		.editBar {
			width: 100%;
			height: 100%;
			background-color: rgba(51, 51, 51, 0.4);
			position: fixed;
			top: 0;
			left: 0;
			z-index: 999;

			.editContent {
				width: 1036px;
				height: 886px;
				background-color: #fff;
				border-radius: 10px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				.questionTitle {
					width: 100%;
					height: 40px;
					margin-top: 20px;

					// background-color: red;
					.subjectName,
					.charpterName,
					.sectionName {
						width: 25%;
						height: 100%;
						display: flex;
						float: left;

						.subjectTitle,
						.charpterTitle,
						.sectionTitle {
							font-size: 16px;
							color: #333;
							line-height: .4rem;
							float: left;
							margin-left: 15px;
						}

						.subjectInput,
						.charpterInput,
						.sectionInput {
							flex: 1;
							height: 100%;
							float: left;
							background-color: #f2f2f2;
							border: 1px solid #ccc;
							border-radius: 4px;
							box-sizing: border-box;
							font-size: 18px;
							color: #666;
							line-height: 40px;
						}
					}
				}

				.questionOne {
					width: 100%;
					height: 72px;
					margin-top: 20px;

					// background-color: #333;
					.questionId {
						width: 122px;
						height: 100%;
						float: left;
						margin-left: 68px;

						.id {
							font-size: 14px;
							color: #333;
							text-align: left;
						}

						.idInput {
							width: 100%;
							height: 40px;
							background-color: #ccc;
							font-size: 14px;
							color: #b7b7b7;
							line-height: 40px;
							border-radius: 4px;
							margin-top: 16px;
						}
					}

					.questionname {
						width: 710px;
						height: 100%;
						float: left;
						margin-left: 52px;

						.name {
							font-size: 14px;
							color: #333;
							text-align: left;
						}

						.nameInput {
							width: 710px;
							height: 40px;
							// border: 1px solid #707070;
							border-radius: 4px;
							outline: none;
							margin-top: 16px;
							position: relative;

							input {
								width: 100%;
								height: 100%;
								position: absolute;
								top: 0;
								left: 0;
								border: 1px solid #707070;
								box-sizing: border-box;
								border-radius: 4px;
								font-size: 14px;
								color: #333;
								outline: none;
								text-indent: 14px;
							}

							.noQuestionTitle {
								border: 1px solid #ff0000;
							}
						}
					}

					.questionLeft {
						margin-left: 66px;
					}
				}

				.questionTwo {
					width: 100%;
					height: 72px;
					margin-top: 26px;

					.questionStyle {
						width: 214px;
						height: 100%;
						float: left;
						margin-left: 68px;

						.styleText {
							font-size: 14px;
							color: #333;
							text-align: left;
						}

						.styleSelect {
							width: 100%;
							height: 40px;
							margin-top: 14px;
							border-radius: 4px;
							border: 1px solid #707070;
							box-sizing: border-box;
							position: relative;
							cursor: pointer;
							font-size: 14px;
							color: #333;
							line-height: 40px;

							.selectArrow {
								width: 21px;
								height: 100%;
								background-image: url('../assets/img/home/botArrow.png');
								background-repeat: no-repeat;
								background-size: 100% 15px;
								background-position: center;
								position: absolute;
								right: 11px;
								z-index: 22;
								top: 0;
							}

							.selectBar {
								width: 100%;
								height: 160px;
								position: absolute;
								top: 40px;
								left: 0;
								z-index: 99999;
								box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
								z-index: 22;
								background-color: #fff;
								overflow: auto;

								.selectItem {
									width: 100%;
									height: 40px;
									font-size: 14px;
									color: #333;
									line-height: 40px;
								}

								.selectBg {
									background-color: rgba(241, 87, 100, .2);
									color: #F15764;
								}
							}

							::-webkit-scrollbar {
								width: 0;
							}
						}
					}

					.difficulty {
						width: 180px;
						height: 100%;
						float: left;
						margin-left: 68px;

						.difficultyText {
							font-size: 14px;
							color: #333;
							text-align: left;
						}

						.difficultySelect {
							width: 100%;
							height: 40px;
							margin-top: 14px;
							border-radius: 4px;
							border: 1px solid #707070;
							box-sizing: border-box;
							position: relative;
							cursor: pointer;
							font-size: 14px;
							color: #333;
							line-height: 40px;

							.selectArrow {
								width: 21px;
								height: 100%;
								background-image: url('../assets/img/home/botArrow.png');
								background-repeat: no-repeat;
								background-size: 100% 15px;
								background-position: center;
								position: absolute;
								right: 11px;
								z-index: 22;
								top: 0;
							}

							.selectBar {
								width: 100%;
								height: 120px;
								position: absolute;
								top: 40px;
								left: 0;
								z-index: 99999;
								box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
								z-index: 22;
								background-color: #fff;

								.selectItem {
									width: 100%;
									height: 40px;
									font-size: 14px;
									color: #333;
									line-height: 40px;
								}

								.selectBg {
									background-color: rgba(241, 87, 100, .2);
									color: #F15764;
								}
							}
						}
					}

					.questionScore {
						width: 87px;
						height: 100%;
						float: left;
						margin-left: 102px;
						position: relative;

						.scoreTect {
							font-size: 14px;
							color: #333;
							text-align: left;
						}

						input {
							width: 100%;
							height: 40px;
							border: 1px solid #707070;
							border-radius: 4px;
							box-sizing: border-box;
							position: absolute;
							top: 32px;
							left: 0;
							outline: none;
							font-size: 14px;
							text-indent: 16px;
						}
					}
				}

				.questionThree {
					width: 100%;
					height: 175px;
					margin-top: 30px;

					.optionA,
					.optionB,
					.optionC {
						width: 409px;
						height: 40px;
						margin-left: 68px;
						position: relative;
						float: left;
						margin-bottom: 18px;

						p {
							font-size: 14px;
							line-height: 40px;
							float: left;
							color: #333;
						}

						input {
							width: 347px;
							height: 40px;
							border: 1px solid #707070;
							border-radius: 4px;
							box-sizing: border-box;
							position: absolute;
							right: 0;
							outline: none;
							text-indent: 16px;
							font-size: 14px;
							color: #333;
							text-indent: 14px;

						}

					}

					.optionD,
					.optionE,
					.optionF {
						width: 409px;
						height: 40px;
						margin-left: 66px;
						position: relative;
						float: left;
						margin-bottom: 18px;

						p {
							font-size: 14px;
							line-height: 40px;
							float: left;
							color: #333;
						}

						input {
							width: 347px;
							height: 40px;
							border: 1px solid #707070;
							border-radius: 4px;
							box-sizing: border-box;
							position: absolute;
							right: 0;
							outline: none;
							text-indent: 16px;
							font-size: 14px;
						}
					}

				}

				.fourBox {
					width: 900px;
					height: 130px;
					margin: auto;

					// margin-top: 30px;
					.questionFour {
						width: 50%;
						height: 130px;
						position: relative;
						float: left;

						.answer {
							font-size: 14px;
							color: #333;
							float: left;
							line-height: 130px;
						}

						textarea {
							width: 347px;
							height: 130px;
							border-radius: 4px;
							border: 1px solid #707070;
							box-sizing: border-box;
							float: left;
							margin-left: 35px;
							resize: none;
							font-size: 14px;
							outline: none;
							text-indent: 16px;
						}
					}

					.questionFive {
						width: 50%;
						height: 130px;
						position: relative;
						float: right;

						.questionAnalysis {
							font-size: 14px;
							color: #333;
							float: left;
							line-height: 130px;
							margin-left: 26px;
						}

						textarea {
							width: 347px;
							height: 130px;
							border-radius: 4px;
							border: 1px solid #707070;
							box-sizing: border-box;
							float: left;
							margin-left: 35px;
							resize: none;
							font-size: 14px;
							outline: none;
							text-indent: 16px;
						}

						::-webkit-scrollbar {
							width: 2px;
							background-color: transparent;
						}

						::-webkit-scrollbar-thumb {
							background-color: #f2f2f2;
						}
					}
				}

				.sixBox {
					width: 100%;
					height: 40px;
					margin: auto;
					margin-top: 20px;

					.annotation {
						width: 409px;
						height: 40px;
						margin-left: 68px;
						position: relative;
						float: left;
						margin-bottom: 18px;

						p {
							font-size: 14px;
							line-height: 40px;
							float: left;
							color: #333;
						}

						input {
							width: 347px;
							height: 40px;
							border: 1px solid #707070;
							border-radius: 4px;
							box-sizing: border-box;
							position: absolute;
							right: 0;
							outline: none;
							text-indent: 16px;
							font-size: 14px;
							color: #333;
							text-indent: 14px;

						}
					}

					.source {
						width: 409px;
						height: 40px;
						margin-left: 66px;
						position: relative;
						float: left;
						margin-bottom: 18px;

						p {
							font-size: 14px;
							line-height: 40px;
							float: left;
							color: #333;
						}

						input {
							width: 347px;
							height: 40px;
							border: 1px solid #707070;
							border-radius: 4px;
							box-sizing: border-box;
							position: absolute;
							right: 0;
							outline: none;
							text-indent: 16px;
							font-size: 14px;
						}
					}
				}

				.boxFive {
					width: 100%;
					margin: 27px auto 0;
					height: 130px;
					// margin-top: 27px;
					position: relative;

					.image {
						width: 50%;
						height: 100%;
						float: left;
						position: relative;

						.imgText {
							font-size: 14px;
							color: #333;
							float: left;
							line-height: 130px;
							margin-left: 56px;
						}

						.imgContent {
							width: 224px;
							height: 126px;
							border: 1px solid #707070;
							border-radius: 4px;
							float: left;
							margin-left: 20px;
							position: relative;

							img {
								width: 100%;
								height: 100%;
								position: absolute;
								top: 0;
								left: 0;
								border-radius: 4px;
							}
						}

						.imgBtn {
							width: 80px;
							height: 27px;
							float: right;
							background-color: #F15764;
							border-radius: 4px;
							float: left;
							margin-left: 20px;
							margin-top: 99px;
							cursor: pointer;
							font-size: 12px;
							color: #fff;
							line-height: 27px;
						}
					}

					.video {
						width: 50%;
						height: 100%;
						float: right;
						position: relative;

						.videoText {
							font-size: 14px;
							color: #333;
							float: left;
							margin-left: 12px;
							line-height: 130px;
						}

						.videoImg {
							width: 224px;
							height: 126px;
							border: 1px solid #707070;
							border-radius: 4px;
							float: left;
							margin-left: 20px;
							position: relative;

							video {
								width: 100%;
								height: 100%;
								position: absolute;
								top: 0;
								left: 0;
								border-radius: 4px;
							}
						}

						.addVideo {
							width: 80px;
							height: 27px;
							float: right;
							background-color: #F15764;
							border-radius: 4px;
							float: left;
							margin-left: 20px;
							margin-top: 99px;
							cursor: pointer;
							font-size: 12px;
							color: #fff;
							line-height: 27px;
						}
					}

				}

				.questionBtn {
					width: 412px;
					height: 38px;
					margin: 24px auto 0;

					.questionSure {
						width: 153px;
						height: 100%;
						background-color: #F15764;
						color: #fff;
						font-size: 14px;
						line-height: 38px;
						float: left;
						border-radius: 4px;
						cursor: pointer;
					}

					.questionCancel {
						width: 153px;
						height: 100%;
						border: 1px solid #F15764;
						box-sizing: border-box;
						color: #F15764;
						font-size: 14px;
						line-height: 38px;
						float: right;
						border-radius: 4px;
						cursor: pointer;
					}
				}
			}
		}
	}
</style>
