import axios from "axios"
import qs from "qs"


//部署服务器的时候开启注释
// axios.defaults.baseURL = 'http://192.168.31.74:8787';



// 添加请求拦截器
axios.interceptors.request.use(function(config) {
    // 参数格式转换

    if (config.method == "post") {
        //  config.data = qs.stringify(config.data);

    }
    var token = localStorage.getItem('token');
    config.headers.ContentType = 'multipart/form-data';
    config.headers.Authorization = token; //Authorization  是请求头要求加上的字段

    return config;
}, function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});
// 添加响应拦截器
axios.interceptors.response.use(function(response) {
    // 对响应数据做点什么
    if (response.status == 200) {
        return response.data;
    } else {
        handleErrorData(response.data);
    }
    return response;
}, function(error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});
//对错误信息的处理函数
const Message = {
    error(message) {
        console.log(message);
    }
}

function handleErrorData(errMes) {
    console.log(errMes.message);
    if (errMes.message) {
        Message.error(errMes.message);
    } else {
        switch (errMes.code) {
            case 401:
                Message.error("未授权，请重新登录!");
                break;
            case 403:
                Message.error("拒绝访问");
                break;
            case 404:
                Message.error("很抱歉，资源未找到!");
                break;
            case 500:
                Message.error("服务器错误!");
                break;
            case 504:
                Message.error("网络超时!");
                break;
            default:
                Message.error("服务正在联调中，请稍后!");
                break;
        }
    }
}

function Post(url, parameter = {}) {
    var proxyUrl = '/api/'
    return axios({
        url: proxyUrl + url,
        method: 'post',
        data: parameter
    })
}


//登录url
const loginUrl = 'backend/login';
const addSubjectUrl = 'backend/subject/add';
const subjectListUrl = 'backend/subject/list';
const editSubjectUrl = 'backend/subject/update';
const deleteSubjectUrl = 'backend/subject/delete';
const chapterListUrl = 'backend/chapter/list';
const addChapterUrl = 'backend/chapter/add';
const editChapterUrl = 'backend/chapter/update';
const deleteChapterUrl = 'backend/chapter/delete';
const sectionListUrl = 'backend/section/list';
const addSectionUrl = 'backend/section/add';
const editSectionUrl = 'backend/section/update';
const deleteSectionUrl = 'backend/section/delete';
const questionListUrl = 'backend/question/list';
const deleteQuestionUrl = 'backend/question/delete';
const editQuestionUrl = 'backend/question/update';

//后台添加题库
const addUserOrderUrl='backend/orders/addOrderByAdmin';


// /添加题
const addQuestionUrl = 'backend/question/add'
    //获取库信息】
const subjectDataUrl = 'backend/subject/get'
    //获取章信息
const chapterDataUrl = 'backend/chapter/get'
    //获取节
const sectionDataUrl = 'backend/section/get'
    //获取题
const questionDataUrl = 'backend/question/get'
   //获取题章节信息
const questionInfoUrl='backend/question/getInfo';
    //根节点列表
const rootsListUrl = 'backend/panel/roots/list'
const addRootsUrl = 'backend/panel/roots/add'
const editRootsUrl = 'backend/panel/roots/update'
const deleteRootsUrl = 'backend/panel/roots/delete'
    //获取二级节点
const stemListUrl = 'backend/panel/stem/list'
const addStemUrl = 'backend/panel/stem/add'
const editStemUrl = 'backend/panel/stem/update'
const deleteStrmUrl = 'backend/panel/stem/delete'
    //三级节点
const leafListUrl = 'backend/panel/leaf/list'
const editleafUrl = 'backend/panel/leaf/update'
const addLeafUrl = 'backend/panel/leaf/add'
const deleteLeafUrl = 'backend/panel/leaf/delete'
    //获取科目列表
const obtainListUrl = 'backend/panel/list'
    //添加库价格
const addSubjectPayUrl = 'backend/subject/option/add'
    //编辑库价格
const editSubjectPayUrl = 'backend/subject/option/update'
    //删除价格
const deleteSubjecPaytUrl = 'backend/subject/option/delete'
    //更新题库数量
const upDataSubjectUrl = 'backend/question/updateQuestionCount'
    //订单列表
const orderListUrl = 'backend/orders/list'
    //查询用户
const searchUserUrl = 'backend/orders/queryUser'
    //激活码列表
const activationUrl = 'backend/activation/list'
    //创建激活码
const addActivationUrl = 'backend/activation/add'
    //上传题文件
const upLoadQusstionUrl = 'backend/question/excel'
    //获取用户信息
const userListUrl = 'backend/user/list'
    //删除
const deleteUserUrl = 'backend/user/del';
//删除激活码
const deleteActivationUrl = 'backend/activation/delete';
//首页统计信息
const homeNewsUrl = 'backend/panel/statistics';
//获取销售统计
const salesListUrl = 'backend/panel/sales';
//纠错列表
const errorListUrl = 'backend/question/report/list';
//编辑错误列表
const editErrolListUrl = 'backend/question/report/update';
//删除
const deleteErrorListUrl = 'backend/question/report/delete'
    // post
var api = {
    login(params) {
        return Post(loginUrl, params)
    },
    addSubject(params) {
        return Post(addSubjectUrl, params)
    },
    subjectList(params) {
        return Post(subjectListUrl, params)
    },
    editSubject(params) {
        return Post(editSubjectUrl, params)
    },
    deleteSubject(params) {
        return Post(deleteSubjectUrl, params)
    },
    chapterList(params) {
        return Post(chapterListUrl, params)
    },
    addChapter(params) {
        return Post(addChapterUrl, params)
    },
    editchapter(params) {
        return Post(editChapterUrl, params)
    },
    sectionList(params) {
        return Post(sectionListUrl, params)
    },
    addSection(params) {
        return Post(addSectionUrl, params)
    },
    editSection(params) {
        return Post(editSectionUrl, params)
    },
    deleteSection(params) {
        return Post(deleteSectionUrl, params)
    },
    questionList(params) {
        return Post(questionListUrl, params)
    },
    deleteQuestion(params) {
        return Post(deleteQuestionUrl, params)
    },
    editQuestion(params) {
        return Post(editQuestionUrl, params)
    },
    sunjectData(params) {
        return Post(subjectDataUrl, params)
    },
    rootsList(params) {
        return Post(rootsListUrl, params)
    },
    addRoots(params) {
        return Post(addRootsUrl, params)
    },
    editRoots(params) {
        return Post(editRootsUrl, params)
    },
    deleteRoots(params) {
        return Post(deleteRootsUrl, params)
    },
    stemList(params) {
        return Post(stemListUrl, params)
    },
    adddStem(params) {
        return Post(addStemUrl, params)
    },
    editStem(params) {
        return Post(editStemUrl, params)
    },
    deleteStem(params) {
        return Post(deleteStrmUrl, params)
    },
    leafList(params) {
        return Post(leafListUrl, params)
    },
    editLeaf(params) {
        return Post(editleafUrl, params)
    },
    addLeaf(params) {
        return Post(addLeafUrl, params)
    },
    deleteLeaf(params) {
        return Post(deleteLeafUrl, params)
    },
	addUserOrderByAdmin(params){
		  return Post(addUserOrderUrl, params)
	},
    //添加库价格
    addSubjectPay(params) {
        return Post(addSubjectPayUrl, params)
    },
    //编辑可价格
    editSubjectPay(params) {
        return Post(editSubjectPayUrl, params)
    },
    //删除价格
    deleteSubjectPay(params) {
        return Post(deleteSubjecPaytUrl, params)
    },
    //获取章信息
    chapterData(params) {
        return Post(chapterDataUrl, params)
    },
    //获取节信息
    sectionData(params) {
        return Post(sectionDataUrl, params)
    },
    //获取题
    questionData(params) {
        return Post(questionDataUrl, params)
    },
	questionInfo(params){
		return Post(questionInfoUrl,params)
	},
    //添加题
    addQuestion(params) {
        return Post(addQuestionUrl, params)
    },
    //更新题库数量
    upDataSubject(params) {
        return Post(upDataSubjectUrl, params)
    },
    //订单列表
    orderList(params) {
        return Post(orderListUrl, params)
    },
    //查询用户
    searchUser(params) {
        return Post(searchUserUrl, params)
    },
    //获取科目列表
    obtainList(params) {
        return Post(obtainListUrl, params)
    },
    //获取激活码列表
    activationList(params) {
        return Post(activationUrl, params)
    },
    //生成激活码
    addActivation(params) {
        return Post(addActivationUrl, params)
    },
    //上传题文件
    upLoadQusstion(params) {
        return Post(upLoadQusstionUrl, params)
    },
    //获取用户信息
    userList(params) {
        return Post(userListUrl, params)
    },
    //删除用户
    deleteUserUrl(params) {
        return Post(deleteUserUrl, params)
    },
    //删除激活吗
    deleteActivation(param) {
        return Post(deleteActivationUrl, param)
    },
    //删除章
    deleteChapter(param) {
        return Post(deleteChapterUrl, param)
    },
    //首页统计信息
    homeNews(param) {
        return Post(homeNewsUrl, param)
    },
    //获取销售统计
    salesList(param) {
        return Post(salesListUrl, param)
    },
    //获取纠错列表哦
    errorList(param) {
        return Post(errorListUrl, param)
    },
    //编辑
    editErrolList(param) {
        return Post(editErrolListUrl, param)
    },
    //删除
    deleteErrorList(param) {
        return Post(deleteErrorListUrl, param)
    }


};
export {
    api
};