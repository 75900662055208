<template>
  <div class="success">
      <div class="center">
          <div class="successImg"></div>
          <div class="successText">{{tipsText}}</div>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        tipsText:String,
        required:true,
    }
}
</script>

<style lang='less' scoped>
    .success{
        width: 100%;
        height: 46.5px;
        background-color: #91ECD7;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 6666;
        .center{
            width: 105px;
            height: 100%;
            margin: auto;
            .successImg{
                width: 24px;
                height: 100%;
                float: left;
                background-image: url('../assets/img/home/success.png');
                background-repeat: no-repeat;
                background-size: 24px 24px;
                background-position: center;
            }
            .successText{
                font-size: 16px;
                color: #23B08D;
                float: right;
                line-height: 46.5px;
            }
            
        }
    }
</style>