import { render, staticRenderFns } from "./addSection.vue?vue&type=template&id=47974479&scoped=true&"
import script from "./addSection.vue?vue&type=script&lang=js&"
export * from "./addSection.vue?vue&type=script&lang=js&"
import style0 from "./addSection.vue?vue&type=style&index=0&id=47974479&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47974479",
  null
  
)

export default component.exports