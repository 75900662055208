<template>
  <div class="login">
    <div class="loginBg">
      <div class="loginText">
        您好！欢迎来到红格子管理平台
      </div>
      <div class="line"></div>
      <div class="userName">
        <p>手机号/用户名</p>
        <input type="text" placeholder="请输入用户名或手机号" v-model="userName">
      </div>
      <div class="password">
        <p>密码</p>
        <input type="passwrd" placeholder="请输入用户密码" v-model="password">
      </div>
      <div class="loginBtn" @click="loginBtn">登录</div>
  </div>

  </div>
</template>

<script>

 
export default {
	mounted() {
			 
			 setTimeout(function(){
				 var width=document.body.clientWidth;
				 var width=window.screen.width;
				  console.log(width,"width");
				 var ratio= width/1920;
				 
			 	 document.querySelector('body').setAttribute('style', 'zoom:'+ratio)
			 },100);
		
	 },
 data(){
   return{
     userName:'18931190100',//用户名
     password:'U2FsdX',//密码
   }
 },
 methods:{
   loginBtn(){
    //  this.$router.push({path:'/home'});
	 var param={username:this.userName,password:this.password};
      this.$.login(param).then(res=>{
        if(res.code==200){
          this.$router.push({path:'/home'});
           console.log(res.token.access_token,'========');
          var token = 'Bearer ' + res.token.access_token;
          localStorage.setItem("token",token)
        }
      
      
     })
   },

 }
}
</script>
<style lang='less' scoped>
  .login{
      width: 100%;
      overflow: auto;
      .loginBg{
        width: 100%;
        height: 100%;
        background-image: url('../assets/img/login.png');
        background-repeat: no-repeat;
        background-size:cover;
        overflow: hidden;
        .loginText{
          width: 2.66rem;
          height: 1.03rem;
          margin-top: 2.7rem;
          margin-left: 1.8rem;
          font-size: .38rem;
          color: #FD7B01;
          line-height: .54rem;
          font-weight: 600;
        }
        .line{
          width: 1.01rem;
          height: .04rem;
          margin-top: .14rem;
          margin-left: 1.8rem;
          background-color: #FD7B01;
        }
        .userName, .password{
          width: 3.4rem;
          height: .7rem;
          margin-top: .88rem;
          margin-left: 1.8rem;
          // background-color: red;
          position: relative;
          p{
            font-size: .2rem;
            color: #999;
            text-align: left;
          }
          input{
            width: 100%;
            height: .4rem;
            position: absolute;
            bottom: 0;
            left: 0;
            border: 0;
            border-bottom: 1px solid #ccc;
            outline: none;
            font-size: .16rem;
            line-height: .4rem;
          }
        }
        .password{
          margin-top: .6rem;
        }
        .loginBtn{
          width: 3.4rem;
          height: .54rem;
          background: linear-gradient(90deg, #F99803 0%, #F76907 100%);
          font-size: .26rem;
          color: #fff;
          line-height: .54rem;
          margin-top: .45rem;
          margin-left: 1.8rem;
          border-radius: .04rem;
          cursor: pointer;
        }
    }
    ::-webkit-scrollbar {
        width:0; /* Chrome Safari */
    }
  }
  ::-webkit-scrollbar {
        width:0; /* Chrome Safari */
    }
  
    
</style>