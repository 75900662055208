<template>
	<div class="questionAdmin">
		<top-bar></top-bar>
		<input type="file" ref="files" @change="selectedFile"
			style="position:absolute;width:0;height: 0;display: inline-block;" />
		<input type="file" ref="filesImg" @change="selectedImg"
			style="position:absolute;width:0;height: 0;display: inline-block;" />
		<input type="file" ref="filesVideo" @change="selectedVideo"
			style="position:absolute;width:0;height: 0;display: inline-block;" />
		<div class="content">
			<left-bar></left-bar>
			<div class="contentRight">
				<div class="contentCenter">
					<div class="title"><span>题库管理 ></span> 题管理</div>
					<div class="center">
						<div class="centerTitle">
							<div class="selectSubject" @click="searchSubjectBtn">
								<div class="nodate">{{subjectTitle}}</div>
								<div class="botArrow"></div>
								<div class="selectBar" v-show="subjectBarShow">
									<div class="setItem" v-for="(item,index) in subjectList" :key="index"
										:class="{ActiveItem:subjectItem==index?true:false}"
										@click="subjectItemBtn(item,index)">
										<div class="selectName">{{item.title}}</div>
										<div class="selectDetalis">{{item.description}}</div>
									</div>
								</div>
							</div>
							<div class="selectChapter" @click="searchChapterBtn">
								<div class="nodate">{{chapterTitle}}</div>
								<div class="botArrow"></div>
								<div class="selectBar" v-show="chapterBarShow">
									<div class="setItem" v-for="(item,index) in chapterList" :key="index"
										:class="{ActiveItem:chapterItem==index?true:false}"
										@click="chapterItemBtn(item,index)">
										<div class="selectName">{{item.title}}</div>
										<div class="selectDetalis">{{item.description}}</div>
									</div>
								</div>
							</div>
							<div class="selectSection" @click="searchSectionBtn">
								<div class="nodate">{{sectionTitle}}</div>
								<div class="botArrow"></div>
								<div class="selectBar" v-show="sectionBarShow">
									<div class="setItem" v-for="(item,index) in sectionList" :key="index"
										:class="{ActiveItem:sectionItem==index?true:false}"
										@click="sectionItemBtn(item,index)">
										<div class="selectName">{{item.title}}</div>
										<div class="selectDetalis">{{item.description}}</div>
									</div>
								</div>
							</div>
							<div class="selectType" @click="searchTypeBtn" >
								<div class="nodate">{{typeTitle}}</div>
								<div class="botArrow"></div>
								<div class="selectBar" v-show="typeBarShow">
									<div class="setItem" v-for="(item,index) in typeList" :key="index"
										:class="{ActiveItem:typeId==item.id?true:false}"
										@click="typeItemBtn(item,index)">
										<div class="selectName">{{item.title}}</div>
										<div class="selectDetalis">{{item.description}}</div>
									</div>
								</div>
							</div>
							<div class="searchInput">
								<input type="text" placeholder="快速搜索题" v-model="searchQuestion"
									@keyup.enter="searchBtn">
								<div class="searchImg" @click="searchBtn"></div>
							</div>
							<!-- <div class="addSubject" @click="addSubjectBtn">添加节</div> -->
							<div class="upLoad" @click="upLoadBtn">
								<div class="img"></div>
								<p>上传</p>
							</div>
							<div class="download" @click="downloadBtn">下载模板</div>
							<div class="addQuestion" @click="addQuestionBtn">添加题</div>
						</div>
						<div class="listTitle">
							<div class="titleId">ID</div>
							<div class="questionId">题目ID</div>
							<div class="titleName">题目</div>
							<div class="titleStyle">题型</div>
							<div class="titleHard">难易度</div>
							<div class="titleScore">分值</div>
							<div class="titleAnswer">答案</div>
							<div class="titleAnalysis">解析</div>
							<div class="titleTime">更新时间</div>
							<div class="titleOperate">操作</div>
						</div>
						<div class="listItem" v-for="(item,index) in questionList" :key="index">
							<div class="itemId">{{index+(currentPage-1)*10+currentPage}}</div>
							<div class="itemQUestionID">{{item.id}}</div>
							<div class="itemName" :title="item.title">{{item.title}}</div>
							<!-- 题型 1单选 2多选 3不定项选择 4判断 5填空 6问答题 -->
							<div class="itemStyle" v-if="item.type==1?true:false">单选</div>
							<div class="itemStyle" v-if="item.type==2?true:false">多选</div>
							<div class="itemStyle" v-if="item.type==3?true:false">不定项选择</div>
							<div class="itemStyle" v-if="item.type==4?true:false">判断</div>
							<div class="itemStyle" v-if="item.type==5?true:false">填空</div>
							<div class="itemStyle" v-if="item.type==6?true:false">问答题</div>
							<div class="itemHard" v-if="item.difficulty==1?true:false">简单</div>
							<div class="itemHard" v-if="item.difficulty==2?true:false">一般</div>
							<div class="itemHard" v-if="item.difficulty==3?true:false">困难</div>
							<div class="itemScore">{{item.score}}</div>
							<div class="itemAnswer" :title="item.answer_m">{{item.answer_m}}</div>
							<div class="itemAnalysis" :title="item.analysis">{{item.analysis}}</div>
							<div class="itemTime">{{item.updated_at | formatDate }}</div>
							<div class="itemEdit" @click.stop="itemEditBtn(item)"></div>
							<div class="itemDelete" @click.stop="deleteQuestion(item)"></div>
						</div>
						<page-bar :currentPage="currentPage" :total="total" :count="count" @jumpNumber="jumpNumber"
							@nextPage="nextPage" @prePage="prePage" @pageTo="pageTo"></page-bar>
						<div class="loadingPanel" :style="{'opacity':loadingOpacity}"
							v-show="loadingOpacity<=0?false:true">
							<img src="../assets/img/home/onLoad.gif" />
						</div>
					</div>
				</div>
				<success-bar :tipsText="success" v-if="successShow"></success-bar>
				<error-bar :tipsText="error" v-if="errorShow"></error-bar>
			</div>
		</div>
		<div class="editBar" v-show="editBar">
			<div class="editContent">
				<div class="questionTitle">
					<div class="subjectName">
						<div class="subjectTitle">库名称：</div>
						<div class="subjectInput">{{subjectTitle}}</div>
					</div>
					<div class="charpterName">
						<div class="charpterTitle">章名称：</div>
						<div class="charpterInput">{{chapterTitle}}</div>
					</div>
					<div class="sectionName">
						<div class="sectionTitle">节名称：</div>
						<div class="sectionInput">{{sectionTitle}}</div>
					</div>

				</div>
				<div class="questionOne">
					<div class="questionId" v-if="questionStyleBar==1?true:false">
						<div class="id">ID</div>
						<div class="idInput">{{questionId}}</div>
					</div>
					<div class="questionname" :class="{questionLeft:questionStyleBar==2?true:false}">
						<div class="name">题目</div>
						<div class="nameInput">
							<input type="text" v-model="title" :class="{noQuestionTitle:noTitle}">
						</div>
					</div>
				</div>
				<div class="questionTwo">
					<div class="questionStyle">
						<div class="styleText">题型</div>
						<div class="styleSelect" @click="styleSelectBtn">
							{{select}}
							<div class="selectArrow"></div>
							<div class="selectBar" v-show="selectBar">
								<div class="selectItem" v-for="(item,index) in questionStyle" :key="index"
									:class="{selectBg:type==index?true:false}" @click.stop="selectItemBtn(index,item)">
									{{item}}</div>
							</div>
						</div>
					</div>
					<div class="difficulty">
						<div class="difficultyText">难易度</div>
						<div class="difficultySelect" @click="difficultyBtn">
							{{difficulty}}
							<div class="selectArrow"></div>
							<div class="selectBar" v-show="difficultyBar">
								<div class="selectItem" v-for="(item,index) in difficultyList" :key="index"
									:class="{selectBg:difficultyIndex==index?true:false}"
									@click.stop="difficultyItemBtn(index,item)">{{item}}</div>
							</div>
						</div>
					</div>
					<div class="questionScore">
						<div class="scoreTect">分值</div>
						<input type="text" v-model="score">
					</div>
				</div>
				<div class="questionThree">
					<div class="optionA">
						<p>选项A</p>
						<input type="text" v-model="option_a">
					</div>
					<div class="optionD">
						<p>选项D</p>
						<input type="text" v-model="option_d">
					</div>
					<div class="optionB">
						<p>选项B</p>
						<input type="text" v-model="option_b">
					</div>
					<div class="optionE">
						<p>选项E</p>
						<input type="text" v-model="option_e">
					</div>
					<div class="optionC">
						<p>选项C</p>
						<input type="text" v-model="option_c">
					</div>
					<div class="optionF">
						<p>选项F</p>
						<input type="text" v-model="option_f">
					</div>

				</div>
				<div class="fourBox">
					<div class="questionFour">
						<div class="answer">答案</div>
						<textarea v-model="answer"></textarea>
					</div>

					<div class="questionFive">
						<div class="questionAnalysis">解析</div>
						<textarea v-model="analysis"></textarea>
					</div>
				</div>
				<div class="sixBox">
					<div class="annotation">
						<p>注释</p>
						<input type="text" v-model="annotation">
					</div>
					<div class="source">
						<p>来源</p>
						<input type="text" v-model="quote">
					</div>
				</div>
				<div class="boxFive">
					<div class="image">
						<div class="imgText">上传截图</div>
						<div class="imgContent">
							<img :src="imgBoxSrc" alt="">
						</div>
						<div class="imgBtn" @click="uploadImg">{{imageText}}</div>
					</div>

					<div class="video">
						<div class="videoText">视频解析</div>
						<div class="videoImg">
							<video :src="videoBoxSrc" controls></video>
						</div>
						<div class="addVideo" @click="uploadVideo">{{videoText}}</div>
					</div>

				</div>
				<div class="questionBtn">
					<div class="questionSure" @click="questionSureBtn">确定</div>
					<div class="questionCancel" @click="cancelBtn">取消</div>
				</div>
			</div>
		</div>
		<!-- <error-bar :tipsText="error" v-if="errorShow" ></error-bar> -->
		<delete-bar @cancelBtn="cancelDeleteBtn" @sureBtn="sureBtn" :deleteContent="deleteContent" v-if="deleteShow">
		</delete-bar>
	</div>
</template>

<script>
	import Config from '../assets/js/img'
	import topBar from '../components/nav.vue'
	import leftBar from '../components/left.vue'
	import pageBar from '../components/pagebarContainer.vue'
	import successBar from '../components/success.vue'
	import errorBar from '../components/error.vue'
	import deleteBar from '../components/delete.vue'
	import CryptoJS from '../assets/js/crypto-js'
	export default {
		components: {
			topBar,
			leftBar,
			pageBar,
			successBar,
			errorBar,
			deleteBar
		},
		data() {
			return {
				deleteContent: '您确定要删除吗',
				imageText: '上传图片',
				videoText: '上传视频',
				deleteShow: false,
				successShow: false,
				errorShow: false,
				success: '删除成功',
				error: '删除失败',
				subjectItem: 0,
				chapterItem: 0,
				sectionItem: 0,
				typeItem: 0,
				subjectBarShow: false,
				chapterBarShow: false,
				sectionBarShow: false,
				typeBarShow: false,
				currentPage: 1,
				total: 30,
				count: 11,
				select: '单选题',
				questionStyle: ['单选', '多选', '不定项选择', '判断', '填空', '问答'],
				selectBar: false,
				editBar: false,
				subjectList: [],
				chapterAll: '',
				sectionAll: '',
				chapterList: [],
				sectionList: [],
				subjectTitle: '',
				subjectId: '',
				chapterTitle: '',
				chapterId: '',
				sectionTitle: '',
				sectionId: '',
				questionList: [],
				questionId: '',
				//修改题
				title: '', //名称
				type: 0, //题类型
				score: '', //分值
				answer: '', //答案
				option_a: '', //选项
				option_b: '', //选项
				option_c: '', //选项
				option_d: '', //选项
				option_e: '', //选项
				option_f: '', //选项
				analysis: '', //解析
				searchQuestion: '', //搜索
				difficultyIndex: 0, //难易度
				annotation: '', //注释
				quote: '', //来源
				difficultyBar: false,
				difficultyList: ['简单', '一般', '困难'],
				difficulty: '简单',
				questionStyleBar: 1,
				noTitle: false,
				questionFill: '',
				loadingOpacity: 1, //loading 透明度
				videoFill: '', //video文件
				imageFill: '', //img文件
				imgBoxSrc: require('../assets/img/img.png'),
				videoBoxSrc: require('../assets/img/img.png'),
				CDN: Config.CDN,
				typeTitle: '所有类型',
				typeId: -1,
				typeList: [{
						id: -1,
						title: '所有类型',
						description: '所有类型'
					},
					{
						id: 1,
						title: '单选题',
						description: '单选题'
					},
					{
						id: 2,
						title: '多选题',
						description: '多选题'
					},
					{
						id: 3,
						title: '不定项选择题',
						description: '不定项选择题'
					},
					{
						id: 4,
						title: '判断题',
						description: '判断题'
					},
					{
						id: 5,
						title: '填空题',
						description: '填空题'
					},
					{
						id: 6,
						title: '问答题',
						description: '问答题'
					}
				]

			}
		},
		filters: {
			formatDate(time) {
				var date = new Date(time);
				var y = date.getFullYear();
				var MM = date.getMonth() + 1;
				MM = MM < 10 ? "0" + MM : MM;
				var d = date.getDate();
				d = d < 10 ? "0" + d : d;
				var h = date.getHours();
				h = h < 10 ? "0" + h : h;
				var m = date.getMinutes();
				m = m < 10 ? "0" + m : m;
				var s = date.getSeconds();
				s = s < 10 ? "0" + s : s;
				return y + "-" + MM + "-" + d + " " + h + ":" + m;
			}
		},
		methods: {
			//上传视频
			uploadVideo() {
				this.$refs.filesVideo.click();
			},
			//上传截图
			uploadImg() {
				this.$refs.filesImg.click();
			},
			//上传题文件
			upLoadBtn() {
				this.$refs.files.click();

			},
			selectedFile(e) {
				this.loadingOpacity = 1;
				this.questionFill = e.target.files[0];
				console.log(this.questionFill);
				var param = new FormData();
				param.append('uploadExcel', this.questionFill);
				param.append('subject_id', localStorage.getItem('question_subjectId'));
				param.append('chapter_id', localStorage.getItem('question_chapterId'));
				param.append('section_id', localStorage.getItem('question_sectionId'));
				this.$.upLoadQusstion(param).then(res => {
					console.log(res, '上传成功')
					var self = this;
					var value = 100;
					var timer1 = setInterval(function() {
						value -= 10;
						self.loadingOpacity = value / 100;
						if (value <= 0) {
							clearInterval(timer1);
						}
					}, 10);
					if (res.msg == 'ok') {
						this.success = '上传成功'
						var self = this;
						this.successShow = true;
						var time1 = setTimeout(function() {
							self.successShow = false;
							clearTimeout(time1)
							//获取题列表
							self.questionListBtn();

						}, 2000);
					} else {
						this.error = '上传失败'
						var self = this
						this.errorShow = true;
						var time2 = setTimeout(function() {
							self.errorShow = false;
							clearTimeout(time2)
							//获取题列表
							self.questionListBtn();
						}, 2000);
					}


				})
			},
			//上传图片
			selectedImg(e) {
				var self = this
				this.imageFill = e.target.files[0];
				console.log(this.imageFill, '1235456');

				var reader = new FileReader();
				reader.readAsDataURL(e.target.files[0]);
				reader.onload = function(e) {

					self.imgBoxSrc = e.target.result;
				};
			},
			videoSrc(file) {
				var url = null;

				if (window.createObjectURL != undefined) { // basic

					url = window.createObjectURL(file);

				} else if (window.URL != undefined) { // mozilla(firefox)

					url = window.URL.createObjectURL(file);

				} else if (window.webkitURL != undefined) { // webkit or chrome

					url = window.webkitURL.createObjectURL(file);

				}

				return url;
			},
			//上传视频
			selectedVideo(e) {

				var self = this
				this.videoFill = e.target.files[0];
				console.log(this.videoFill, '1235456');
				self.videoBoxSrc = this.videoSrc(e.target.files[0]);

				console.log('src---------', self.videoBoxSrc);
				// var reader = new FileReader();
				// reader.readAsDataURL(e.target.files[0]);
				// reader.onload = function (e) {

				//     self.videoBoxSrc =   e.target.result;
				// };
			},
			//解密
			secret(string, code, operation) {
				code = CryptoJS.MD5(code).toString();
				var iv = CryptoJS.enc.Utf8.parse(code.substring(0, 16));
				var key = CryptoJS.enc.Utf8.parse(code.substring(16));
				if (operation) {
					return CryptoJS.AES.decrypt(string, key, {
						iv: iv,
						padding: CryptoJS.pad.Pkcs7
					}).toString(CryptoJS.enc.Utf8);
				}
				return CryptoJS.AES.encrypt(string, key, {
					iv: iv,
					mode: CryptoJS.mode.CBC,
					padding: CryptoJS.pad.Pkcs7
				}).toString();
			},
			//添加
			addQuestionBtn() {
				this.questionStyleBar = 2;
				this.editBar = true;
				this.title = ''; //名称
				this.type = 0; //题类型
				this.score = ''; //分值
				this.answer = ''; //答案
				this.option_a = ''; //选项
				this.option_b = ''; //选项
				this.option_c = ''; //选项
				this.option_d = ''; //选项
				this.option_e = ''; //选项
				this.option_f = ''; //选项
				this.analysis = ''; //解析
				this.searchQuestion = ''; //搜索
				this.difficultyIndex = 0; //难易度
				this.imgBoxSrc = require('../assets/img/img.png');
				this.videoBoxSrc = '';
				this.select = '单选题'
			},
			//修改
			questionSureBtn() {
				if (this.questionStyleBar == 1) {
					if (this.title != '') {
						this.noTitle = false;
						var param = new FormData();
						param.append('id', this.questionId);
						param.append('chapter_id', localStorage.getItem('question_chapterId'));
						param.append('section_id', localStorage.getItem('question_sectionId'));
						param.append('type', this.type + 1);
						// param.append('')
						param.append('title', this.title);
						param.append('score', this.score);
						// var _m = this.secret('www.redgezi.cn', 'www.redgezi.cn', false);
						param.append('option_a', this.option_a);
						param.append('option_b', this.option_b.trim());
						param.append('option_c', this.option_c.trim());
						param.append('option_d', this.option_d.trim());
						param.append('option_e', this.option_e.trim());
						param.append('option_f', this.option_f.trim());
						param.append('answer', this.answer);
						param.append('analysis', this.analysis);
						param.append('video', this.videoFill);
						param.append('difficulty', this.difficultyIndex + 1);
						param.append('annotation', this.annotation);
						param.append('screenshot', this.imageFill);
						param.append('quote', this.quote)
						this.$.editQuestion(param).then(res => {
							console.log(res, '编辑题')
							console.log(this.type + 1, '编辑题++++++++++++++++++++++')
							var self = this;
							if (res.code == 200 && res.msg == 'ok') {
								this.success = '编辑成功'
								this.successShow = true;
								var time1 = setTimeout(function() {
									self.successShow = false;
									clearTimeout(time1)
								}, 2000);
								this.questionListBtn();
							} else {
								this.error = '编辑失败'
								this.errorShow = true;
								var time2 = setTimeout(function() {
									self.errorShow = false;
									clearTimeout(time2)
								}, 2000);
							}
						})
						console.log(localStorage.getItem('question_chapterId'));
						this.editBar = false;
					} else {
						this.noTitle = reue;
						this.error = '编辑失败'
						this.errorShow = true;
						var time2 = setTimeout(function() {
							self.errorShow = false;
							clearTimeout(time2)
						}, 2000);
					}

				}
				if (this.questionStyleBar == 2) {
					if (this.title != '') {
						this.noTitle = false;
						var param = new FormData();
						param.append('subject_id', localStorage.getItem('question_subjectId'))
						param.append('chapter_id', localStorage.getItem('question_chapterId'));
						param.append('section_id', localStorage.getItem('question_sectionId'));
						param.append('type', this.type + 1);
						console.log(this.type + 1, '564565656提类型')
						// param.append('')
						param.append('title', this.title);
						param.append('score', this.score);
						param.append('option_a', this.option_a);
						param.append('option_b', this.option_b.trim());
						param.append('option_c', this.option_c.trim());
						param.append('option_d', this.option_d.trim());
						param.append('option_e', this.option_e.trim());
						param.append('option_f', this.option_f.trim());
						param.append('answer', this.answer);
						param.append('analysis', this.analysis);
						param.append('video', this.videoFill);
						param.append('difficulty', this.difficultyIndex + 1);
						param.append('annotation', this.annotation);
						param.append('screenshot', this.imageFill);
						param.append('quote', this.quote)
						this.$.addQuestion(param).then(res => {
							console.log(res, '添加题')
							var self = this;
							if (res.code == 200 && res.msg == 'ok') {
								this.success = '编辑成功'
								this.successShow = true;
								var time1 = setTimeout(function() {
									self.successShow = false;
									clearTimeout(time1)
								}, 2000);
								this.questionListBtn();
							} else {
								this.error = '编辑失败'
								this.errorShow = true;
								var time2 = setTimeout(function() {
									self.errorShow = false;
									clearTimeout(time2)
								}, 2000);
							}
						})
						console.log(localStorage.getItem('question_chapterId'));
						this.editBar = false;
					} else {
						this.noTitle = reue;
						this.error = '编辑失败'
						this.errorShow = true;
						var time2 = setTimeout(function() {
							self.errorShow = false;
							clearTimeout(time2)
						}, 2000);
					}
				}

			},
			nextPage() {
				this.currentPage++;
				if (this.currentPage >= this.pageSize) {
					this.currentPage = this.pageSize;
				}
				//获取题列表
				this.questionListBtn();
			},
			pageTo(index) {
				this.currentPage = index;
				//获取题列表
				this.questionListBtn();
			},
			prePage() {
				this.currentPage--;
				if (this.currentPage <= 1) {
					this.currentPage = 1;
				}
				//获取题列表
				this.questionListBtn();
			},
			jumpNumber(val) {
				if (val == '') {
					val = 1
				}
				if (val >= Math.ceil(this.total / this.count)) {
					val = Math.ceil(this.total / this.count)
				}
				this.currentPage = val / 1;
				//获取题列表
				this.questionListBtn();
			},
			//删除题
			deleteQuestion(item) {
				console.log('12356456===============')
				this.questionId = item.id;
				this.deleteShow = true;
				this.deleteContent = '您确定要删除该题吗？';
			},
			sureBtn() {
				var self = this;
				var param = new FormData();
				param.append('id', this.questionId);
				this.$.deleteQuestion(param).then(res => {
					console.log(res)
					this.deleteShow = false;
					if (res.code == 200 && res.msg == 'ok') {
						this.success = '删除成功';
						this.successShow = true;
						var time1 = setTimeout(function() {
							self.successShow = false;
							clearTimeout(time1)
						}, 2000);
						this.questionListBtn();
					} else {
						this.error = '删除失败';
						this.errorShow = true;
						var time2 = setTimeout(function() {
							self.errorShow = false;
							clearTimeout(time2)
						}, 2000);
					}
				})
			},
			cancelDeleteBtn() {
				this.deleteShow = false;
				console.log(12365)
			},
			//获取库列表
			subjectListBtn() {
				var param = new FormData();
				param.append('page', 1);
				param.append('count', 100000)
				this.$.subjectList(param).then(res => {
					console.log(res, '+++++++++++++++++++++++===================')
					this.subjectList = res.subjectInfo.data;
					this.subjectTitle = localStorage.getItem('question_subjectTitle');
					this.subjectId = localStorage.getItem('question_subjectId');
					var param = new FormData();
					param.append('subject_id', this.subjectId);
					param.append('page', 1);
					param.append('count', 1000000);
					this.$.chapterList(param).then(res => {
						console.log(res, '章========================')
						this.chapterList = res.chapterInfo.data;
						console.log(this.chapterList)
						this.chapterTitle = localStorage.getItem('question_chapterTitle');
						this.chapterId = localStorage.getItem('question_chapterId');
						//获取节总数
						if (this.chapterId != '') {
							var paramsection = new FormData()
							paramsection.append('subject_id', this.subjectId);
							paramsection.append('chapter_id', this.chapterId);
							paramsection.append('page', 1);
							paramsection.append('count', 12);
							this.$.sectionList(paramsection).then(res => {
								this.sectionAll = res.sectionInfo.total;
								//获取节列表
								if (this.sectionAll != 0) {
									var paramsection = new FormData()
									paramsection.append('subject_id', this.subjectId);
									paramsection.append('chapter_id', this.chapterId);
									paramsection.append('page', 1);
									paramsection.append('count', this.sectionAll);
									this.$.sectionList(paramsection).then(res => {
										this.sectionList = res.sectionInfo.data;
										//获取题列表
										this.questionListBtn();
									})
								} else {
									this.sectionList = [];
									this.sectionTitle = '';
									// this.sectionId = 0;
									// localStorage.setItem('question_sectionId',0)
									//获取题列表
									this.questionListBtn();
								}

							})
						}



					})


				})

			},
			//选择题库
			subjectItemBtn(item, index) {
				localStorage.setItem('question_subjectTitle', item.title);
				localStorage.setItem('question_subjectId', item.id);
				localStorage.setItem('question_subjectItem', index);
				localStorage.setItem('question_chapterTitle', '');
				localStorage.setItem('question_chapterId', '');
				localStorage.setItem('question_sectionTitle', '');
				localStorage.setItem('question_sectionId', '');
				this.subjectTitle = localStorage.getItem('question_subjectTitle');
				this.subjectId = localStorage.getItem('question_subjectId');
				this.subjectItem = localStorage.getItem('question_subjectItem');
				// this.sectionTitle = localStorage.getItem('question_sectionTitle');
				console.log(this.subjectId, '库')
				//获取章列表
				var param = new FormData();
				param.append('subject_id', this.subjectId);
				param.append('page', 1);
				param.append('count', 99999);
				this.$.chapterList(param).then(res => {
					this.chapterList = res.chapterInfo.data;
					console.log(this.chapterList)
					this.chapterTitle = localStorage.getItem('question_chapterTitle');
					this.chapterId = localStorage.getItem('question_chapterId');
					//获取节总数
					if (this.chapterId = '') {
						var paramsection = new FormData()
						paramsection.append('subject_id', this.subjectId);
						paramsection.append('chapter_id', this.chapterId);
						paramsection.append('page', 1);
						paramsection.append('count', 12);
						this.$.sectionList(paramsection).then(res => {
							console.log(res, '节总数')
							this.sectionAll = res.sectionInfo.total;
							//获取节列表
							if (this.sectionAll != 0) {
								var paramsection = new FormData()
								paramsection.append('subject_id', this.subjectId);
								paramsection.append('chapter_id', this.chapterId);
								paramsection.append('page', 1);
								paramsection.append('count', this.sectionAll);
								this.$.sectionList(paramsection).then(res => {
									console.log(res, '=========+++++')
									this.sectionList = res.sectionInfo.data;
									//获取题列表
									this.questionListBtn();
								})
							} else {
								this.sectionList = [];
								this.sectionTitle = '';
								// this.sectionId = 0;
								// localStorage.setItem('question_sectionId',0)
								//获取题列表
								this.questionListBtn();
							}

						})
					}



				})

				// //获取题列表
				this.questionList = [];
			},
			//选择章
			chapterItemBtn(item, index) {
				localStorage.setItem('question_chapterTitle', item.title);
				localStorage.setItem('question_chapterId', item.id);
				localStorage.setItem('question_chapterItem', index);
				localStorage.setItem('question_sectionTitle', '');
				localStorage.setItem('question_sectionId', '');
				this.chapterTitle = localStorage.getItem('question_chapterTitle');
				this.chapterId = localStorage.getItem('question_chapterId');
				this.chapterItem = localStorage.getItem('question_chapterItem');
				console.log(this.chapterId, '章')
				//获取节总数
				var paramsection = new FormData()
				paramsection.append('subject_id', this.subjectId);
				paramsection.append('chapter_id', this.chapterId);
				paramsection.append('page', 1);
				paramsection.append('count', 12);
				this.$.sectionList(paramsection).then(res => {
					this.sectionAll = res.sectionInfo.total;
					console.log(this.sectionAll, '456564565645454545')
					//获取节列表
					if (this.sectionAll != 0) {
						var paramsection = new FormData()
						paramsection.append('subject_id', this.subjectId);
						paramsection.append('chapter_id', this.chapterId);
						paramsection.append('page', 1);
						paramsection.append('count', this.sectionAll);
						this.$.sectionList(paramsection).then(res => {
							console.log(res, '=========+++++')
							this.sectionList = res.sectionInfo.data;
							//获取题列表
							this.questionListBtn();
						})
					} else {
						this.sectionList = [];
						this.sectionTitle = '';
						// this.sectionId = 0;
						// localStorage.setItem('question_sectionId',0)
						//获取题列表
						this.questionListBtn();
					}

				})
				//获取题列表
				this.questionListBtn();
			},
			//选择节
			sectionItemBtn(item, index) {
				console.log(item, '选择节')
				localStorage.setItem('question_sectionTitle', item.title);
				localStorage.setItem('question_sectionId', item.id);
				localStorage.setItem('question_sectionItem', index);
				this.sectionTitle = localStorage.getItem('question_sectionTitle');
				this.sectionId = localStorage.getItem('question_sectionId');
				this.sectionItem = localStorage.getItem('question_sectionItem');
				this.questionListBtn();
				console.log(this.sectionId, '节')
				//获取题列表
				// this.questionListBtn();
			},
			typeItemBtn(item, index) {
				this.typeTitle = item.title;
				this.typeId = item.id;
				this.questionListBtn();
			},
			//获取题列表
			questionListBtn() {
				//获取题列表
				var questionParam = new FormData();
				questionParam.append('subject_id', this.subjectId);
				questionParam.append('chapter_id', this.chapterId);
				questionParam.append('section_id', localStorage.getItem('question_sectionId'));
				questionParam.append('type', this.typeId);
				questionParam.append('page', this.currentPage);
				questionParam.append('count', this.count);
				questionParam.append('query', this.searchQuestion);
				this.$.questionList(questionParam).then(res => {
					this.loadingOpacity = 1;
					console.log(res, '替列表')
					this.questionList = res.questionInfo.data;
					this.total = res.questionInfo.total
					var self = this;
					console.log(res, '=====================')
					// this.total = res.chapterInfo.total;
					// this.chapterList = res.chapterInfo.data;
					var value = 100;
					var timer = setInterval(function() {
						value -= 10;
						self.loadingOpacity = value / 100;
						if (value <= 0) {
							clearInterval(timer);
						}
					}, 10);
				})
			},
			//搜索
			searchBtn() {
				this.questionListBtn();
			},
			searchSubjectBtn() {
				this.subjectBarShow = !this.subjectBarShow;
				this.chapterBarShow = false;
			},
			searchChapterBtn() {
				this.chapterBarShow = !this.chapterBarShow;
				this.subjectBarShow = false;
			},
			searchSectionBtn() {
				this.sectionBarShow = !this.sectionBarShow
			},
			searchTypeBtn() {
				this.typeBarShow = !this.typeBarShow;
			},
			selectItemBtn(index, item) {
				this.type = index;
				this.select = item;
				this.selectBar = false;
			},
			styleSelectBtn() {
				this.selectBar = !this.selectBar;
			},
			itemEditBtn(item) {
				this.imageText = '修改图片';
				this.videoText = '修改视频';
				this.imageFill = '';
				this.videoFill = '';
				this.editBar = true;
				this.questionId = item.id;
				this.questionStyleBar = 1;
				//获取题信息
				var param = new FormData();
				param.append('id', this.questionId)
				this.$.questionData(param).then(res => {
					console.log(res, '题信息')
					this.title = res.questionInfo.title;
					this.select = this.questionStyle[res.questionInfo.type - 1];
					this.type = res.questionInfo.type - 1
					this.score = res.questionInfo.score;
					this.answer = res.questionInfo.answer;
					var _m = this.secret('www.redgezi.cn', 'www.redgezi.cn', false)
					this.option_a = this.secret(res.questionInfo.option_a, _m, true);
					console.log(res.questionInfo.option_a, '789789889879');
					console.log(this.secret(this.option_a, _m, false), '56565656454545')
					this.option_b = res.questionInfo.option_b;
					this.option_c = res.questionInfo.option_c;
					this.option_d = res.questionInfo.option_d;
					this.option_e = res.questionInfo.option_e;
					this.option_f = res.questionInfo.option_f;
					this.analysis = res.questionInfo.analysis;
					this.difficulty = this.difficultyList[res.questionInfo.difficulty - 1];
					this.annotation = res.questionInfo.annotation == null ? '' : res.questionInfo.annotation;
					this.quote = res.questionInfo.quote == null ? '' : res.questionInfo.quote;
					this.videoBoxSrc = this.CDN + res.questionInfo.video;
					this.imgBoxSrc = this.CDN + res.questionInfo.screenshot;
				})
			},
			cancelBtn() {
				this.editBar = false;
			},
			difficultyItemBtn(index, item) {
				this.difficultyIndex = index;
				this.difficulty = item;
				this.difficultyBar = false;
			},
			difficultyBtn() {
				this.difficultyBar = !this.difficultyBar;
			},
			//下载模板
			downloadBtn() {
				window.open('http://localhost/上传模板.xlsx', '_blank');
			}
		},
		mounted() {
			this.subjectListBtn();
			this.sectionTitle = localStorage.getItem('question_sectionTitle');
			this.subjectItem = localStorage.getItem('question_subjectItem');
			this.chapterItem = localStorage.getItem('question_chapterItem');
			this.sectionItem = localStorage.getItem('question_sectionItem');



		}
	}
</script>

<style lang='less' scoped>
	.questionAdmin {
		width: 100%;
		height: 1080px;
		background-color: #f2f2f2;
		position: relative;

		.content {
			width: 100%;
			display: flex;
			height: 100vh;
			margin-top: 60px;
		}

		.contentRight {
			flex: 1;
			margin-left: 195px;
			height: 1000px;

			.contentCenter {
				width: 1570px;
				height: 100%;
				margin: auto;

				.title {
					font-size: 20px;
					color: #333;
					text-align: left;
					margin-top: 23px;

					span {
						color: #ccc;
					}
				}

				.center {
					width: 1570px;
					height: 920px;
					margin-top: 20px;
					background-color: #fff;
					border-radius: 10px;
					position: relative;

					.centerTitle {
						width: 1490px;
						height: 107px;
						border-bottom: 1px solid #ccc;
						margin: auto;
						position: relative;

						.selectSubject,
						.selectChapter,
						.selectSection,
						 .selectType{
							width: 181px;
							height: 44px;
							border: 1px solid #ccc;
							box-sizing: border-box;
							border-radius: 4px;
							float: left;
							margin-right: 24px;
							margin-top: 28px;
							position: relative;
							cursor: pointer;

							.botArrow {
								width: 15px;
								height: 100%;
								position: absolute;
								right: 15px;
								top: 0;
								background-image: url('../assets/img/home/botArrow.png');
								background-repeat: no-repeat;
								background-size: 100% 9px;
								background-position: center;
							}

							.nodate {
								font-size: 14px;
								color: #333;
								line-height: 44px;
							}

							.selectBar {
								position: absolute;
								top: 50px;
								width: 289px;
								height: 375px;
								left: 0;
								z-index: 99999;
								background-color: #fff;
								border-radius: 10px;
								box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
								overflow: auto;

								.setItem {
									width: 100%;
									height: 57px;
									margin-top: 8px;
									overflow: hidden;

									.selectName {
										width: 100%;
										font-size: 14px;
										color: #333;
										margin-top: 7px;
										text-align: left;
										text-indent: 25px;
										text-overflow: ellipsis;
										overflow: hidden;
										white-space: nowrap;
									}

									.selectDetalis {
										width: 100%;
										font-size: .12rem;
										color: #999;
										margin-top: 6px;
										text-align: left;
										text-indent: 25px;
										text-overflow: ellipsis;
										overflow: hidden;
										white-space: nowrap;
									}
								}

								.ActiveItem {
									background-color: rgba(241, 241, 241, .5);
								}
							}

							::-webkit-scrollbar {
								width: 2px;
								background-color: transparent;
							}

							::-webkit-scrollbar-thumb {
								background-color: #CCCCCC;
								border-radius: .1rem;
							}
						}

						.searchInput {
							width: 287px;
							height: 44px;
							margin-top: 28px;
							position: relative;
							float: left;

							input {
								width: 100%;
								height: 100%;
								border: 1px solid #ccc;
								box-sizing: border-box;
								border-radius: 4px;
								float: left;
								outline: none;
								font-size: 14px;
								text-indent: 22px;
								line-height: 44px;
							}

							.searchImg {
								width: 24px;
								height: 100%;
								position: absolute;
								right: 21px;
								// background-color: #333;
								cursor: pointer;
								background-image: url('../assets/img/home/search.png');
								background-repeat: no-repeat;
								background-size: .24rem .24rem;
								background-position: center;
							}
						}

						.upLoad {
							width: 122px;
							height: 38px;
							float: right;
							border-radius: 4px;
							border: 1px solid #ccc;
							margin-top: 28px;
							cursor: pointer;

							.img {
								width: 20px;
								height: 100%;
								float: left;
								margin-left: 24px;
								background-image: url('../assets/img/home/img.png');
								background-repeat: no-repeat;
								background-size: 100% 22px;
								background-position: center;
							}

							p {
								font-size: 14px;
								color: #ccc;
								line-height: 38px;
								float: left;
								margin-left: 15px;
							}
						}

						.addQuestion {
							width: 122px;
							height: 38px;
							float: right;
							margin-right: 20px;
							border: 1px solid #F15764;
							box-sizing: border-box;
							border-radius: 4px;
							margin-top: 28px;
							font-size: 14px;
							color: #F15764;
							line-height: 38px;
							cursor: pointer;
						}

						.download {
							width: 122px;
							font-size: 14px;
							color: #F15764;
							position: absolute;
							right: 0;
							top: 79px;
							text-decoration: underline;
							cursor: pointer;
						}
					}

					.listTitle {
						width: 1491px;
						height: 60px;
						background-color: #f2f2f2;
						border-radius: 4px;
						margin: 36px auto 20px;

						.titleId {
							font-size: 14px;
							color: #333;
							line-height: 60px;
							float: left;
							margin-left: 40px;
						}

						.questionId {
							font-size: 14px;
							color: #333;
							line-height: 60px;
							float: left;
							margin-left: 60px;
						}

						.titleName {
							font-size: 14px;
							color: #333;
							line-height: 60px;
							float: left;
							margin-left: 65px;
						}

						.titleStyle {
							font-size: 14px;
							color: #333;
							line-height: 60px;
							float: left;
							margin-left: 150px;
						}

						.titleHard {
							font-size: 14px;
							color: #333;
							line-height: 60px;
							float: left;
							margin-left: 105px;
						}

						.titleScore {
							font-size: 14px;
							color: #333;
							line-height: 60px;
							float: left;
							margin-left: 75px;
						}

						.titleAnswer {
							font-size: 14px;
							color: #333;
							line-height: 60px;
							float: left;
							margin-left: 96px;
						}

						.titleAnalysis {
							font-size: 14px;
							color: #333;
							line-height: 60px;
							float: left;
							margin-left: 89px;
						}

						.titleTime {
							font-size: 14px;
							color: #333;
							line-height: 60px;
							float: left;
							margin-left: 218px;
						}

						.titleOperate {
							font-size: 14px;
							color: #333;
							line-height: 60px;
							float: left;
							margin-left: 167px;
						}
					}

					.listItem {
						width: 1490px;
						height: 44px;
						margin: 11px auto;

						.itemId {
							width: 38px;
							height: 100%;
							font-size: 14px;
							color: #333;
							line-height: 44px;
							float: left;
							margin-left: 24px;
						}

						.itemQUestionID {
							width: 38px;
							height: 100%;
							font-size: 14px;
							color: #333;
							line-height: 44px;
							float: left;
							margin-left: 53px;
						}

						.itemName {
							width: 102px;
							color: #333;
							line-height: 44px;
							float: left;
							font-size: 14px;
							margin-left: 65px;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
							text-align: left;
						}

						.itemStyle {
							width: 54px;
							height: 44px;
							font-size: 14px;
							color: #333;
							line-height: 44px;
							float: left;
							margin-left: 70px;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
						}

						.itemHard {
							width: 38px;
							height: 100%;
							font-size: 14px;
							color: #333;
							line-height: 44px;
							float: left;
							margin-left: 90px;
						}

						.itemScore {
							width: 38px;
							height: 100%;
							font-size: 14px;
							color: #333;
							line-height: 44px;
							float: left;
							margin-left: 72px;
						}

						.itemAnswer {
							width: 54px;
							height: 100%;
							font-size: 14px;
							color: #333;
							line-height: 44px;
							float: left;
							margin-left: 82px;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
						}

						.itemAnalysis {
							width: 127px;
							height: 100%;
							font-size: 14px;
							color: #333;
							line-height: 44px;
							float: left;
							margin-left: 73px;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
							text-align: left;
						}

						.itemTime {
							width: 156px;
							height: 100%;
							font-size: 14px;
							color: #333;
							line-height: 44px;
							float: left;
							margin-left: 81px;
						}

						.itemEdit {
							width: 21px;
							height: 100%;
							background-image: url('../assets/img/home/edit.png');
							background-size: 100% 21px;
							background-repeat: no-repeat;
							background-position: center;
							float: left;
							margin-left: 95px;
							cursor: pointer;
						}

						.itemDelete {
							width: 18px;
							height: 100%;
							background-image: url('../assets/img/home/delete.png');
							background-repeat: no-repeat;
							background-size: 100% 20px;
							background-position: center;
							float: left;
							margin-left: 31px;
							cursor: pointer;
						}
					}

					.loadingPanel {
						position: absolute;
						width: 100%;
						height: 709px;
						background-color: white;
						z-index: 999;
						pointer-events: none;
						display: flex;
						flex-wrap: nowrap;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						z-index: 999;
						pointer-events: auto;
						left: 0;
						top: 108px;

						img {
							width: .6rem;

						}
					}
				}
			}
		}

		.editBar {
			width: 100%;
			height: 100%;
			background-color: rgba(51, 51, 51, 0.4);
			position: fixed;
			top: 0;
			left: 0;
			z-index: 999;

			.editContent {
				width: 1036px;
				height: 886px;
				background-color: #fff;
				border-radius: 10px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				.questionTitle {
					width: 100%;
					height: 40px;
					margin-top: 20px;

					// background-color: red;
					.subjectName,
					.charpterName,
					.sectionName {
						width: 25%;
						height: 100%;
						display: flex;
						float: left;

						.subjectTitle,
						.charpterTitle,
						.sectionTitle {
							font-size: 16px;
							color: #333;
							line-height: .4rem;
							float: left;
							margin-left: 15px;
						}

						.subjectInput,
						.charpterInput,
						.sectionInput {
							flex: 1;
							height: 100%;
							float: left;
							background-color: #f2f2f2;
							border: 1px solid #ccc;
							border-radius: 4px;
							box-sizing: border-box;
							font-size: 18px;
							color: #666;
							line-height: 40px;
						}
					}
				}

				.questionOne {
					width: 100%;
					height: 72px;
					margin-top: 20px;

					// background-color: #333;
					.questionId {
						width: 122px;
						height: 100%;
						float: left;
						margin-left: 68px;

						.id {
							font-size: 14px;
							color: #333;
							text-align: left;
						}

						.idInput {
							width: 100%;
							height: 40px;
							background-color: #ccc;
							font-size: 14px;
							color: #b7b7b7;
							line-height: 40px;
							border-radius: 4px;
							margin-top: 16px;
						}
					}

					.questionname {
						width: 710px;
						height: 100%;
						float: left;
						margin-left: 52px;

						.name {
							font-size: 14px;
							color: #333;
							text-align: left;
						}

						.nameInput {
							width: 710px;
							height: 40px;
							// border: 1px solid #707070;
							border-radius: 4px;
							outline: none;
							margin-top: 16px;
							position: relative;

							input {
								width: 100%;
								height: 100%;
								position: absolute;
								top: 0;
								left: 0;
								border: 1px solid #707070;
								box-sizing: border-box;
								border-radius: 4px;
								font-size: 14px;
								color: #333;
								outline: none;
								text-indent: 14px;
							}

							.noQuestionTitle {
								border: 1px solid #ff0000;
							}
						}
					}

					.questionLeft {
						margin-left: 66px;
					}
				}

				.questionTwo {
					width: 100%;
					height: 72px;
					margin-top: 26px;

					.questionStyle {
						width: 214px;
						height: 100%;
						float: left;
						margin-left: 68px;

						.styleText {
							font-size: 14px;
							color: #333;
							text-align: left;
						}

						.styleSelect {
							width: 100%;
							height: 40px;
							margin-top: 14px;
							border-radius: 4px;
							border: 1px solid #707070;
							box-sizing: border-box;
							position: relative;
							cursor: pointer;
							font-size: 14px;
							color: #333;
							line-height: 40px;

							.selectArrow {
								width: 21px;
								height: 100%;
								background-image: url('../assets/img/home/botArrow.png');
								background-repeat: no-repeat;
								background-size: 100% 15px;
								background-position: center;
								position: absolute;
								right: 11px;
								z-index: 22;
								top: 0;
							}

							.selectBar {
								width: 100%;
								height: 160px;
								position: absolute;
								top: 40px;
								left: 0;
								z-index: 99999;
								box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
								z-index: 22;
								background-color: #fff;
								overflow: auto;

								.selectItem {
									width: 100%;
									height: 40px;
									font-size: 14px;
									color: #333;
									line-height: 40px;
								}

								.selectBg {
									background-color: rgba(241, 87, 100, .2);
									color: #F15764;
								}
							}

							::-webkit-scrollbar {
								width: 0;
							}
						}
					}

					.difficulty {
						width: 180px;
						height: 100%;
						float: left;
						margin-left: 68px;

						.difficultyText {
							font-size: 14px;
							color: #333;
							text-align: left;
						}

						.difficultySelect {
							width: 100%;
							height: 40px;
							margin-top: 14px;
							border-radius: 4px;
							border: 1px solid #707070;
							box-sizing: border-box;
							position: relative;
							cursor: pointer;
							font-size: 14px;
							color: #333;
							line-height: 40px;

							.selectArrow {
								width: 21px;
								height: 100%;
								background-image: url('../assets/img/home/botArrow.png');
								background-repeat: no-repeat;
								background-size: 100% 15px;
								background-position: center;
								position: absolute;
								right: 11px;
								z-index: 22;
								top: 0;
							}

							.selectBar {
								width: 100%;
								height: 120px;
								position: absolute;
								top: 40px;
								left: 0;
								z-index: 99999;
								box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
								z-index: 22;
								background-color: #fff;

								.selectItem {
									width: 100%;
									height: 40px;
									font-size: 14px;
									color: #333;
									line-height: 40px;
								}

								.selectBg {
									background-color: rgba(241, 87, 100, .2);
									color: #F15764;
								}
							}
						}
					}

					.questionScore {
						width: 87px;
						height: 100%;
						float: left;
						margin-left: 102px;
						position: relative;

						.scoreTect {
							font-size: 14px;
							color: #333;
							text-align: left;
						}

						input {
							width: 100%;
							height: 40px;
							border: 1px solid #707070;
							border-radius: 4px;
							box-sizing: border-box;
							position: absolute;
							top: 32px;
							left: 0;
							outline: none;
							font-size: 14px;
							text-indent: 16px;
						}
					}
				}

				.questionThree {
					width: 100%;
					height: 175px;
					margin-top: 30px;

					.optionA,
					.optionB,
					.optionC {
						width: 409px;
						height: 40px;
						margin-left: 68px;
						position: relative;
						float: left;
						margin-bottom: 18px;

						p {
							font-size: 14px;
							line-height: 40px;
							float: left;
							color: #333;
						}

						input {
							width: 347px;
							height: 40px;
							border: 1px solid #707070;
							border-radius: 4px;
							box-sizing: border-box;
							position: absolute;
							right: 0;
							outline: none;
							text-indent: 16px;
							font-size: 14px;
							color: #333;
							text-indent: 14px;

						}

					}

					.optionD,
					.optionE,
					.optionF {
						width: 409px;
						height: 40px;
						margin-left: 66px;
						position: relative;
						float: left;
						margin-bottom: 18px;

						p {
							font-size: 14px;
							line-height: 40px;
							float: left;
							color: #333;
						}

						input {
							width: 347px;
							height: 40px;
							border: 1px solid #707070;
							border-radius: 4px;
							box-sizing: border-box;
							position: absolute;
							right: 0;
							outline: none;
							text-indent: 16px;
							font-size: 14px;
						}
					}

				}

				.fourBox {
					width: 900px;
					height: 130px;
					margin: auto;

					// margin-top: 30px;
					.questionFour {
						width: 50%;
						height: 130px;
						position: relative;
						float: left;

						.answer {
							font-size: 14px;
							color: #333;
							float: left;
							line-height: 130px;
						}

						textarea {
							width: 347px;
							height: 130px;
							border-radius: 4px;
							border: 1px solid #707070;
							box-sizing: border-box;
							float: left;
							margin-left: 35px;
							resize: none;
							font-size: 14px;
							outline: none;
							text-indent: 16px;
						}
					}

					.questionFive {
						width: 50%;
						height: 130px;
						position: relative;
						float: right;

						.questionAnalysis {
							font-size: 14px;
							color: #333;
							float: left;
							line-height: 130px;
							margin-left: 26px;
						}

						textarea {
							width: 347px;
							height: 130px;
							border-radius: 4px;
							border: 1px solid #707070;
							box-sizing: border-box;
							float: left;
							margin-left: 35px;
							resize: none;
							font-size: 14px;
							outline: none;
							text-indent: 16px;
						}

						::-webkit-scrollbar {
							width: 2px;
							background-color: transparent;
						}

						::-webkit-scrollbar-thumb {
							background-color: #f2f2f2;
						}
					}
				}

				.sixBox {
					width: 100%;
					height: 40px;
					margin: auto;
					margin-top: 20px;

					.annotation {
						width: 409px;
						height: 40px;
						margin-left: 68px;
						position: relative;
						float: left;
						margin-bottom: 18px;

						p {
							font-size: 14px;
							line-height: 40px;
							float: left;
							color: #333;
						}

						input {
							width: 347px;
							height: 40px;
							border: 1px solid #707070;
							border-radius: 4px;
							box-sizing: border-box;
							position: absolute;
							right: 0;
							outline: none;
							text-indent: 16px;
							font-size: 14px;
							color: #333;
							text-indent: 14px;

						}
					}

					.source {
						width: 409px;
						height: 40px;
						margin-left: 66px;
						position: relative;
						float: left;
						margin-bottom: 18px;

						p {
							font-size: 14px;
							line-height: 40px;
							float: left;
							color: #333;
						}

						input {
							width: 347px;
							height: 40px;
							border: 1px solid #707070;
							border-radius: 4px;
							box-sizing: border-box;
							position: absolute;
							right: 0;
							outline: none;
							text-indent: 16px;
							font-size: 14px;
						}
					}
				}

				.boxFive {
					width: 100%;
					margin: 27px auto 0;
					height: 130px;
					// margin-top: 27px;
					position: relative;

					.image {
						width: 50%;
						height: 100%;
						float: left;
						position: relative;

						.imgText {
							font-size: 14px;
							color: #333;
							float: left;
							line-height: 130px;
							margin-left: 56px;
						}

						.imgContent {
							width: 224px;
							height: 126px;
							border: 1px solid #707070;
							border-radius: 4px;
							float: left;
							margin-left: 20px;
							position: relative;

							img {
								width: 100%;
								height: 100%;
								position: absolute;
								top: 0;
								left: 0;
								border-radius: 4px;
							}
						}

						.imgBtn {
							width: 80px;
							height: 27px;
							float: right;
							background-color: #F15764;
							border-radius: 4px;
							float: left;
							margin-left: 20px;
							margin-top: 99px;
							cursor: pointer;
							font-size: 12px;
							color: #fff;
							line-height: 27px;
						}
					}

					.video {
						width: 50%;
						height: 100%;
						float: right;
						position: relative;

						.videoText {
							font-size: 14px;
							color: #333;
							float: left;
							margin-left: 12px;
							line-height: 130px;
						}

						.videoImg {
							width: 224px;
							height: 126px;
							border: 1px solid #707070;
							border-radius: 4px;
							float: left;
							margin-left: 20px;
							position: relative;

							video {
								width: 100%;
								height: 100%;
								position: absolute;
								top: 0;
								left: 0;
								border-radius: 4px;
							}
						}

						.addVideo {
							width: 80px;
							height: 27px;
							float: right;
							background-color: #F15764;
							border-radius: 4px;
							float: left;
							margin-left: 20px;
							margin-top: 99px;
							cursor: pointer;
							font-size: 12px;
							color: #fff;
							line-height: 27px;
						}
					}

				}

				.questionBtn {
					width: 412px;
					height: 38px;
					margin: 24px auto 0;

					.questionSure {
						width: 153px;
						height: 100%;
						background-color: #F15764;
						color: #fff;
						font-size: 14px;
						line-height: 38px;
						float: left;
						border-radius: 4px;
						cursor: pointer;
					}

					.questionCancel {
						width: 153px;
						height: 100%;
						border: 1px solid #F15764;
						box-sizing: border-box;
						color: #F15764;
						font-size: 14px;
						line-height: 38px;
						float: right;
						border-radius: 4px;
						cursor: pointer;
					}
				}
			}
		}
	}
</style>
