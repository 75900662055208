<template>
    <div class="left">
        <div class="homeItem" :class="{ 'selectItem': selectItem == 1 ? true : false }" @click="itemBtn(1)">
            <div class="homeImg"></div>
            <p>首页</p>
        </div>
        <div class="subjectItem" :class="{ 'selectItem': selectItem == 2 ? true : false }" @click="itemBtn(2)">
            <div class="subjectImg"></div>
            <p>题库管理</p>
            <div class="subjectArrow"></div>
        </div>
        <div class="subjectBar" v-show="selectItem == 2 ? true : false">
            <div class="home" :class="{ selectBarItem: barItem == 5 ? true : false }" @click="barItemBtn(5)">
                <div class="radio"></div>
                <div class="itemText">首界面</div>
            </div>
            <div class="barSubject" :class="{ selectBarItem: barItem == 1 ? true : false }" @click="barItemBtn(1)">
                <div class="radio"></div>
                <div class="itemText">库管理</div>
            </div>
            <div class="barChirte" :class="{ selectBarItem: barItem == 2 ? true : false }" @click="barItemBtn(2)">
                <div class="radio"></div>
                <div class="itemText">章管理</div>
            </div>
            <div class="barSection" :class="{ selectBarItem: barItem == 3 ? true : false }" @click="barItemBtn(3)">
                <div class="radio"></div>
                <div class="itemText">节管理</div>
            </div>
            <div class="barQusetion" :class="{ selectBarItem: barItem == 4 ? true : false }" @click="barItemBtn(4)">
                <div class="radio"></div>
                <div class="itemText">题管理</div>
            </div>
            <div class="barQusetion" :class="{ selectBarItem: barItem == 6 ? true : false }" @click="barItemBtn(6)">
                <div class="radio"></div>
                <div class="itemText">错题反馈</div>
            </div>
        </div>

        
        <div class="orderAdmin" :class="{ 'selectItem': selectItem == 5? true : false }" @click="itemBtn(5)">
            <div class="orderImg"></div>
            <p>订单管理</p>
        </div>
        <div class="codeAdmin" :class="{ 'selectItem': selectItem == 6? true : false }" @click="itemBtn(6)">
            <div class="codeImg"></div>
            <p>激活码管理</p>
        </div>
        <div class="newsItem" :class="{ 'selectItem': selectItem == 3 ? true : false }" @click="itemBtn(3)">
            <div class="userImg"></div>
            <p>用户管理</p>
        </div>
        <div class="systemItem" :class="{ 'selectItem': selectItem == 4 ? true : false }" @click="itemBtn(4)">
            <div class="systemImg"></div>
            <p>系统管理</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectItem: 1,
            barItem: 5,
        };
    },
    methods: {
        itemBtn(val) {
            this.selectItem = val;
            if (val == 1) {
                this.$router.push({ path: "/home", query: { leftId: 1 } });
            }
            if (val == 2) {
                this.$router.push({ path: "/leftHome", query: { leftId: 2,barId:5 } });
            }
            if (val == 3) {
                this.$router.push({ path: "/userAdmin", query: { leftId: 3 } });
            }
            if (val == 4) {
                this.$router.push({ path: "/userDetalis", query: { leftId: 4 } });
            }
            if(val == 5){
                this.$router.push({ path: "/orderAdmin", query: { leftId: 5} });
            }
            if(val == 6){
                this.$router.push({ path: "/codeAdmin", query: { leftId: 6} });
            }
            
        },
        barItemBtn(val) {
            this.barItem = val;
            if (val == 1) {
                this.$router.push({
                    path: "/subjectAdmin",
                    query: { leftId: 2, barId: 1 },
                });
            }
            if (val == 2) {
                this.$router.push({
                    path: "/chapterAdmin",
                    query: { leftId: 2, barId: 2 },
                });
            }
            if (val == 3) {
                this.$router.push({
                    path: "/sectionAdmin",
                    query: { leftId: 2, barId: 3 },
                });
            }
            if (val == 4) {
                this.$router.push({
                    path: "/questionAdmin",
                    query: { leftId: 2, barId: 4 },
                });
            }
            if (val == 5) {
                this.$router.push({
                    path: "/leftHome",
                    query: { leftId: 2, barId: 5 },
                });
            }
            if (val == 6) {
                this.$router.push({
                    path: "/feendback",
                    query: { leftId: 2, barId: 6 },
                });
            }
            // this.selectItem = -1
        },
    },
    mounted() {
        if (this.$route.query.leftId == undefined) {
            this.selectItem = 1;
        } else {
            this.selectItem = this.$route.query.leftId;
        }
        if (this.$route.query.barId == undefined) {
            this.barItem = 1;
        } else {
            this.barItem = this.$route.query.barId;
        }
    },
};
</script>

<style lang='less' scoped>
.left {
    width: 195px;
    height: 100%;
    background-color: #fff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    padding-top: 20px;
    box-sizing: border-box;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 22;

    .homeItem,
    .subjectItem,
    .newsItem,
    .systemItem, .orderAdmin, .codeAdmin{
        width: 100%;
        height: 33px;
        margin-top: 10px;
        border-right: 4px solid transparent;
        box-sizing: border-box;
        cursor: pointer;

        .homeImg {
            width: 15px;
            height: 33px;
            float: left;
            margin-left: 33px;
            background-image: url("../assets/img/home/home.png");
            background-repeat: no-repeat;
            background-size: 100% 15px;
            background-position: center;
        }

        .subjectImg {
            width: 15px;
            height: 33px;
            float: left;
            margin-left: 33px;
            background-image: url("../assets/img/home/subject.png");
            background-repeat: no-repeat;
            background-size: 100% 15px;
            background-position: center;
        }

        .subjectArrow {
            width: 4.5px;
            height: 33px;
            background-image: url("../assets/img/home/leftArrow.png");
            background-repeat: no-repeat;
            background-size: 100% 9px;
            background-position: center;
            float: right;
            margin-right: 15px;
        }

        .userImg {
            width: 18px;
            height: 33px;
            float: left;
            margin-left: 31.5px;
            background-image: url("../assets/img/home/user.png");
            background-repeat: no-repeat;
            background-size: 100% 15px;
            background-position: center;
        }
        .orderImg{
            width: 13.5px;
            height: 33px;
            float: left;
            margin-left: 31.5px;
            background-image: url("../assets/img/home/order.png");
            background-repeat: no-repeat;
            background-size: 100% 15px;
            background-position: center;
        }
        .systemImg {
            width: 15px;
            height: 33px;
            float: left;
            margin-left: 33px;
            background-image: url("../assets/img/home/setUp.png");
            background-repeat: no-repeat;
            background-size: 100% 15px;
            background-position: center;
        }
        .codeImg{
            width: 13.5px;
            height: 33px;
            float: left;
            margin-left: 33px;
            background-image: url("../assets/img/home/code.png");
            background-repeat: no-repeat;
            background-size: 100% 15px;
            background-position: center;
        }

        p {
            font-size: 13.5px;
            height: 33px;
            color: #333;
            float: left;
            margin-left: 24px;
            line-height: 33px;
        }
    }

    .subjectBar {
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;

        .barSubject,
        .barChirte,
        .barSection,
        .barQusetion,
        .home, .barQusetion {
            width: 100%;
            height: 33px;
            border-right: 4px solid transparent;
            box-sizing: border-box;

            .radio {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                margin-top: 13.5px;
                float: left;
                margin-left: 72px;
                border: 1px solid #707070;
            }

            .itemText {
                font-size: 12px;
                line-height: 33px;
                float: left;
                margin-left: 22.5px;
                cursor: pointer;
            }
        }

        .selectBarItem {
            background-color: rgba(241, 82, 99, 0.1);
            border-right: 4px solid #f15764;

            .radio {
                background-color: #f15764;
                border: 1px solid #f15764;
            }

            .itemText {
                color: #f15764;
            }
        }
    }
    .selectItem {
        background-color: rgba(241, 82, 99, 0.1);
        border-right: 4px solid #f15764;

        .homeImg {
            background-image: url("../assets/img/home/selectHome.png");
        }

        .subjectImg {
            background-image: url("../assets/img/home/sleectSubject.png");
        }

        .subjectArrow {
            width: 14.81px;
            height: 100%;
            background-image: url("../assets/img/home/selectArrow.png");
            background-repeat: no-repeat;
            background-size: 100% 7.41px;
            background-position: center;
        }

        .userImg {
            background-image: url("../assets/img/home/selectUser.png");
        }
        .orderImg{
            background-image: url("../assets/img/home/orderSelect.png");
        }
        .systemImg {
            background-image: url("../assets/img/home/selectSetUp.png");
        }
        .codeImg{
              background-image: url("../assets/img/home/codeSelect.png");
        }

        p {
            color: #f15764;
        }
    }
}
</style>