<template>
  <div class="delete">
      <div class="deleteContent">
          <div class="tips">提示信息</div>
          <div class="tipsText">{{deleteContent}}</div>
          <div class="tipsBot">
              <div class="sure" @click="sureBtn">确定</div>
              <div class="cancel" @click="cancelBtn">取消</div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            
        }
    },
    props:{
        deleteContent:String,
        required:true,
    },
    methods:{
        sureBtn(){
            this.$emit('sureBtn')
        },
        cancelBtn(){
            this.$emit('cancelBtn')
        }
    }
}
</script>

<style lang='less' scoped>
    .delete{
        width: 100%;
        height: 100vh;
        background-color: rgba(51, 51, 51, .3);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999999;
        .deleteContent{
            width: 564px;
            height: 171px;
            border-radius: 4px;
            background-color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            .tips{
                font-size: 22px;
                color: #333;
                margin-top: 20px;
                text-align: left;
                text-indent: 24px;
            }
            .tipsText{
                font-size: 16px;
                color: #666;
                margin-top: 20px;
                text-align: left;
                text-indent: 24px;
            }
            .tipsBot{
                width: 188px;
                height: 36px;
                margin-top: 35px;
                float: right;
                margin-right: 34px;
                .sure{
                    width: 84px;
                    height: 100%;
                    background-color: #E80510;
                    font-size: 16px;
                    color: #fff;
                    line-height: 36px;
                    float: left;
                    border-radius: 4px;
                    cursor: pointer;
                }
                .cancel{
                    width: 84px;
                    height: 100%;
                    border:1px solid #E80510;
                    box-sizing: border-box;
                    font-size: 16px;
                    color: #E80510;
                    line-height: 34px;
                    float: right;
                    border-radius: 4px;
                    cursor: pointer;
                }
            }
        }
    }
</style>