<template>
    <div class="userAdmin">
        <top></top>
        <div class="content">
            <left></left>
            <div class="contentRight">
                <div class="contentCenter">
                    <div class="title">用户管理</div>
                    <div class="centerContent">
                        <div class="centerTitle">
                            <div class="search">
                                <input type="text" placeholder="请输入用户名" v-model="userName" @keyup.enter="searchBtn">
                                <div class="searchImg" @click="searchBtn"></div>
                            </div>
                            <!-- <div class="delete">批量删除</div> -->
                        </div>
                        <div class="listTitle">
                            <!-- <div class="titleChecked"></div> -->
                            <div class="titleId">ID</div>
                            <div class="titleNickName">微信昵称</div>
                            <div class="titleName">用户名</div>
                            <div class="titlePhone">手机号</div>
                            <div class="titleBuyStyle">购买状态</div>
                            <div class="titleOpenId">OPENID</div>
                            <div class="titleOperate">操作</div>
                        </div>
                        <div class="listItem" v-for="(item,index) in userList" :key="index" @click="itemBtn(item)" >
                            <!-- <div class="itemChecked" :class="{ selectImg: selectChecked }"  @click.stop="selectImgBtn">
                            </div> -->
                            <div class="itemId">{{index+1+(currentPage-1)*7}}</div>
                            <div class="itemUserImg">
                                <img :src="item.avatar==''?image:item.avatar" alt="">
                            </div>
                            <div class="itemNickName">{{item.nickname}}</div>
                            <div class="itemName">{{item.realname}}<span v-if="item.realname==null?true:false">暂无真实姓名</span></div>
                            <div class="itemPhone">{{item.phone}}<span v-if="item.phone==null?true:false">暂无手机号</span></div>
                            <div class="itemBuyStyle1" v-if="item.purchased">已购买</div>
                            <div class="itemBuyStyle2" v-if="!item.purchased">未购买</div>
                            <div class="itemOpenId">{{item.openid}}</div>
                            <!-- <div class="itemEdit"></div> -->
                            <div class="itemdelete" @click.stop="deleteItemBtn(item)"></div>
                        </div>
                        <pagebar :currentPage="currentPage" :total="total" :count="count" @jumpNumber="jumpNumber" @nextPage="nextPage"
                            @prePage="prePage" @pageTo="pageTo"></pagebar>
                        <div class="loadingPanel" :style="{'opacity':loadingOpacity}" v-show="loadingOpacity<=0?false:true">
                            <img src="../assets/img/home/onLoad.gif" />
                        </div>
                    </div>
                    <success-bar :tipsText="success" v-if="successShow"></success-bar>
                     <error-bar :tipsText="error" v-if="errorShow"></error-bar>
                </div>
            </div>
        </div>
        <!-- <delete-bar @cancelBtn="cancelBtn" @sureBtn="sureBtn" :deleteContent="deleteContent" v-if="deleteShow"></delete-bar> -->
        <div class="deleteUser" v-if="deleteShow">
            <div class="deleteContent">
                <div class="tips">提示信息</div>
                <div class="tipsText">如果删除，将会删除该用户的所有内容，您确定要删除吗？</div>
                <div class="deleteNumber" v-show="numberShow">
                    <p>计算结果为：</p>
                    <p>{{number1}} + {{number2}} = </p>
                    <input type="text" v-model="sumNumber" :class="{inputBoder:inputBoder}">
                </div>
                <div class="countdown" v-show="countdownShow">
                    <p>你还有&nbsp;&nbsp;{{countdownTime}}&nbsp;&nbsp;的考虑时间，确定删除吗</p>
                </div>
                <div class="tipsBot">
                    <div class="sure" @click="sureBtn" v-show="!numberShow&& !countdownShow">确定</div>
                    <div class="sure" @click="sureBtn2" v-show="numberShow && !countdownShow" >确定</div>
                    <div class="sure" @click="sureBtn3" v-show="countdownShow" :class="{bgColor:sumSelect}">确定</div>
                    <div class="cancel" @click="cancelBtn">取消</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import top from '../components/nav.vue'
import left from '../components/left.vue'
import pagebar from '../components/pagebarContainer.vue'
import successBar from '../components/success.vue'
import errorBar from '../components/error.vue'
import deleteBar from '../components/delete.vue'
export default {
    components: {
        top, left,
        pagebar,successBar,errorBar,deleteBar
    },
    data() {
        return {
            deleteContent:'您确定要删除吗',
            deleteShow:false,
            successShow:false,
            errorShow:false,
            success:'删除成功',
            error:'删除失败',
            selectChecked: false,
            currentPage: 1,
            total: 30,
            count:7,
            userName:'',
            userList:[],
            image:require('../assets/img/home/userImg.png'),
            userId:'',
            loadingOpacity:1,//透明度
            number1:0,
            number2:0,
            numberShow:false,
            sumNumber:'',
            sumSelect:false,
            inputBoder:false,
            suerShow:false,
            countdownTime:10,
            countdownShow:false,
        }
    },
    methods: {
        //删除用户
      deleteItemBtn(item){
          this.userId = item.id;
          this.deleteShow = true;
          console.log(this.userId);
          this.countdownShow = false;
      },
      //取消
      cancelDeleteBtn(){
          this.deleteShow = false;
      },
    //   确定
      sureBtn(){
        var num1 = Math.floor(Math.random()*(20-1)+1);
        this.number1 = num1;
        var num2 = Math.floor(Math.random()*(20-1)+1);
        this.number2 = num2;
        this.numberShow =true;
        this.suerShow = true;
      },
      sureBtn2(){
        if(this.sumNumber == '' || this.sumNumber/1 != this.number1 +  this.number2){
            this.inputBoder = true;
            return
        }else{
            if(this.sumNumber/1 == this.number1 +  this.number2){
                console.log('789456123')
                this.sumSelect = true;
                this.numberShow = false;
                this.suerShow == false;
                this.countdownShow = true;
                var timeNumber = 10;
                this.countdownTime = 10;
                var time = setInterval(()=>{
                    timeNumber --;
                    this.countdownTime = timeNumber;
                    if(timeNumber<=0){
                        clearInterval(time);
                        this.sumSelect = false;
                        // this.countdownShow = false;
                    }
                },1000)
            }
        }
      },
      sureBtn3(){
        console.log(123,'删除')
        this.deleteShow = false;
        var param = new FormData();
          param.append('user_id',this.userId);
          this.$.deleteUserUrl(param).then(res=>{
              console.log(res);
              if(res.code == 200){
                  var self = this;
                  this.successShow= true;
                  var time1= setTimeout(function(){
                    self.successShow = false;
                    clearTimeout(time1)
                },2000);
              }else{
                  var self = this;
                        this.errorShow = true;
                        var time2= setTimeout(function(){
                            self.erroeShow = false;
                            clearTimeout(time2)
                        },2000);
              }
          });
           this.deleteShow = false;
           this.userListBtn()
      },
        cancelBtn(){
            this.deleteShow = false;
      },
        selectImgBtn() {
            this.selectChecked = !this.selectChecked
        },
        itemBtn(item) {
            this.$router.push({ path: '/itemUserDetalis', query: { leftId: 3,userId:item.id 
                ,userName:item.nickname,userImg:item.avatar,phone:item.phone}})
        },
        nextPage() {
            this.currentPage++;
            if (this.currentPage >= this.pageSize) {
                this.currentPage = this.pageSize;

            }
            this.userListBtn()
        },
        jumpNumber(val){
            if(val==''){
            val = 1
        }
        if(val>=Math.ceil(this.total / this.count)){
              val = Math.ceil(this.total / this.count)
          }
          this.currentPage = val/1;
          this.subjectListBtn();
      },
        pageTo(index) {
            this.currentPage = index;
            this.userListBtn()
        },
        prePage() {
            this.currentPage--;
            if (this.currentPage <= 1) {
                this.currentPage = 1;
            }
           this.userListBtn()
        },
        //获取用户信息
        userListBtn(){
            var self = this;
            var param = new FormData();
            param.append('nickname',this.userName);
            param.append('page',this.currentPage);
            param.append('count',this.count);
            this.$.userList(param).then(res=>{
                self.loadingOpacity = 1,
                console.log(res,'用户信息')
                this.userList = res.userInfo.data;
                this.total = res.userInfo.total;
                var value = 100;
                var timer = setInterval(function() {
                    value -= 10;
                    self.loadingOpacity = value / 100;
                    if (value <= 0) {
                    clearInterval(timer);
                    }
                }, 10);
            })
        },
        //搜索
        searchBtn(){
            this.userListBtn();
        }
    },
    mounted(){
         //获取用户信息
        this.userListBtn();
        
    }
}
</script>

<style lang='less' scoped>
.userAdmin {
    width: 100%;
    height: 100vh;
    background-color: #f2f2f2;

    .content {
        width: 100%;
        // height: 100%;
        margin-top: 60px;
        display: flex;
        background-color: #f2f2f2;
        .contentRight {
            flex: 1;
            margin-left: 195px;
            // height: 100vh;
            position: relative;

            .contentCenter {
                width: 1570px;
                height: 100%;
                margin: auto;

                .title {
                    font-size: 20px;
                    color: #333;
                    margin-top: 30px;
                    text-align: left;
                }

                .centerContent {
                    width: 100%;
                    height: 902px;
                    background-color: #fff;
                    border-radius: 10px;
                    margin-top: 14px;
                    position: relative;

                    .centerTitle {
                        width: 1490px;
                        height: 107px;
                        border-bottom: 1px solid #ccc;
                        margin: auto;

                        .search {
                            width: 387px;
                            height: 44px;
                            margin-top: 28px;
                            // margin-left: 40px;
                            float: left;
                            position: relative;

                            input {
                                width: 100%;
                                height: 100%;
                                border: 1px solid #ccc;
                                border-radius: 4px;
                                box-sizing: border-box;
                                outline: none;
                                position: absolute;
                                top: 0;
                                left: 0;
                                font-size: 14px;
                                color: #333;
                                text-indent: 22px;
                            }

                            .searchImg {
                                width: 24px;
                                height: 100%;
                                background-image: url('../assets/img/home/search.png');
                                background-repeat: no-repeat;
                                background-size: 100% 24px;
                                background-position: center;
                                position: absolute;
                                right: 21px;
                                cursor: pointer;
                            }
                        }

                        .delete {
                            width: 122px;
                            height: 38px;
                            border: 1px solid #F15764;
                            box-sizing: border-box;
                            border-radius: 4px;
                            color: #F15764;
                            margin-top: 34px;
                            float: right;
                            cursor: pointer;
                            font-size: 14px;
                            line-height: 38px;
                        }
                    }

                    .listTitle {
                        width: 1490px;
                        height: 60px;
                        background-color: #f2f2f2;
                        margin: 36px auto 12px;

                        .titleChecked {
                            width: 16px;
                            height: 16px;
                            margin-top: 22px;
                            margin-left: 18px;
                            float: left;
                            border-radius: 2px;
                            border: 1px solid #707070;
                            box-sizing: border-box;
                        }

                        .titleId {
                            font-size: 14px;
                            color: #333;
                            float: left;
                            margin-left: 50px;
                            line-height: 60px;
                        }

                        .titleNickName {
                            font-size: 14px;
                            color: #333;
                            line-height: 60px;
                            float: left;
                            margin-left: 126px;
                        }

                        .titleName {
                            font-size: 14px;
                            color: #333;
                            line-height: 60px;
                            float: left;
                            margin-left: 200px;
                        }

                        .titlePhone {
                            font-size: 14px;
                            color: #333;
                            line-height: 60px;
                            float: left;
                            margin-left: 150px;
                        }

                        .titleBuyStyle {
                            font-size: 14px;
                            color: #333;
                            line-height: 60px;
                            float: left;
                            margin-left: 218px;
                        }

                        .titleOpenId {
                            font-size: 14px;
                            color: #333;
                            line-height: 60px;
                            float: left;
                            margin-left: 115px;
                        }

                        .titleOperate {
                            font-size: 14px;
                            color: #333;
                            line-height: 60px;
                            float: right;
                            margin-right: 113px;
                        }
                    }

                    .listItem {
                        width: 1490px;
                        height: 66px;
                        margin: auto;
                        margin-bottom: 22px;
                        margin-top: 12px;
                        cursor: pointer;

                        .itemChecked {
                            width: 16px;
                            height: 16px;
                            border: 1px solid #707070;
                            box-sizing: border-box;
                            border-radius: 2px;
                            margin-top: 25px;
                            margin-left: 18px;
                            float: left;
                            cursor: pointer;
                        }

                        .selectImg {
                            border: 0;
                            background-image: url('../assets/img/home/selectItem.png');
                            background-repeat: no-repeat;
                            background-size: 100% 100%;

                        }

                        .itemId {
                            width: 68px;
                            height: 100%;
                            float: left;
                            margin-left: 22px;
                            font-size: 14px;
                            color: 3333;
                            line-height: 66px;
                        }

                        .itemUserImg {
                            width: 44px;
                            height: 100%;
                            float: left;
                            margin-left: 93px;
                            position: relative;
                            img{
                                width: 100%;
                                height: 44px;
                                border-radius: 50%;
                                position: absolute;
                                top: 50%;
                                left: 0;
                                transform: translateY(-50%);
                            }
                        }

                        .itemNickName {
                            width: 120px;
                            height: 100%;
                            float: left;
                            margin-left: 30px;
                            font-size: 14px;
                            color: #333;
                            line-height: 66px;
                            text-align: left;
                        }

                        .itemName {
                            width: 120px;
                            height: 100%;
                            float: left;
                            margin-left: 34px;
                            font-size: 14px;
                            color: #333;
                            line-height: 66px;
                            text-align: left;
                            text-align: center;
                            span{
                                color: #999;
                            }
                        }

                        .itemPhone {
                            width: 90px;
                            height: 100%;
                            font-size: 14px;
                            color: #333;
                            line-height: 66px;
                            float: left;
                            margin-left: 89px;
                            span{
                                color: #999;
                            }
                        }

                        .itemBuyStyle1 {
                            width: 84px;
                            height: 30px;
                            margin-top: 18px;
                            border-radius: 15px;
                            background-color: #D8FAF5;
                            float: left;
                            margin-left: 177px;
                            font-size: 14px;
                            color: #33998F;
                            line-height: 30px;
                        }
                        .itemBuyStyle2 {
                            width: 84px;
                            height: 30px;
                            margin-top: 18px;
                            border-radius: 15px;
                            background-color: #FEEDEF;
                            float: left;
                            margin-left: 177px;
                            font-size: 14px;
                            color: #993333;
                            line-height: 30px;
                        }

                        .itemOpenId {
                            width: 150px;
                            height: 66px;
                            float: left;
                            margin-left: 100px;
                            line-height: 66px;
                            color: #333;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            font-size: 14px;
                            text-align: left;
                        }

                        .itemEdit {
                            width: 21px;
                            height: 100%;
                            float: left;
                            margin-left: 75px;
                            background-image: url('../assets/img/home/edit.png');
                            background-repeat: no-repeat;
                            background-size: 100% 21px;
                            background-position: center;
                            cursor: pointer;
                        }

                        .itemdelete {
                            width: 18px;
                            height: 100%;
                            background-image: url('../assets/img/home/delete.png');
                            background-size: 100% 20px;
                            background-repeat: no-repeat;
                            background-position: center;
                            float: right;
                            margin-right: 120px;
                            cursor: pointer;
                        }
                    }
                    .loadingPanel {
                        position        : absolute;
                        width           : 100%;
                        height          : 709px;
                        background-color: white;
                        z-index         : 999;
                        pointer-events  : none;
                        display         : flex;
                        flex-wrap       : nowrap;
                        flex-direction  : column;
                        justify-content : center;
                        align-items     : center;
                        z-index         : 999;
                        pointer-events  : auto;
                        left            : 0;
                        top: 108px;

                        img {
                            width: .6rem;

                        }
                    }
                }
            }
        }
    }
    .deleteUser{
        width: 100%;
        height: 100%;
        background-color: rgba(51, 51, 51, .3);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999999;
        .deleteContent{
            width: 564px;
            height: 171px;
            border-radius: 4px;
            background-color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            .tips{
                font-size: 22px;
                color: #333;
                margin-top: 20px;
                text-align: left;
                text-indent: 24px;
            }
            .tipsText{
                font-size: 16px;
                color: #ff0000;
                margin-top: 20px;
                text-align: left;
                text-indent: 24px;
            }
            .deleteNumber{
                width: 300px;
                height: 36px;
                margin-top: 35px;
                float: left;
                // background-color: #98f1e8;
                margin-left: 20px;
                position: relative;
                p{
                    font-size: 16px;
                    color: #333;
                    line-height: 36px;
                    float: left;
                }
                input{
                    width: 100px;
                    height: 90%;
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    transform: translateY(-50%);
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    outline: none;
                    box-sizing: border-box;
                    font-size: 16px;
                    color: #333;
                    text-indent: 16px;
                }
                .inputBoder{
                    border: 1px solid #ff0000;
                }
            }
            .countdown{
                width: 300px;
                height: 36px;
                margin-top: 35px;
                float: left;
                // background-color: #98f1e8;
                margin-left: 20px;
                p{
                    font-size: 16px;
                    color: #333;
                    line-height: 36px;
                    color: #333;
                    text-align: left;
                }
            }
            .tipsBot{
                width: 188px;
                height: 36px;
                margin-top: 35px;
                float: right;
                margin-right: 34px;
                .sure{
                    width: 84px;
                    height: 100%;
                    background-color: #E80510;
                    font-size: 16px;
                    color: #fff;
                    line-height: 36px;
                    float: left;
                    border-radius: 4px;
                    cursor: pointer;
                }
                .bgColor{
                    background-color: #ccc;
                    color: #333;
                    pointer-events: none;
                }
                .cancel{
                    width: 84px;
                    height: 100%;
                    border:1px solid #E80510;
                    box-sizing: border-box;
                    font-size: 16px;
                    color: #E80510;
                    line-height: 34px;
                    float: right;
                    border-radius: 4px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>