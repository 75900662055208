<template>
  <div class="itemUserSetalis">
      <top></top>
      <div class="content">
          <left></left>
          <div class="contentRight">
              <div class="rightCenter">
                  <div class="title">用户管理</div>
                  <div class="center">
                      <div class="cneterTitle">
                          <div class="titleOne">
                              <div class="titleImg">
                                  <img :src="userImg==''?image:userImg" alt="">
                              </div>
                              <div class="titleRight">
                                  <div class="nickName">{{userName}}</div>
                                  <div class="phone">
                                      手机号&nbsp; &nbsp; &nbsp; {{phone==''?'暂无手机号':phone}}
                                  </div>
                                  <div class="money">余额： <span>100.00元</span></div>
                              </div>
                          </div>
                          <div class="titleSelect"> 
                              <div class="buyItem" :class="{selectItem:select==1?true:false}" @click="selectBtn(1)">购买记录</div>
                              <div class="withdraw" :class="{selectItem:select==2?true:false}" @click="selectBtn(2)">提现记录</div>
                          </div>
                      </div>
                      <div class="buyBar" v-show="select==1?true:false">
                          <div class="listTitle">
                              <div class="titleName">题库名称</div>
                              <div class="titleMoney">金额</div>
                              <div class="titleTime">购买时间</div>
                              <div class="titlePaymentStyle">支付方式</div>
                              <div class="titleTime2">有效期</div>
                              <div class="titleTime3">截止日期</div>
                              <div class="titleOperation">操作</div>
                          </div>
                          <div class="listItem" v-for="(item,index) in orderList" :key="index">
                              <div class="itemName">{{item.goods_name}}</div>
                              <div class="itemMoney">{{item.order_money}}元</div>
                              <div class="itemTime" ><span v-if="item.pay_time">{{item.pay_time |formatDate }}</span></div>
                              <div class="itemPaymentStyle" v-show="item.pay_type==1?true:false">微信</div>
                              <div class="itemPaymentStyle" v-show="item.pay_type==2?true:false">激活码</div>
                              <!-- <div class="itemPaymentStyle" v-show="item.pay_type==3?true:false">激活码</div> -->
                              <div class="itemTime2"><span v-if="item.pay_time">{{item.pay_time |formatDate}}  {{item.deadline |formatDate}}</span></div>
                              <div class="itemTime3"><span v-if="item.deadline">{{item.deadline |formatDate}}</span></div>
                              <!-- <div class="itemEdit"></div> -->
                              <div class="itemDelete"></div>
                          </div>
                         <div class="loadingPanel" :style="{'opacity':loadingOpacity}" >
                            <img src="../assets/img/home/onLoad.gif" />
                        </div>
                         <pagebar :currentPage="currentPage" :total="total" :count="count" @nextPage="nextPage" @prePage="prePage" @pageTo="pageTo"></pagebar>
                      </div>
                      <div class="Withdrawal" v-show="select == 2?true:false">
                          <div class="listTitle2">
                              <div class="title2Time">申请时间</div>
                              <div class="title2Withdrawal">提现方向</div>
                              <div class="title2Number">收款账号</div>
                              <div class="title2Money">提现金额(元)</div>
                              <div class="title2Account">实际到账金额(元)</div>
                              <div class="title2Style">状态</div>
                              <div class="title2Remarks">备注</div>
                          </div>
                          <div class="listItem2">
                              <div class="item2Time">2022-04-16  18:47</div>
                              <div class="item2Direction">工商银行</div>
                              <div class="item2Number">16541684618684161</div>
                              <div class="item2Money">100</div>
                              <div class="item2Money2">100</div>
                              <div class="item2Style">已到账</div>
                              <div class="item2Edit"></div>
                          </div>
                          <pagebar :currentPage="currentPage2" :total="total2" :count="count2" @nextPage="nextPageTwo" @prePage="prePageTwo" @pageTo="pageToTwo"></pagebar>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import top from '../components/nav.vue'
import left from '../components/left.vue'
import pagebar from '../components/pagebarContainer.vue'
export default {
    components:{
        top,left,pagebar
    },
    data(){
        return{
            select:1,
            currentPage:1,
            total:30,
            count:5,
            currentPage2:1,
            total2:30,
            count2:5,
            orderList:[],
            userId:'',
            userName:'',
            userImg:'',
            phone:'',
            image:require('../assets/img/home/userImg.png'),
            loadingOpacity:1,//透明度
        }
    },
    filters: {
        formatDate(time) {
            var date = new Date(time);
            var y = date.getFullYear();
            var MM = date.getMonth() + 1;
                MM = MM < 10 ? "0" + MM : MM;
            var d = date.getDate();
                d = d < 10 ? "0" + d : d;
            var h = date.getHours();
                h = h < 10 ? "0" + h : h;
            var m = date.getMinutes();
                m = m < 10 ? "0" + m : m;
            var s = date.getSeconds();
            s = s < 10 ? "0" + s : s;
            return y + "-" + MM + "-" + d + " " + h + ":" + m;
        }
    },
    methods:{
        selectBtn(val){
            this.select = val;
        },
        nextPage() {
            this.currentPage++;
            if (this.currentPage >= this.pageSize) {
                this.currentPage = this.pageSize;
                
            }
           //获取单个用户信息
        this.userBtn()
        },
        pageTo(index) {
            this.currentPage = index;
            //获取单个用户信息
        this.userBtn()
        },
        prePage() {
            this.currentPage--;
            if (this.currentPage <= 1) {
                this.currentPage = 1;
            }
            //获取单个用户信息
        this.userBtn()
        },
        nextPageTwo() {
            this.currentPage++;
            if (this.currentPage >= this.pageSize) {
                this.currentPage = this.pageSize;
                
            }
            // this.chapterListBtn()
        },
        pageToTwo(index) {
            this.currentPage = index;
            // this.chapterListBtn()
        },
        prePageTwo() {
            this.currentPage--;
            if (this.currentPage <= 1) {
                this.currentPage = 1;
            }
            // this.chapterListBtn()
        },
        //获取用户信息
        userBtn(){
            var param = new FormData();
            var self = this;
            param.append('status',5);
            param.append('subject_id','');
            param.append('user_id',this.userId);
            param.append('page',this.currentPage);
            param.append('count',this.count);
            this.$.orderList(param).then(res=>{
                this.loadingOpacity = 1;
                console.log(res)
                this.total = res.ordersInfo.total;
                this.orderList = res.ordersInfo.data;
                var value = 100;
                var timer = setInterval(function() {
                    value -= 10;
                    self.loadingOpacity = value / 100;
                    if (value <= 0) {
                    clearInterval(timer);
                    }
                }, 10);
            })
        }
    },
    mounted(){
        console.log(this.$route.query.userId,'5645665465');
        this.userId = this.$route.query.userId
        //获取单个用户信息
        this.userBtn();
        this.userName = this.$route.query.userName;
        this.userImg = this.$route.query.userImg;
        this.phone = this.$route.query.phone;
        
    }
}
</script>

<style lang='less' scoped>
    .itemUserSetalis{
        width: 100%;
        height: 1080px;
        background-color: #f2f2f2;
        .content{
            width: 100%;
            height: 100vh;
            margin-top: 60px;
            display: flex;
            .contentRight{
                flex: 1;
                margin-left: 195px;
                height: 100%;
                .rightCenter{
                    width: 1570px;
                    height: 100%;
                    margin: auto;
                    .title{
                        font-size: 20px;
                        margin-top: 30px;
                        text-align: left;
                        color: #333;
                    }
                    .center{
                        width: 100%;
                        height: 902px;
                        background-color: #fff;
                        border-radius: 10px;
                        margin-top: 14px;
                        .cneterTitle{
                            width: 1490px;
                            height: 232px;
                            margin: auto;
                            border-bottom:1px solid rgba(241, 87, 100, .3);
                            overflow: hidden;
                            .titleOne{
                                margin-top: 70px;
                                width: 100%;
                                height: 100px;
                                .titleImg{
                                    width: 90px;
                                    height: 100%;
                                    float: left;
                                    position: relative;
                                    img{
                                        width: 90px;
                                        height: 90px;
                                        border-radius: 50%;
                                        position: absolute;
                                        top: 50%;
                                        left: 0;
                                        transform: translateY(-50%);
                                    }
                                }
                                .titleRight{
                                    width: 500px;
                                    height: 100%;
                                    float: left;
                                    margin-left: 20px;
                                    .nickName{
                                        font-size: 20px;
                                        color: #333;
                                        text-align: left;
                                        margin-top: 38px;
                                    }
                                    .phone{
                                        font-size: 14px;
                                        color: #333;
                                        margin-top: 21px;
                                        float: left;
                                    }
                                    .money{
                                        font-size: 14px;
                                        color: #333;
                                        float: left;
                                        margin-top: 14px;
                                        float: left;
                                        margin-left: 74px;
                                        span{
                                            color: #ff0000;
                                            font-size: 20px;
                                            
                                        }
                                    }
                                }
                            }
                            .titleSelect{
                                width: 100%;
                                height: 32px;
                                margin-top: 24px;
                                .buyItem, .withdraw{
                                    width: 71px;
                                    height: 36px;
                                    border-bottom: 4px solid transparent;
                                    font-size: 14px;
                                    color: #333;
                                    float: left;
                                    line-height: 32px;
                                    margin-right: 21px;
                                    cursor: pointer;
                                }
                                .selectItem{
                                    border-bottom: 4px solid #F15764;
                                }
                            }
                        }
                        .buyBar{
                            width: 100%;
                            height: 668px;
                            position: relative;
                            overflow: hidden;
                            .listTitle{
                                width: 1490px;
                                height: 60px;
                                background-color: #f2f2f2;
                                margin: 38px auto 0;
                                .titleName{
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 60px;
                                    float: left;
                                    margin-left: 117px;
                                }
                                .titleMoney{
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 60px;
                                    float: left;
                                    margin-left: 160px;
                                }
                                .titleTime{ 
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 60px;
                                    float: left;
                                    margin-left: 160px;
                                }
                                .titlePaymentStyle{
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 60px;
                                    float: left;
                                    margin-left: 171px;
                                }
                                .titleTime2{
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 60px;
                                    float: left;
                                    margin-left: 155px;
                                }
                                .titleTime3{
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 60px;
                                    float: left;
                                    margin-left: 170px;
                                }
                                .titleOperation{
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 60px;
                                    float: left;
                                    margin-left: 175px;
                                }
                            }
                            .listItem{
                                width: 1490px;
                                height: 60px;
                                margin:0 auto 26px;
                                .itemName{
                                    width: 72px;
                                    height: 60px;
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 60px;
                                    float: left;
                                    margin-left: 109px;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                }
                                .itemMoney{
                                    width: 76px;
                                    font-size: 14px;
                                    color: #ff0000;
                                    float: left;
                                    margin-left: 130px;
                                    line-height: 60px;
                                }
                                .itemTime{
                                    width: 156px;
                                    height: 60px;
                                    font-size: 14px;
                                    line-height: 60px;
                                    float: left;
                                    margin-left: 90px;
                                }
                                .itemPaymentStyle{
                                    width: 54px;
                                    height: 60px;
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 60px;
                                    float: left;
                                    margin-left: 116px;
                                }
                                .itemTime2{
                                    width: 156px;
                                    height: 100%;
                                    float: left;
                                    font-size: 14px;
                                    color: #333;
                                    margin-left: 108px;
                                    line-height: 30px;
                                }
                                .itemTime3{
                                    width: 156px;
                                    height: 100%;
                                    float: left;
                                    font-size: 14px;
                                    color: #333;
                                    margin-left: 65px;
                                    line-height: 60px;
                                }
                                // .itemEdit{
                                //     width: 21px;
                                //     height: 100%;
                                //     float: left;
                                //     margin-left: 96px;
                                //     background-image: url('../assets/img/home/edit.png');
                                //     background-repeat: no-repeat;
                                //     background-size: 100% 21px;
                                //     background-position: center;
                                //     cursor: pointer;
                                // }
                                .itemDelete{
                                    width: 18px;
                                    height: 100%;
                                    float: left;
                                    margin-left: 119px;
                                    background-image: url('../assets/img/home/delete.png');
                                    background-repeat: no-repeat;
                                    background-size: 100% 21px;
                                    background-position: center;
                                    cursor: pointer;
                                }
                            }
                            .loadingPanel {
                                position        : absolute;
                                width           : 100%;
                                height          : 490px;
                                background-color: white;
                                z-index         : 999;
                                pointer-events  : none;
                                display         : flex;
                                flex-wrap       : nowrap;
                                flex-direction  : column;
                                justify-content : center;
                                align-items     : center;
                                z-index         : 999;
                                pointer-events  : auto;
                                left            : 0;
                                top: 98px;

                                img {
                                    width: .6rem;

                                }
                            }
                        }
                        .Withdrawal{
                            width: 100%;
                            height: 668px;
                            position: relative;
                            overflow: hidden;
                            .listTitle2{
                                width: 1490px;
                                height: 60px;
                                background-color: #f2f2f2;
                                margin: 38px auto 0;
                                .title2Time{
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 60px;
                                    float: left;
                                    margin-left: 109px;
                                }
                                .title2Withdrawal{
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 60px;
                                    float: left;
                                    margin-left: 157px;
                                }
                                .title2Number{
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 60px;
                                    float: left;
                                    margin-left: 144px;
                                }
                                .title2Money{
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 60px;
                                    float: left;
                                    margin-left: 160px;
                                }
                                .title2Account{
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 60px;
                                    float: left;
                                    margin-left:117px;
                                }
                                .title2Style{
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 60px;
                                    float: left;
                                    margin-left: 140px;
                                }
                                .title2Remarks{
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 60px;
                                    float: left;
                                    margin-left: 177px;
                                }
                            }
                            .listItem2{
                                width: 1490px;
                                height: 60px;
                                margin: 0 auto 25px;
                                .item2Time{
                                    width: 156px;
                                    height: 60px;
                                    font-size: 14px;
                                    color: #333;
                                    float: left;
                                    margin-left: 67px;
                                    line-height: 60px;
                                }
                                .item2Direction{
                                    width: 72px;
                                    height: 60px;
                                    font-size: 14px;
                                    color: #333;
                                    float: left;
                                    margin-left: 90px;
                                    line-height: 60px;
                                }
                                .item2Number{
                                    width: 170px;
                                    font-size: 14px;
                                    color: #333;
                                    height: 60px;
                                    line-height: 60px;
                                    float: left;
                                    margin-left: 83px;
                                }
                                .item2Money{
                                    width: 30px;
                                    height: 60px;
                                    font-size: 14px;
                                    color: #333;
                                    float: left;
                                    margin-left: 121px;
                                    line-height: 60px;
                                }
                                .item2Money2{
                                    width: 30px;
                                    height: 60px;
                                    font-size: 14px;
                                    color: #333;
                                    float: left;
                                    margin-left: 183px;
                                    line-height: 60px;
                                }
                                .item2Style{
                                    width: 54px;
                                    height: 60px;
                                    font-size: 14px;
                                    color: #333;
                                    float: left;
                                    margin-left: 170px;
                                    line-height: 60px;
                                }
                                .item2Edit{
                                    width: 21px;
                                    height: 100%;
                                    background-image: url('../assets/img/home/delete.png');
                                    background-repeat: no-repeat;
                                    background-size: 100% 21px;
                                    background-position: center;
                                    float: left;
                                    margin-left: 166px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
</style>