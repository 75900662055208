<template>
  <div class="leftHome">
      <top-bar></top-bar>
      <div class="homeContent">
          <left-bar></left-bar>
          <div class="right">
              <div class="center">
                  <p>题库管理><span>首界面</span></p>
                  <div class="content">
                      <div class="one">
                          <div class="oneTitle">一级目录</div>
                          <div class="oneItem" :class="{selectOne:switchOne==index?true:false}"
                                     @click="selectOneBtn(item,index)" v-for="(item,index) in rootsList" :key="index">{{item.title}}
                            <div class="edit" v-if="switchOne==index?true:false" @click="oneEditBtn(item)"></div>
                            <div class="delete" v-if="switchOne==index?true:false" @click="oneDeleteBtn(item)"></div>
                          </div>
                          <div class="add" @click="addOneBtn">
                              <div class="addImg"></div>
                              <div class="addText">添加一级目录</div>
                          </div>
                      </div>
                      <div class="two">
                          <div class="twoTitle">二级目录</div>
                          <div class="twoItem" :class="{selectTwo:switchTwo==index?true:false}" v-for="(item,index) in setmList" :key="index"
                                @click="selectTwoBtn(item,index)">{{item.title}} 
                                <div class="edit" v-if="switchTwo==index?true:false" @click="twoEditBtn(item)"></div>
                                <div class="delete" v-if="switchTwo==index?true:false" @click="twoDeleteBtn(item)"></div>
                            </div>
                          <div class="add" @click="addTwoBtn">
                              <div class="addImg"></div>
                              <div class="addText">添加二级目录</div>
                          </div>
                      </div>
                      <div class="three">
                          <div class="threeTitle">三级目录</div>
                          <div class="threeItem" :class="{selectThree:switchThree==index?true:false}" v-for="(item,index) in leafList" :key="index"
                            @click="selectThreeBtn(item,index)">
                              {{item.title}}
                              <div class="edit" v-if="switchThree==index?true:false" @click="threeEditBtn(item)"></div>
                            <div class="delete" v-if="switchThree==index?true:false" @click="threeDeleteBtn(item)"></div>
                          </div>
                          <div class="add" @click="addThreeBtn">
                              <div class="addImg"></div>
                              <div class="addText">添加三级目录</div>
                          </div>
                      </div>
                      <div class="preview">
                          <div class="previewTitle">实时预览</div>
                          <div class="previewBox">
                              <div class="previewLeft">
                                  <div class="leftItem" :class="{selectLeftItem:stemIndex==index?true:false}" v-for="(item,index) in obtainLis" :key="index" @click="leftItemBtn(index,item)">{{item.title}}</div>
                              </div>
                              <div class="previewRight" v-if="obtainLis[stemIndex]">
                                  <div class="righttItem" v-for="(item,index) in obtainLis[stemIndex].stems" :key="index">
                                      <div class="itemTitle">
                                          <div class="line"></div>
                                          <div class="itemText">{{item.title}}</div>
                                          <div class="line"></div>
                                      </div>
                                      <div class="itemContent" v-for="(obj,index) in item.leafs" :key="index" v-show="item.id == obj.stem_id?true:false">{{obj.title}}</div>
                                      <!-- <div class="itemContent">法律法规与工程管理</div> -->
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="popBar" v-show="popBar">
      <!-- //添加  编辑-->
          <div class="addPopBar" v-show="addPopBar">
              <div class="addTitle">
                  <div class="titleText">{{titleText}}</div>
                  <div class="colse" @click="closeBtn"></div>
              </div>
              <div class="addName">
                  <div class="addNameText">目录名称:</div>
                  <input type="text" v-model="title">
              </div>
              <div class="addDescription">
                  <div class="descriptionText">描述:</div>
                  <textarea name="" id="" cols="30" rows="10" v-model="description"></textarea>
              </div>
              <div class="addBtn">
                  <div class="add_sure" @click="sureBtn">确定</div>
                  <div class="cancel" @click="closeBtn">取消</div>
              </div>
          </div>
          <!-- 必须选择上一级 -->
          <div class="addTips" v-show="addTips">
              <div class="itpsTitle">
                  <div class="tipsText">提示信息</div>
              </div>
              <div class="tipsContent">{{tips}}</div>
              <div class="tipsBtn" @click="closeBtn">确定</div>
          </div>
      </div>
      <div class="deletePopBar" v-show="deletePopBar">
          <div class="deleteContent">
            <div class="itpsTitle">
                  <div class="tipsText">提示信息</div>
            </div>
            <div class="tipsContent">确定要删除该目录吗？</div>
            <div class="deleteBtn">
                <div class="deleteSure" @click="deleteSureBtn">确定</div>
                <div class="deleteCancel" @click="deleteCancelBtn">取消</div>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import topBar from '../components/nav.vue'
import leftBar from '../components/left.vue'
export default {
    components:{
        topBar,leftBar
    },
    data(){
        return{
            switchOne:-1,
            switchTwo:-1,
            switchThree:-1,
            titleText:'新增一级目录',
            popBar:false,
            addPopBar:false,
            tips:'请您选择一级目录才可添加其它目录',
            addTips:false,
            rootsList:[],
            title:'',
            description:"",
            rootsId:'',
            deletePopBar:false,
            setmList:[],
            stemId:'',
            deleteStyle:1,
            leafList:[],
            leafId:'',
            // leftItem:-1,
            obtainLis:[],
            stemIndex:0,
        }
    },
    methods:{
        //获取科目列表
        obtainListBtn(){
            this.$.obtainList().then(res=>{
                this.obtainLis = res.roots;
				this.obtainLis.sort((a,b)=>{
					return a.sort-b.sort;
				});
                console.log(this.obtainLis,'56456')
            })
        },
        //左侧选择
        leftItemBtn(index){
            this.stemIndex = index;
            
        },
        selectOneBtn(item,index){
            console.log(item.id);
            this.switchOne = index;
            this.rootsId = item.id;
            this.switchTwo = -1;
            //获取二级列表
            var param = new FormData();
            param.append('roots_id',item.id)
            this.$.stemList(param).then(res=>{
                console.log(res,'二级节点')
                this.setmList=res.stemInfo;
                console.log(this.setmList,'564')
            })
        },
        selectTwoBtn(item,index){
            this.switchTwo = index;
            this.stemId = item.id;
            this.switchThree = -1;
            var param = new FormData();
            param.append('stem_id',this.stemId);
            this.$.leafList(param).then(res=>{
                this.leafList = res.leafInfo;
                console.log(this.leafList,'三级节点')
            })
        },
        selectThreeBtn(item,index){
            this.switchThree = index;
            this.leafId = item.id;
        },
        closeBtn(){
            this.popBar = false;
        },
        addOneBtn(){
            this.title = '';
            this.description = '';
            this.popBar = true;
            this.addPopBar = true;
            this.addTips = false;
            this.titleText = '新增一级目录'
        },
        addTwoBtn(){
            this.title = '';
            this.description = '';
            if(this.switchOne==-1){
                this.popBar = true;
                this.addPopBar = false;
                this.addTips = true;
            }else{
                this.popBar = true;
                this.addPopBar = true;
                this.addTips = false;
                this.titleText = '新增二级目录';
                this.title = '';
                this.description = '';
            }
        },
        addThreeBtn(){
            this.title = '';
            this.description = '';
            if(this.switchTwo==-1){
                this.popBar = true;
                this.addTips = true;
                this.addPopBar = false;
                this.tips='请您选择二级目录才可添加其它目录'
            }else{
                this.popBar = true;
                this.addPopBar = true;
                this.addTips = false;
                this.titleText = '新增三级目录';
                this.title = '';
                this.description = '';
            }
        },
        sureBtn(){
            if(this.titleText=='新增一级目录'){
                // 接口   误删
                var param = new FormData();
                param.append('title',this.title)
                this.$.addRoots(param).then(res=>{
                    console.log(res,'=====addRoots')
                    this.rootsListBtn();
                })
                    

                console.log(123456)
                this.obtainListBtn();
            }
            if(this.titleText=='新增二级目录'){
                console.log(654321)
                var param = new FormData();
                param.append('roots_id',this.rootsId);
                param.append('title',this.title);
                param.append('description',this.description);
                this.$.adddStem(param).then(res=>{
                    console.log(res,'添加二级节点')
                    //刷新
                    var param = new FormData();
                    param.append('roots_id',this.rootsId)
                    this.$.stemList(param).then(res=>{
                        console.log(res,'二级节点')
                        this.setmList=res.stemInfo;
                        console.log(this.setmList,'564')
                        this.obtainListBtn();
                    })
                })
            }
            if(this.titleText=='新增三级目录'){
                console.log(963258)
                var param  = new FormData();
                param.append('roots_id',this.rootsId);
                param.append('stem_id',this.stemId);
                param.append('title',this.title);
                param.append('description',this.description);
                this.$.addLeaf(param).then(res=>{
                    console.log(res)
                    //刷新
                    var param = new FormData();
                    param.append('stem_id',this.stemId);
                    this.$.leafList(param).then(res=>{
                        this.leafList = res.leafInfo;
                        console.log(this.leafList,'三级节点')
                        this.obtainListBtn();
                    })
                })
            }
            if(this.titleText=='编辑一级目录'){
                console.log('编辑一级目录')
                // 接口   误删


                var param = new FormData();
                param.append('id',this.rootsId);
                param.append('title',this.title);
                param.append('description',this.description);
                this.$.editRoots(param).then(res=>{
                    console.log('编辑')
                    this.rootsListBtn();
                    this.obtainListBtn();
                })
            }
            if(this.titleText == '编辑二级目录'){
                console.log('编辑2级目录')

                var param =  new FormData();
                param.append('roots_id',this.rootsId)
                param.append('id',this.stemId);
                param.append('title',this.title);
                param.append('description',this.description);
                this.$.editStem(param).then(res=>{
                    console.log(res,'编辑二级节点')
                    //刷新
                    var param = new FormData();
                    param.append('roots_id',this.rootsId)
                    this.$.stemList(param).then(res=>{
                        console.log(res,'二级节点')
                        this.setmList=res.stemInfo;
                        console.log(this.setmList,'564')
                        this.obtainListBtn();
                    })
                })
            }
            if(this.titleText == '编辑三级目录'){
                var param = new FormData();
                param.append('id',this.leafId);
                param.append('roots_id',this.rootsId);
                param.append('stem_id',this.stemId);
                param.append('title',this.title);
                param.append('description',this.description);
                this.$.editLeaf(param).then(res=>{
                    console.log(res);
                    //刷新
                    var param = new FormData();
                    param.append('stem_id',this.stemId);
                    this.$.leafList(param).then(res=>{
                        this.leafList = res.leafInfo;
                        console.log(this.leafList,'三级节点')
                        this.obtainListBtn();
                    })
                })
            }
            this.popBar = false;
        },
        //获取跟一级列表
        rootsListBtn(){
            this.$.rootsList().then(res=>{
                this.rootsList = res.rootsInfo;
			    this.rootsList.sort((a,b)=>{
					return a.sort-b.sort;
				})
                console.log(this.rootsList,'=============roots')
            })
        },
        //编辑
        oneEditBtn(item){
            this.rootsId = item.id;
            this.title = item.title;
            this.description = item.description;
            this.popBar = true;
            this.addPopBar = true;
            this.addTips = false;
            this.titleText= '编辑一级目录'
        },
        twoEditBtn(item){
            this.stemId = item.id;
            this.title = item.title;
            this.description = item.description;
            this.popBar = true;
            this.addPopBar = true;
            this.addTips = false;
            this.titleText= '编辑二级目录';
        },
        threeEditBtn(item){
            this.leafId = item.id;
            this.title = item.title;
            this.description = item.description;
            this.popBar = true;
            this.addPopBar = true;
            this.addTips = false;
            this.titleText= '编辑三级目录';
        },
        //删除
        deleteCancelBtn(){
            this.deletePopBar = false;
        },
        oneDeleteBtn(item){
            this.rootsId = item.id;
            this.deletePopBar = true;
            this.deleteStyle=1;
        },
        deleteSureBtn(){
            if(this.deleteStyle==1){
                var param = new FormData();
                param.append('id',this.rootsId);
                this.$.deleteRoots(param).then(res=>{
                    console.log(res,'删除')
                    this.rootsListBtn();
                })
                console.log('删除roots')
                this.obtainListBtn();
                //刷新二级目录
                this.setmList=[];
                //刷新三极目录
                this.leafList = [];
            }
            if(this.deleteStyle==2){
                console.log('删除stem');
                var param = new FormData();
                param.append('id',this.stemId);
                param.append('roots_id',this.rootsId);
                this.$.deleteStem(param).then(res=>{
                    console.log(res)
                })
                //刷新二级
                var param = new FormData();
                param.append('roots_id',this.rootsId)
                this.$.stemList(param).then(res=>{
                    console.log(res,'二级节点')
                    this.setmList=res.stemInfo;
                    console.log(this.setmList,'564')
                    this.obtainListBtn()
                })
                //刷新三极目录
                this.leafList =[];
            }
            if(this.deleteStyle == 3){
                var param = new FormData();
                param.append('roots_id',this.rootsId);
                param.append('stem_id',this.stemId);
                param.append('id',this.leafId);
                this.$.deleteLeaf(param).then(res=>{
                    console.log(res);
                     //刷新
                    var param = new FormData();
                    param.append('stem_id',this.stemId);
                    this.$.leafList(param).then(res=>{
                        this.leafList = res.leafInfo;
                        console.log(this.leafList,'三级节点')
                    })
                })
                this.obtainListBtn()
            }
            this.deletePopBar = false;
        },

        twoDeleteBtn(item){
            this.stemId = item.id;
            this.deletePopBar = true;
            this.deleteStyle = 2;
        },
        threeDeleteBtn(item){
            this.leafId = item.id;
            this.deletePopBar = true;
            this.deleteStyle = 3;
        }

    },
    mounted(){
        //获取根节点列表
        this.rootsListBtn();
        //获取科目列表
        this.obtainListBtn()
    }
}
</script>

<style lang="less" scoped>
    .leftHome{
        width: 100%;
        background-color: #f2f2f2;
        overflow: hidden;
        .homeContent{
            width: 100%;
            height: 100vh;
            margin-top: 60px;
            background-color: #f2f2f2;
            display: flex;
            .right{
                flex: 1;
                margin-left: 195px;
                height: 1000px;
                .center{
                    width: 1570px;
                    height: 100%;
                    margin: auto;
                    p{
                        font-size: 20px;
                        color: #ccc;
                        text-align: left;
                        margin-top: 24px;
                        margin-bottom: 20px;
                        span{
                            color: #333;
                        }
                    }
                    .content{
                        width: 100%;
                        height: 902px;
                        background-color: #fff;
                        border-radius: 10px;
                        position: relative;
                        overflow: hidden;
                        .one{
                            width: 330px;
                            // height: 122px;
                            border: 1px solid #ccc;
                            margin-top: 57px;
                            margin-left: 46px;
                            float: left;
                            .oneTitle{
                                width: 100%;
                                height: 54px;
                                border-bottom: 1px solid #ccc;
                                line-height: 54px;
                                font-size: 18px;
                                font-weight: 600;
                                text-align: left;
                                text-indent: 20px;
                                
                            }
                            .oneItem{
                                width: 100%;
                                height: 34px;
                                font-size: 16px;
                                color: #333;
                                line-height: 34px;
                                cursor: pointer;
                                border-bottom: 1px solid #ccc;
                                position: relative;
                                .edit{
                                    width: 14px;
                                    height: 100%;
                                    background-image: url('../assets/img/home/homeEdit.png');
                                    background-repeat: no-repeat;
                                    background-size: 100% .14rem;
                                    background-position: center;
                                    position: absolute;
                                    top: 0;
                                    right: 45px;
                                }
                                .delete{
                                    width: 14px;
                                    height: 100%;
                                    background-image: url('../assets/img/home/homeDelete.png');
                                    background-repeat: no-repeat;
                                    background-size: 100% .14rem;
                                    background-position: center;
                                    position: absolute;
                                    top: 0;
                                    right: 15px;
                                }
                            }
                            .selectOne{
                                background-color: #F15764;
                                color: #fff;
                            }
                            .add{
                                width: 100%;
                                height: 34px;
                                cursor: pointer;
                                .addImg{
                                    width: 16px;
                                    height: 100%;
                                    background-image: url('../assets/img/home/add.png');
                                    background-size: 16px 16px;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    float: left;
                                    margin-left: 99px;
                                }
                                .addText{
                                    font-size: 16px;
                                    color: #333;
                                    float: left;
                                    margin-left: 30px;
                                    line-height: 34px;
                                }
                            }
                        }
                        .two{
                            width: 330px;
                            // height: 122px;
                            border: 1px solid #ccc;
                            margin-top: 57px;
                            margin-left: 26px;
                            float: left;
							max-height: 600px;
							overflow-y: auto;
                            .twoTitle{
                                width: 100%;
                                height: 54px;
                                border-bottom: 1px solid #ccc;
                                line-height: 54px;
                                font-size: 18px;
                                font-weight: 600;
                                text-align: left;
                                text-indent: 20px; 
                            }
                            .twoItem{
                                width: 100%;
                                height: 34px;
                                font-size: 16px;
                                color: #333;
                                line-height: 34px;
                                cursor: pointer;
                                border-bottom: 1px solid #ccc;
                                position: relative;
                                .edit{
                                    width: 14px;
                                    height: 100%;
                                    background-image: url('../assets/img/home/homeEdit.png');
                                    background-repeat: no-repeat;
                                    background-size: 100% .14rem;
                                    background-position: center;
                                    position: absolute;
                                    top: 0;
                                    right: 45px;
                                }
                                .delete{
                                    width: 14px;
                                    height: 100%;
                                    background-image: url('../assets/img/home/homeDelete.png');
                                    background-repeat: no-repeat;
                                    background-size: 100% .14rem;
                                    background-position: center;
                                    position: absolute;
                                    top: 0;
                                    right: 15px;
                                }
                            }
                            .selectTwo{
                                background-color: #F15764;
                                color: #fff;
                            }
                            .add{
                                width: 100%;
                                height: 34px;
                                cursor: pointer;
                                .addImg{
                                    width: 16px;
                                    height: 100%;
                                    background-image: url('../assets/img/home/add.png');
                                    background-size: 16px 16px;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    float: left;
                                    margin-left: 99px;
                                }
                                .addText{
                                    font-size: 16px;
                                    color: #333;
                                    float: left;
                                    margin-left: 30px;
                                    line-height: 34px;
                                }
                            }
                        }
                        .three{
                            width: 330px;
                            height: 500px;
							overflow-y: auto;
                            border: 1px solid #ccc;
                            margin-top: 57px;
                            margin-left: 26px;
                            float: left;
                            .threeTitle{
                                width: 100%;
                                height: 54px;
                                border-bottom: 1px solid #ccc;
                                line-height: 54px;
                                font-size: 18px;
                                font-weight: 600;
                                text-align: left;
                                text-indent: 20px;
                                
                            }
                            .threeItem{
                                width: 100%;
                                height: 34px;
                                font-size: 16px;
                                color: #333;
                                line-height: 34px;
                                cursor: pointer;
                                border-bottom: 1px solid #ccc;
                                position: relative;
								
                                .edit{
                                    width: 14px;
                                    height: 100%;
                                    background-image: url('../assets/img/home/homeEdit.png');
                                    background-repeat: no-repeat;
                                    background-size: 100% .14rem;
                                    background-position: center;
                                    position: absolute;
                                    top: 0;
                                    right: 45px;
                                }
                                .delete{
                                    width: 14px;
                                    height: 100%;
                                    background-image: url('../assets/img/home/homeDelete.png');
                                    background-repeat: no-repeat;
                                    background-size: 100% .14rem;
                                    background-position: center;
                                    position: absolute;
                                    top: 0;
                                    right: 15px;
                                }
                            }
                            .selectThree{
                                background-color: #F15764;
                                color: #fff;
                            }
                            .add{
                                width: 100%;
                                height: 34px;
                                cursor: pointer;
                                .addImg{
                                    width: 16px;
                                    height: 100%;
                                    background-image: url('../assets/img/home/add.png');
                                    background-size: 16px 16px;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    float: left;
                                    margin-left: 99px;
                                }
                                .addText{
                                    font-size: 16px;
                                    color: #333;
                                    float: left;
                                    margin-left: 30px;
                                    line-height: 34px;
                                }
                            }
                        }
                        .preview{
                            width: 330px;
                            height: 570px;
                            float: left;
                            margin-left: 28px;
                            margin-top: 26px;
                            .previewTitle{
                                font-size: 18px;
                                color: #333;
                                text-align: left;
                            }
                            .previewBox{
                                width: 100%;
                                height: 541px;
                                border: 1px solid #ccc;
                                margin-top: 10px;
                                .previewLeft{
                                    width: 80px;
                                    height: 100%;
                                    float: left;
                                    border-right: 1px solid #ccc;
                                    box-sizing: border-box;
                                    .leftItem{
                                        width: 100%;
                                        height: 30px;
                                        font-size: 16px;
                                        color:#333;
                                        line-height: 30px;
                                        margin-top: 5px;
                                        cursor: pointer;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        white-space: nowrap;
                                    }
                                    .selectLeftItem{
                                        color: #F15764;
                                    }
                                }
                                .previewRight{
                                    width: 250px;
                                    height: 100%;
                                    float: right;
                                    overflow: auto;
                                    .righttItem{
                                        width: 100%;
                                        overflow: hidden;
                                        .itemTitle{
                                            width: 220px;
                                            height: 30px;
                                            margin: auto;
                                            position: relative;
                                            display: flex;
                                            margin-top: 10px;
                                            .line{
                                                width: 40px;
                                                height: 1px;
                                                background-color: #ccc;
                                                float: left;
                                                margin-top: 15px;
                                            }
                                            .itemText{
                                                flex: 1;
                                                height: 100%;
                                                float: left;
                                                font-size: 16px;
                                                color: #333;
                                                line-height: 30px;
                                            }
                                        }
                                        .itemContent{
                                            width: 110px;
                                            height: 50px;
                                            background-color: #f2f2f2;
                                            float: left;
                                            border-radius: 4px;
                                            font-size: 12px;
                                            color: #333;
                                            line-height: 50px;
                                            margin-left: 10px;
                                            text-overflow: ellipsis;
                                            overflow: hidden;
                                            white-space: nowrap;
                                            margin-top: 10px;
                                        }
                                    }
                                }
                                ::-webkit-scrollbar{
                                    width: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .popBar{
            width: 100%;
            height: 100%;
            background-color: rgba(51, 51, 51, .3);
            position: fixed;
            top: 0;
            left: 0;
            z-index: 9999;
            .addPopBar{
                width: 710px;
                height: 380px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                background-color: #fff;
                border-radius: 2px;
                .addTitle{
                    width: 100%;
                    height: 62px;
                    border-bottom: 1px solid #ccc;
                    .titleText{
                        font-size: 22px;
                        color: #333;
                        float: left;
                        margin-left: 30px;
                        line-height: 62px;
                    }
                    .colse{
                        width: 12px;
                        height: 100%;
                        float: right;
                        margin-right: 19px;
                        background-image: url('../assets/img/home/close.png');
                        background-size: 12px 12px;
                        background-repeat: no-repeat;
                        background-position: center;
                        cursor: pointer;
                    }
                }
                .addName{
                    width: 100%;
                    height: 34px;
                    margin-top: 39px;
                    position: relative;
                    .addNameText{
                        font-size: 18px;
                        color: #333;
                        float: left;
                        margin-left: 30px;
                        line-height: 34px;
                    }
                    input{
                        width: 468px;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 121px;
                        border: 1px solid #ccc;
                        outline: none;
                        font-size: 16px;
                        color: #333;
                        text-indent: 16px;
                    }
                }
                .addDescription{
                    width: 100%;
                    height: 111px;
                    margin-top: .3rem;
                    position: relative;
                    .descriptionText{
                        font-size: 18px;
                        color: #333;
                        float: left;
                        margin-left: 66px;
                        // line-height: 34px;
                        margin-top: 8px;
                    }
                    textarea{
                        width: 468px;
                        height: 111px;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        box-sizing: border-box;
                        padding: 15px;
                        outline: none;
                        resize: none;
                        position: absolute;
                        top: 0;
                        left: 121px;
                        font-size: 16px;
                    }
                }
                .addBtn{
                    width: 242px;
                    height: 36px;
                    margin: 58px auto;
                    .add_sure{
                        width: 86px;
                        height: 100%;
                        float: left;
                        font-size: 20px;
                        line-height: 36px;
                        color: #fff;
                        background-color: #F15764;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                    .cancel{
                        width: 86px;
                        height: 100%;
                        float: right;
                        font-size: 20px;
                        line-height: 34px;
                        color: #F15764;
                        border:1px solid #F15764;
                        box-sizing: border-box;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                }
            }
            .addTips{
                width: 564px;
                height: 168px;
                background-color: #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                .itpsTitle{
                    width: 100%;
                    height: 42px;
                    border-bottom: 1px solid #ccc;
                    .tipsText{
                        font-size: 22px;
                        color: #333;
                        float: left;
                        margin-left: 24px;
                        line-height: 42px;
                    }
                }
                .tipsContent{
                    font-size: 16px;
                    color: #666;
                    text-align: left;
                    text-indent: 24px;
                    margin-top: 28px;
                }
                .tipsBtn{
                    width: 84px;
                    height: 36px;
                    background-color: #E80510;
                    color: #fff;
                    font-size: 16px;
                    line-height: 36px;
                    margin-top: 35px;
                    float: right;
                    margin-right: 34px;
                    cursor: pointer;
                    border-radius: 4px;
                }
            }
        }
        .deletePopBar{
            width: 100%;
            height: 100vh;
            background-color: rgba(51, 51, 51, .3);
            position: fixed;
            top: 0;
            left: 0;
            z-index: 9999;
            .deleteContent{
                width: 564px;
                height: 180px;
                background-color: #fff;
                border-radius: 4px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                .itpsTitle{
                    width: 100%;
                    height: 42px;
                    border-bottom: 1px solid #ccc;
                    .tipsText{
                        font-size: 22px;
                        color: #333;
                        float: left;
                        margin-left: 24px;
                        line-height: 42px;
                    }
                }
                .tipsContent{
                    font-size: 16px;
                    color: #666;
                    text-align: left;
                    text-indent: 24px;
                    margin-top: 28px;
                }
                .deleteBtn{
                    width: 200px;
                    height: 36px;
                    float: right;
                    margin-right: 34px;
                    margin-top: 35px;
                    .deleteSure{
                        width: 84px;
                        height: 100%;
                        background-color: #F15764;
                        font-size: 16px;
                        color: #fff;
                        line-height: 36px;
                        border-radius: 4px;
                        float: left;
                        cursor: pointer;
                    }
                    .deleteCancel{
                        width: 84px;
                        height: 100%;
                        border:1px solid #F15764;
                        font-size: 16px;
                        color: #F15764;
                        box-sizing: border-box;
                        line-height: 34px;
                        border-radius: 4px;
                        float: right;
                        cursor: pointer;
                    }
                }
            }
        }
    }
</style>