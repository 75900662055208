<template>
  <div class="nav">
      <div class="navName">后台题库管理</div>
      <div class="navRihght" @click="navBtn">
          <div class="tips"></div>
          <div class="portrait"></div>
          <div class="userName">管理员</div>
          <div class="arrow"></div>
          <div class="navBar" v-show="navBar">
              <div class="setUp" @click="setUpBtn">设置</div>
              <div class="quit" @click="quitBtn">退出</div>
              
          </div>
      </div>
  </div>
</template>

<script>
export default {
	mounted() {
			 
			 setTimeout(function(){
				 var width=document.body.clientWidth;
				 var width=window.screen.width;
				  console.log(width,"width");
				 var ratio= width/1920;
				 
			 	 document.querySelector('body').setAttribute('style', 'zoom:'+ratio)
			 },100);
		
	 },
    data(){
        return{
            navBar:false,
        }
    },
    methods:{
        navBtn(){
            this.navBar = !this.navBar
        },
        quitBtn(){
            this.$router.push({path:'/'})
        },
        setUpBtn(){
            this.$router.push({path:'/userDetalis',query:{leftId:4}})
        }
    },
}
</script>

<style lang='less' scoped>
    .nav{
        width: 100%;
        height: .6rem;
        background-color: #fff;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);
        position: fixed;
        top: 0;
        z-index: 22;
        .navName{
            font-size: .15rem;
            color: #333;
            font-weight: 600;
            line-height: .6rem;
            float: left;
            margin-left: .38rem;
        }
        .navRihght{
            width: 1.65rem;
            height: 100%;
            float: right;
            margin-right: .465rem;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            position: relative;
            cursor: pointer;
            .tips{
                width: .15rem;
                height: 100%;
                background-image: url('../assets/img/home/tips.png');
                background-repeat: no-repeat;
                background-size: 100% .1875rem;
                background-position: center;
            }
            .portrait{
                width: .345em;
                height: 100%;
                background-image: url('../assets/img/home/portrait.png');
                background-size: 100% .345rem;
                background-repeat: no-repeat;
                background-position: center;
            }
            .userName{
                width: .6rem;
                height: 100%;
                font-size: .12rem;
                color: #333;
                line-height: .5775rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .arrow{
                width: .1125rem;
                height: 100%;
                background-image: url('../assets/img/home/arrow.png');
                background-size: 100% .06rem;
                background-repeat: no-repeat;
                background-position: center;
            }
            .navBar{
                width: 88.5px;
                height: 54px;
                background-color: #fff;
                position: absolute;
                top: 50px;
                right: 0px;
                box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
                .quit, .setUp{
                    width: 100%;
                    // height: 36px;
                    font-size: 12px;
                    line-height: 27px;
                    color: #333;
                    cursor: pointer;
                }
            }
        }
    }
</style>