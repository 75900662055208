<template>
  <div class="paddingBox">
    <div class="paging">
      <div class="pagingText firstPage" :class="{ 'disable': currentPage == 1 ? true : false }" @click="pageTo(1)">首页</div>
      <!-- 上一页 -->
      <div class="pagingText prePage" :class="{ 'disable': currentPage == 1 ? true : false }" @click="prePage">&lt;</div>
      <div class="pagingText" v-for="(item, index) in pageArr" :key="index"
        :class="{ 'active': (currentPage == (item)) ? true : false }" @click="pageTo(item)">{{ item }}</div>
      <!-- 下一页 -->
      <div class="pagingText nextPage" :class="{ 'disable': currentPage == pageSize ? true : false }" @click="nextPage"> &gt;
      </div>
      <div class="pagingText lastPage" :class="{ 'disable': currentPage == pageSize ? true : false }" @click="pageTo(pageSize)">
        尾页</div>

        <div class="allPage">共{{allPage}}页</div>
        <div class="jump">
          <p>跳转到：</p>
          <div class="unmInput">
            <input type="text" v-model="jumpNum">
          </div>
          <div class="jumpInput" @click="jumpInputBtn" :class="{noJump:nojump}">确定</div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // currentPage: 2,
      // total: 155,
      // count: 11
    allPage:'',
    jumpNum:'',
    nojump:false,
    }
  },
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    count: {
      type: Number,
      required: true
    }
  },
  methods: {
    pageTo(index) {
      this.$emit("pageTo", index);

    },
    nextPage() {
      this.$emit("nextPage");
    },
    prePage() {
      this.$emit("prePage");
    },
    jumpInputBtn(){
      this.$emit('jumpNumber',this.jumpNum)
    }
  },
  mouted: function () {
    // console.log(this.pageSize,'======================+++_--------------')
  },
  computed: {
    pageSize: function () {
      var pageSize = 0;
      pageSize = Math.ceil(this.total / this.count); //向上取整
      console.log(pageSize,'741258963')
      this.allPage = pageSize;
      if(pageSize<=1){
        this.nojump = true;
      }else{
        this.nojump = false;
      }
      return pageSize >= 1 ? pageSize : 1;
      
    },
    pageArr: function () {
      var pageArr = [];
      //不足5页
      if (this.pageSize <= 5) {
        pageArr.length = 0;
        pageArr = [];
        for (var i = 1; i <= this.pageSize; i++) {
          pageArr.push(i);
        }
      } else {
        //超出5页
        if (this.currentPage <= this.pageSize - 2 && this.currentPage >= 3) {
          pageArr.length = 0;
          pageArr = [];
          for (var i = this.currentPage - 2; i <= this.currentPage + 2; i++) {
            pageArr.push(i);
          }
        }
        if (this.currentPage <= 2) {
          pageArr.length = 0;
          pageArr = [];
          for (var i = 1; i <= 5; i++) {
            pageArr.push(i);
          }
        }
        if (this.currentPage > this.pageSize - 2) {
          pageArr.length = 0;
          pageArr = [];
          for (var i = this.pageSize - 4; i <= this.pageSize; i++) {
            pageArr.push(i);
          }
        }
      }
      return pageArr;
    }

  }
}
</script>

<style scoped="scoped" lang="less">
.paddingBox {
  width: 15.7rem;
  height: .32rem;
  float: right;
  position: absolute;
  bottom: 0.4rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;

  .paging {
    // width: 4.4rem;
    padding-left: .01rem;
    padding-right: .01rem;
    height: .32rem;
    margin: auto;

    .pagingText {
      height: .32rem;
      font-size: .12rem;
      width: 0.34rem;
      float: left;
      line-height: .32rem;
      background-color: #fff;
      border-radius: .04rem;
      margin-right: .15rem;
      cursor: pointer;
      border: 1px solid transparent;
    }
    .allPage{
      height: 100%;
      float: left;
      padding-left: .1rem;
      padding-right: .1rem;
      font-size: .12rem;
      line-height: .32rem;
      margin-left: .3rem;
    }
    .jump{
      width: 2rem;
      height: 100%;
      margin-left: .2rem;
      float: left;
      position: relative;
      p{
          float: left;
          font-size: .12rem;
          color: #333;
          text-align: left;
          float: left;
          line-height: .32rem;
        }
      .unmInput{
        width: .6rem;
        height: 100%;
        float: left;
        // margin-left: .1rem;
        position: relative;
        
        input{
          width: 100%;
          height: 100%;
          border: 1px  solid #999;
          border-radius: 4px;
          box-sizing: border-box;
          outline: none;
          position: absolute;
          top: 0;
          left: 0;
          text-indent: .12rem;
          font-size: .12rem;
        }
      }
      .jumpInput{
        width: .5rem;
        height: 100%;
        float: left;
        margin-left: .1rem;
        background-color: #f15764;
        font-size: .12rem;
        color: #fff;
        line-height: .32rem;
        border-radius: 4px;
        cursor: pointer;
      }
      .noJump{
        background-color: #ececec;
        pointer-events: none;
      }
    }
    .pagingText:hover {
      border: 1px solid orange;
      color: #aaaaaa;
    }

    .pagingText:last-child {
      margin: 0;
    }

    .disable {
      cursor: default;
      color: #aaaaaa;
      pointer-events: none;
    }

    .disable:hover {
      border: 1px solid transparent;
    }

    .active {
      color: #fc7f03;
    }
  }
}
</style>
