<template>
  <div class="addChapter">
      <top-bar></top-bar>
      <div class="content">
          <left-bar></left-bar>
          <div class="contentRight">
              <div class="contentCenter">
                <div class="rightTitle"><span>题库管理 ></span> 修改章</div>
                <div class="center">
                    <div class="subjectName">
                        <div class="name">库名称</div>
                        <input type="text" disabled="disabled" v-model="subjectTitle">
                    </div>
                    <div class="chrapeName">
                        <div class="name">章名称</div>
                        <input type="text" v-model="title" :class="{noChapterTitle:notitle}">
                    </div>
                    <div class="chrapeDetalis">
                        <div class="detalis">章描述</div>
                            <input type="text" v-model="description">
                    </div>
                    <div class="chrapeStyle">
                        <div class="style">章类型</div>
                        <div class="practise" @click="typeBtn(1)">
                            <div class="practiseRadio" :class="{selectStyle:type==1?true:false}">
                                <div class="instal"></div>
                            </div>
                            <div class="practiseText">题目练习</div>
                        </div>
                        <div class="exam" @click="typeBtn(2)">
                            <div class="examRadio" :class="{selectStyle:type==2?true:false}">
                                <div class="instal"></div>
                            </div>
                            <div class="examText">真题考试</div>
                        </div>
                        <div class="simulate" @click="typeBtn(3)">
                            <div class="simulatRadio" :class="{selectStyle:type==3?true:false}">
                                <div class="instal"></div>
                            </div>
                            <div class="simulateText">模拟考试</div>
                        </div>
                    </div>
                    <div class="freeBox">
                        <div class="chapterPay">节价格</div>
                        <div class="free"  @click="isFreeBtn(1)">
                            <div class="freeRadio" :class="{selectPay:is_free==1?true:false}">
                                <div class="instal"></div>
                            </div>
                            <div class="freeText">免费</div>
                        </div>
                        <div class="pay"  @click="isFreeBtn(2)">
                            <div class="payRadio" :class="{selectPay:is_free==2?true:false}">
                                <div class="instal"></div>
                            </div>
                            <div class="payText">付费</div>
                        </div>
                    </div>
                    <div class="addChapterBtn">
                        <div class="sure"  @click="sureBtn">确定</div>
                        <div class="cancel" @click="cancelBtn">取消</div>
                    </div>
                </div>
              </div>
              <success-bar :tipsText="success" v-if="successShow"></success-bar>
              <error-bar :tipsText="error" v-if="erroeShow"></error-bar>
          </div>
      </div>
  </div>
</template>

<script>
import topBar from '../components/nav.vue'
import leftBar from '../components/left.vue'
import successBar from '../components/success.vue'
import errorBar from '../components/error.vue'
export default {
    components:{
        topBar,leftBar,successBar,errorBar
    },
    data(){
        return{
            successShow:false,
            erroeShow:false,
            success:'编辑成功',
            error:'编辑失败',
            title:'',//章名称
            description:'',//章描述
            type:1,//章类型
            is_free:2,//是否免费
            subjectId:'',
            chapterId:'',
            notitle:false,
            subjectTitle:'',//库名称
        }
    },
    methods:{
        isFreeBtn(val){
            this.is_free = val;
        },
        sureBtn(){
            if(this.title != ''){
                this.notitle = false;
                var param = new FormData();
                param.append('id',this.chapterId)
                param.append('subject_id',this.subjectId);
                param.append('type',this.type);
                param.append('title',this.title);
                param.append('description',this.description);
                param.append('is_free',this.is_free);
                this.$.editchapter(param).then(res=>{
                    console.log(res,'456789')
                    if(res.code==200 && res.msg == 'ok'){
                    var self = this;
                        this.successShow= true;
                        var time1= setTimeout(function(){
                            self.successShow = false;
                            clearTimeout(time1)
                                self.$router.push({path:'/chapterAdmin',query:{leftId:2,barId:2}})
                        },2000);
                    }else{
                        var self = this
                        this.errorShow = true;
                        var time2= setTimeout(function(){
                            self.errorShow = false;
                            clearTimeout(time2)
                                self.$router.push({path:'/chapterAdmin',query:{leftId:2,barId:2}})
                        },2000);
                    }
                })
            }else{
                this.notitle = true;
                var self = this
                        this.errorShow = true;
                        var time2= setTimeout(function(){
                            self.errorShow = false;
                            clearTimeout(time2)
                                self.$router.push({path:'/chapterAdmin',query:{leftId:2,barId:2}})
                        },2000);
            }
            
        },
        cancelBtn(){
            this.$$router.push({path:'/chapterAdmin',query:{leftId:2,barId:2}})
        },
        //章类型选择
        typeBtn(val){
            this.type = val
        }
    },
    mounted(){
        console.log(this.$route.query.subjectId,this.$route.query.chapterId,'4556789');
        this.subjectId = this.$route.query.subjectId;
        this.chapterId = this.$route.query.chapterId;
        //获取章
        var param = new FormData();
        param.append('id',this.chapterId)
        this.$.chapterData(param).then(res=>{
            console.log(res,'章信息')
            this.title = res.chapterInfo.title;
            this.description = res.chapterInfo.description;
            this.type = res.chapterInfo.type;
            this.is_free = res.chapterInfo.is_free;
        });
        this.subjectTitle = localStorage.getItem('subjectTitle');
    }
    

}
</script>

<style lang='less' scoped>
    .addChapter{
        width: 100%;
        height: 1080px;
        .content{
            width: 100%;
            height: 100vh;
            margin-top: 60px;
            background-color: #F2F2F2;
            display: flex;
            .contentRight{
                flex: 1;
                margin-left: 195px;
                height: 1000px;
                .contentCenter{
                    width: 1570px;
                    height: 100%;
                    margin: auto;
                    .rightTitle{
                        font-size: 20px;
                        color: #333;
                        margin-top: 24px;
                        text-align: left;
                        span{
                            color: #ccc;
                        }
                    }
                    .center{
                        width: 1570px;
                        height: 902px;
                        margin-top: 20px;
                        background-color: #fff;
                        border-radius: 10px;
                        overflow: hidden;
                        .chrapeName,.subjectName{
                            width: 100%;
                            height: 44px;
                            margin-top: 31px;
                            position: relative;
                            overflow: hidden;
                            .name{
                                width: 120px;
                                height: 21px;
                                border-left: 2px solid #F15764;
                                box-sizing: border-box;
                                font-size: 16px;
                                color: #333;
                                //    margin-left: 48px;
                                position: absolute;
                                top: 50%;
                                left: 48px;
                                transform: translateY(-50%);
                            }
                            input{
                                width: 769px;
                                height:100%;
                                border: 1px solid #ccc;
                                box-sizing: border-box;
                                border-radius: 4px;
                                background-color: #f9f9f9;
                                position: absolute;
                                left: 175px;
                                top: 0;
                                outline: none;
                                font-size: 16px;
                                color: #333;
                                text-indent: 16px;
                            }
                            .noChapterTitle{
                                border: 1px solid #ff0000;
                            }
                        }
                        .chrapeDetalis{
                            width: 100%;
                            height: 44px;
                            margin-top: 32px;
                            position: relative;
                            .detalis{
                                font-size: 16px;
                                color: #333;
                                float: left;
                                margin-left: 80px;
                                line-height: 44px;
                            }
                            input{
                                width: 645px;
                                height:100%;
                                border: 1px solid #ccc;
                                box-sizing: border-box;
                                border-radius: 4px;
                                background-color: #f9f9f9;
                                position: absolute;
                                left: 175px;
                                top: 0;
                                outline: none;
                                font-size: 16px;
                                text-indent: 16px;
                            }
                        }
                        .chrapeStyle{
                            width: 100%;
                            height: 21px;
                            margin-top: 44px;
                            position: relative;
                            .style{
                                width: 124px;
                                height: 100%;
                                border-left: 2px solid #F15764;
                                margin-left: 48px;
                                font-size: 16px;
                                line-height: 21px;
                                color: #333;
                                float: left;
                            }
                            .practise{
                                width: 100px;
                                height: 100%;
                                float: left;
                                margin-left: 2px;
                                position: relative;
                                cursor: pointer;
                                .practiseRadio{
                                    width: 14px;
                                    height: 14px;
                                    border-radius: 50%;
                                    border: 1px solid #ccc;
                                    float: left;
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    
                                    .instal{
                                        width: .07rem;
                                        height: .07rem;
                                        background-color: #fff;
                                        border-radius: 50%;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%,-50%);
                                    }
                                }
                                .selectStyle{
                                    background-color: #F15764;
                                    border: 1px solid #F15764;
                                }
                                .practiseText{
                                    font-size: 16px;
                                    color: #333;
                                    float: right;
                                    line-height: .21rem;
                                }
                            }
                            .exam, .simulate{
                                width: 100px;
                                height: 100%;
                                float: left;
                                margin-left:34px;
                                position: relative;
                                cursor: pointer;
                                .examRadio, .simulatRadio{
                                    width: 14px;
                                    height: 14px;
                                    border-radius: 50%;
                                    border: 1px solid #ccc;
                                    float: left;
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    
                                    .instal{
                                        width: .07rem;
                                        height: .07rem;
                                        background-color: #fff;
                                        border-radius: 50%;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%,-50%);
                                    }
                                }
                                .selectStyle{
                                    background-color: #F15764;
                                    border: 1px solid #F15764;
                                }
                                .examText, .simulateText{
                                    font-size: 16px;
                                    color: #333;
                                    float: right;
                                    line-height: .21rem;
                                }
                            }
                        }
                       .freeBox{
                            width: 100%;
                            height: 21px;
                            margin-top: 41px;
                            .chapterPay{
                                width: 124px;
                                height: 21px;
                                border-left: 2px solid #F15764;
                                margin-left: 48px;
                                font-size: 16px;
                                line-height: 21px;
                                color: #333;
                                float: left;
                            }
                            .free{
                                width: 62px;
                                height: 20px;
                                // margin-top: 22px;
                                // margin-left: 72px;
                                position: relative;
                                cursor: pointer;
                                float: left;
                                .freeRadio{
                                    width: 14px;
                                    height: 14px;
                                    border-radius: 50%;
                                    border: 1px solid #ccc;
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    .instal{
                                        width: .07rem;
                                        height: .07rem;
                                        background-color: #fff;
                                        border-radius: 50%;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%,-50%);
                                    }
                                }
                                .selectPay{
                                    background-color: #F15764;
                                    border: 1px solid #F15764;
                                }
                                .freeText{
                                    font-size: 16px;
                                    color: #333;
                                    float: right;
                                    line-height: .21rem;
                                }
                            }
                            .pay{
                                width: 62px;
                                height: 21px;
                                margin-left: 22px;
                                position: relative;
                                cursor: pointer;
                                float: left;
                                .payRadio{
                                    width: .14rem;
                                    height: .14rem;
                                    border-radius: 50%;
                                    border: 1px solid #ccc;
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    .instal{
                                        width: .07rem;
                                        height: .07rem;
                                        background-color: #fff;
                                        border-radius: 50%;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%,-50%);
                                    }
                                }
                                .selectPay{
                                    background-color: #F15764;
                                    border: 1px solid #F15764;
                                }
                                .payText{
                                    font-size: 16px;
                                    color: #333;
                                    float: right;
                                    line-height: 21px;
                                }

                            }
                        }
                            .fictitiousNum{
                                width: 270px;
                                height: 44px;
                                margin-top: 37px;
                                margin-left: 48px;
                                position: relative;
                                .fictitiousText{
                                    width: 106px;
                                    height: 21px;
                                    float: left;
                                    border-left: 2px solid #F15764;
                                    box-sizing: border-box;
                                    font-size: 16px;
                                    line-height: 21px;
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                                input{
                                    width: 142px;
                                    height: 100%;
                                    border: 1px solid #ccc;
                                    box-sizing: border-box;
                                    position: absolute;
                                    right: 0;
                                    background-color: #f9f9f9;
                                    border-radius: 4px;
                                    outline: none;
                                    font-size: 16px;
                                    color: #333;
                                    text-indent: 16px;
                                }
                            }
                            .addChapterBtn{
                                width: 412px;
                                height: 38px;
                                margin: 305px auto 0;
                                display: flex;
                                flex-wrap: nowrap;
                                flex-direction: row;
                                justify-content: space-between;
                                .sure{
                                    width: 153px;
                                    height: 100%;
                                    background-color: #F15764;
                                    color: #fff;
                                    line-height: 38px;
                                    font-size: 14px;
                                    border-radius: 4px;
                                    cursor: pointer;
                                }
                                .cancel{
                                    width: 153px;
                                    height: 100%;
                                    background-color: #fff;
                                    border: 1px solid #F15764;
                                    border-radius: 4px;
                                    box-sizing: border-box;
                                    color: #F15764;
                                    line-height: 38px;
                                    font-size: 14px;
                                    cursor: pointer;
                                }
                            }
                    }
                }
               
            }
        }
    }
</style>