<template>
    <div class="editsubject">
        <top></top>
        <input type="file" ref="files" @change="selectedFile"
            style="position:absolute;width:0;height: 0;display: inline-block;" />
        <div class="editContent">
            <left></left>
            <div class="contentRight">
                <div class="center">
                    <p><span @click="returnBtn">库管理></span>修改库</p>
                    <div class="centerContent">
                        <div class="centerTop">
                            <div class="contentLeft">
                                <div class="subjectName">
                                    <div class="nameLin"></div>
                                    <div class="name">题库名称</div>
                                    <input type="text" v-model="title" :class="{noSubjectTitle:notitle}">
                                </div>
                                <div class="subjectSetalis">
                                    <div class="detalis">题库描述</div>
                                    <input type="text" v-model="description" :class="{noDescription:noDescription}">
                                </div>
                                <div class="subjectCover">
                                    <div class="cover">题库封面</div>
                                    <div class="imgBox">
                                        <img :src="imgBoxSrc" />
                                    </div>
                                    <div class="modify" @click="selectFile">修改</div>
                                    <span>支持JPG、PNG等格式的图片，图片大小不超过10M</span>
                                </div>
                            </div>
                            <div class="right">
                                <div class="subjectPrice">
                                    <div class="line"></div>
                                    <p>题库价格</p>
                                </div>
                                <div class="free" @click="styleBtn(1)">
                                    <div class="freeRadio" :class="{ selectStyle: selectStyle == 1 ? true : false }">
                                        <div class="instal"></div>
                                    </div>
                                    <div class="freeText">免费</div>
                                </div>
                                <div class="pay" @click="styleBtn(2)">
                                    <div class="payRadio" :class="{ selectStyle: selectStyle == 2 ? true : false }">
                                        <div class="instal"></div>
                                    </div>
                                    <div class="payText">付费</div>
                                    <div class="price">
                                        <input type="text" v-model="price">
                                    </div>
                                    <div class="fictitious">划线价格</div>
                                    <div class="price">
                                        <input type="text" v-model="fake_price">
                                    </div>
                                </div>
                                <div class="fictitiousNum">
                                    <div class="line"></div>
                                    <div class="fictitiousNumText">虚拟销量</div>
                                    <div class="fictitiousInput">
                                        <input type="text" v-model="fake_sales">
                                    </div>
                                </div>
                                <div class="is_sell">
                                    <div class="line"></div>
                                    <div class="isSellText">是否上架</div>
                                    <div class="sellSwitch" :class="{ styleSell: sellColor }" @click="sellBtn">
                                        <div class="switchInstal"></div>
                                    </div>
                                </div>
                                <div class="threeListTitle">
                                    <div class="line"></div>
                                    <div class="threeText">绑定三级目录</div>
                                </div>
                                <div class="three_list">
                                    <div class="three_item" v-for="(item,index) in leafList" :key="index"
                                        :class="{selectLeaf:leaf_id==item.id?true:false}" @click="leafItemBtn(item,index)">
                                        {{item.title}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="centerSelect">
                            <div class="selectContent">
                                <div class="selectTop">
                                    <div class="topName">名称</div>
                                    <div class="topPay">金额(元)</div>
                                    <div class="topTime">有效期(日)</div>
                                    <div class="topOperate">操作</div>
                                </div>
                                <div class="selectBot">
                                    <div class="botContent">
                                        <div class="selectItem" v-for="(item, index) in optionsList" :key="index">
                                            <div class="Selectname">{{ item.title }}</div>
                                            <div class="Selectpay">{{ item.price }}</div>
                                            <div class="selectTime">{{ item.period }}</div>
                                            <div class="selectEdit" @click="editBtn(item)"></div>
                                            <div class="selectDelete" @click="deletePay(item)"></div>
                                        </div>
                                    </div>
                                    <div class="addSelectItem" @click="addSelectItemBtn">+</div>
                                </div>
                            </div>
                            <div class="selectPop" v-show="selectPop">
                                <div class="popTitle">
                                    <div class="titleName">名称</div>
                                    <div class="titlePay">金额(元)</div>
                                    <div class="titleTime">有效期(日)</div>
                                </div>
                                <div class="popContent">
                                    <div class="popItem">
                                        <div class="itemName">
                                            <input type="text" v-model="popName" :placeholder="place_name">
                                        </div>
                                        <div class="timePay">
                                            <input type="text" v-model="popPay" :placeholder="place_pay">
                                        </div>
                                        <div class="itemTime">
                                            <input type="text" v-model="popTime" :placeholder="place_time">
                                        </div>
                                    </div>
                                    <div class="popBtn">
                                        <div class="sure" @click="popSureBtn">确定</div>
                                        <div class="cancel" @click="popCancelBtn">取消</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="contentBtn">
                            <div class="contentSure" @click="sureBtn">确定</div>
                            <div class="contentCancel" @click="cancelBtn">取消</div>
                        </div>
                    </div>
                </div>
                <success :tipsText="success" v-if="successShow"></success>
                <error :tipsText="error" v-if="errorShow"></error>
            </div>
        </div>
    </div>
</template>

<script>
import top from '../components/nav.vue'
import left from '../components/left.vue'
import success from '../components/success.vue'
import error from '../components/error.vue'
import Config from '../assets/js/img'
export default {
    components: {
        top, left, success, error
    },
    data() {
        return {
            leafStyle:-1,//三级目录选中样式
            successShow: false,
            errorShow: false,
            success: '编辑成功',
            error: '编辑失败',
            popName: '',
            popPay: '',
            popTime: '',
            place_name: '名称',
            place_pay: '价格',
            place_time: '天数',
            selectPop: false,
            title: '',//题库名称
            description: '',//题库描述,
            imgBoxSrc: '',//题库缩略图展示
            fillImg: '',//缩略图
            leaf_id: '',//叶子id
            price: '',//价格
            fake_price: '',//划线价格
            is_sell: 1,//是否上架
            selectStyle: 2,//是否免费
            sales: '',//真实销量
            fake_sales: '',//虚拟销量
            id: '',//库id
            CDN: Config.CDN,
            optionsList: [],
            sellColor: true,
            subjectPayId: '',//库价格id
            payStyle: 1,//1为修改 2为添加
            leafList:[],
            notitle:false,
            noDescription:false,
        }

    },
    methods: {
        //是否付费
        styleBtn(val) {
            this.selectStyle = val;
        },
        //绑定三级目录
        leafItemBtn(item,index){
            this.leafStyle = index;
            this.leaf_id = item.id;
            console.log(this.leaf_id,'456123231')
        },
        //是否上架
        sellBtn() {
            this.sellColor = !this.sellColor;
            if (this.sellColor == true) {
                this.is_sell = 1
            } else {
                this.is_sell = 2
            }
            console.log(this.is_sell, '789789')
        },
        returnBtn() {
            this.$router.push({ path: '/subjectAdmin', query: { leftId: 2 } })
        },
        addSelectItemBtn() {
            this.selectPop = true;
            this.payStyle = 2;
            this.popName = '';
            this.popPay = '';
            this.popTime = '';

        },
        popSureBtn() {
            this.selectPop = false;
            if (this.payStyle == 1) {
                //编辑
                console.log(123456);
                var param = new FormData();
                param.append('id', this.subjectPayId);
                param.append('subject_id', this.id);
                param.append('title', this.popName);
                param.append('price', this.popPay);
                param.append('period', this.popTime);
                this.$.editSubjectPay(param).then(res => {
                    console.log(res, '编辑库价格')
                })
                //获取库信息
                this.subjectDataBtn();
            }
            if (this.payStyle == 2) {
                //添加
                var param = new FormData();
                param.append('subject_id', this.id);
                param.append('title', this.popName);
                param.append('price', this.popPay);
                param.append('period', this.popTime);
                this.$.addSubjectPay(param).then(res => {
                    console.log(res, '添加库价格')
                })
                //获取库信息
                this.subjectDataBtn();
            }
        },
        popCancelBtn() {
            this.selectPop = false;
        },
        editBtn(item) {
            this.selectPop = true;
            this.subjectPayId = item.id;
            this.popName = item.title;
            this.popPay = item.price;
            this.popTime = item.period;
            this.payStyle = 1;
        },
        //删除价格
        deletePay(item) {
            var param = new FormData();
            param.append('id', item.id);
            this.$.deleteSubjectPay(param).then(res => {
                console.log(res, '删除价格')
            })
            //获取库信息
            this.subjectDataBtn();
        },
        //上传图片
        selectFile() {
            this.$refs.files.click();
        },
        selectedFile(e) {
            var self = this;
            this.fillImg = e.target.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function (e) {
            self.imgBoxSrc = e.target.result;

            };
        },
        //确定修改
        sureBtn() {
            if(this.title != '' && this.description !=''){
                var param = new FormData();
                param.append('id', this.id);
                param.append('title', this.title);
                param.append('thumb', this.fillImg);
                param.append('description', this.description);
                param.append('leaf_id', this.leaf_id);
                param.append('price', this.price);
                param.append('fake_price', this.fake_price);
                param.append('is_sell', this.is_sell);
                param.append('is_free', this.selectStyle);
                param.append('sales', this.sales);
                param.append('fake_sales', this.fake_sales);
                this.$.editSubject(param).then(res => {
                    console.log(res, '-------------修改库')
                    var self = this;
                    if (res.code == 200 && res.msg == 'ok') {
                        this.successShow = true;
                        var time1 = setTimeout(function () {
                            self.successShow = false;
                            clearTimeout(time1)
                            self.$router.push({ path: '/subjectAdmin', query: { leftId: 2 } })
                        }, 2000);
                    } else {
                        var self = this
                        this.errorShow = true;
                        var time2 = setTimeout(function () {
                            self.errorShow = false;
                            clearTimeout(time2)
                            self.$router.push({ path: '/subjectAdmin', query: { leftId: 2 } })
                        }, 2000);
                    }
                })
            }else{
                if(this.title == ''){
                    this.notitle = true
                }else{
                    this.notitle = false
                }
                if(this.description == ''){
                    this.noDescription = true
                }else{
                    this.noDescription = false
                }
            }
            
        },
        //取消修改
        cancelBtn() {
            this.$router.push({ path: '/subjectAdmin', query: { leftId: 2 } })
        },
        //获取库信息
        subjectDataBtn() {
            var param = new FormData();
            param.append('subject_id', this.id)
            this.$.sunjectData(param).then(res => {
                console.log(res, '-----------================')
                this.title = res.subjectInfo.title;
                this.description = res.subjectInfo.description;
                this.imgBoxSrc = this.CDN + res.subjectInfo.thumb;
                this.selectStyle = res.subjectInfo.is_free;
                this.price = res.subjectInfo.price;
                this.fake_price = res.subjectInfo.fake_price;
                this.fake_sales = res.subjectInfo.fake_sales;
                this.optionsList = res.subjectInfo.options;
                this.is_sell = res.subjectInfo.is_sell;
                if(this.is_sell == 1){
                    this.sellColor = true;
                }else{
                    this.sellColor = false;
                }
                this.leaf_id = res.subjectInfo.leaf_id;
                console.log(this.leaf_id,'789456')
                console.log(this.optionsList, '456')
            })
            
        }

    },
    mounted() {
        this.id = this.$route.query.subjectId;
        //获取库信息
        this.subjectDataBtn();
        //获取三级目录
        this.$.leafList().then(res=>{
            console.log(res,'三级目录')
            this.leafList = res.leafInfo
        })
    }

}
</script>

<style lang='less' scoped>
.editsubject {
    width: 100%;
    height: 100vh;
    background-color: #f2f2f2;

    // overflow: hidden;
    .editContent {
        width: 100%;
        height: 100vh;
        margin-top: 60px;
        background-color: #F2F2F2;
        display: flex;

        .contentRight {
            flex: 1;
            margin-left: 195px;
            height: 1000px;
            position: relative;
            .center {
                width: 1570px;
                height: 100%;
                margin: auto;
                overflow: hidden;

                p {
                    font-size: 20px;
                    color: #333;
                    margin-top: 24px;
                    text-align: left;
                    cursor: pointer;

                    span {
                        color: #ccc;
                    }
                }

                .centerContent {
                    width: 100%;
                    height: 902px;
                    background-color: #fff;
                    border-radius: 10px;
                    margin-top: 20px;
                    overflow: hidden;

                    .centerTop {
                        width: 100%;
                        display: flex;

                        .contentLeft {
                            width: 765px;
                            float: left;

                            .subjectName {
                                width: 607px;
                                height: 44px;
                                margin-top: 31px;
                                margin-left: 48px;
                                position: relative;

                                // background-color: #333;
                                .nameLin {
                                    width: 2px;
                                    height: 21px;
                                    background-color: #F15764;
                                    position: absolute;
                                    top: 50%;
                                    left: 0;
                                    transform: translateY(-50%);
                                }

                                .name {
                                    font-size: 16px;
                                    color: #333;
                                    line-height: 44px;
                                    float: left;
                                    margin-left: 12px;
                                }

                                input {
                                    width: 480px;
                                    height: 100%;
                                    border: 1px solid #ccc;
                                    box-sizing: border-box;
                                    outline: none;
                                    border-radius: .04px;
                                    position: absolute;
                                    right: 0;
                                    border-radius: 4px;
                                    font-size: 16px;
                                    color: #333;
                                    text-indent: 16px;
                                }
                                .noSubjectTitle{
                                    border: 1px solid #ff0000;
                                }
                            }

                            .subjectSetalis {
                                width: 765px;
                                height: 44px;
                                position: relative;
                                margin-left: 60px;
                                margin-top: 32px;

                                .detalis {
                                    font-size: 16px;
                                    color: #333;
                                    line-height: 44px;
                                    float: left;
                                }

                                input {
                                    width: 578px;
                                    height: 44px;
                                    border: 1px solid #ccc;
                                    border-radius: 4px;
                                    outline: none;
                                    box-sizing: border-box;
                                    position: absolute;
                                    left: 115px;
                                    font-size: 16px;
                                    color: #333;
                                    text-indent: 16px;
                                }
                                .noDescription{
                                    border: 1px solid #ff0000;
                                }
                            }

                            .subjectCover {
                                width: 720px;
                                height: 132px;
                                margin-left: 60px;
                                margin-top: 50px;

                                .cover {
                                    font-size: 16px;
                                    color: #333;
                                    line-height: 44px;
                                    float: left;
                                }

                                .imgBox {
                                    width: 212px;
                                    height: 132px;
                                    border: 1px solid #ccc;
                                    border-radius: 10px;
                                    float: left;
                                    margin-left: 33px;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 10px;
                                        object-fit: cover;
                                    }
                                }

                                .modify {
                                    width: 70px;
                                    height: 27px;
                                    margin-top: 105px;
                                    border: 1px solid #F15764;
                                    border-radius: 4px;
                                    margin-left: 20px;
                                    float: left;
                                    font-size: 14px;
                                    color: #F15764;
                                    line-height: 27px;
                                    cursor: pointer;
                                }

                                span {
                                    display: block;
                                    font-size: 12px;
                                    margin-top: 120px;
                                    float: left;
                                    margin-left: 10px;
                                    color: #ccc;
                                }
                            }
                        }

                        .right {
                            flex: 1;
                            float: right;

                            // background-color: #333;
                            .subjectPrice {
                                width: 100%;
                                height: 21px;
                                margin-top: 31px;

                                .line {
                                    width: 2px;
                                    height: 21px;
                                    background-color: #F15764;
                                    margin-left: 132px;
                                    float: left;
                                }

                                p {
                                    font-size: 16px;
                                    color: #333;
                                    float: left;
                                    margin-left: 12px;
                                    line-height: 21px;
                                    margin-top: 0;
                                }
                            }

                            .free {
                                width: 100%;
                                height: 16px;
                                margin-top: 20px;
                                position: relative;

                                .freeRadio {
                                    margin-left: 156px;
                                    height: 16px;
                                    width: 16px;
                                    height: 16px;
                                    border-radius: 50%;
                                    border: 1px solid #707070;
                                    box-sizing: border-box;
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);

                                    // border: 0;
                                    // background-color: #F15764;
                                    .instal {
                                        width: 0.08rem;
                                        height: 0.08rem;
                                        border-radius: 50%;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        background-color: #fff;
                                    }
                                }

                                .selectStyle {
                                    border: 0;
                                    background-color: #f15764;
                                }

                                .freeText {
                                    font-size: 16px;
                                    color: #333;
                                    margin-left: 196px;
                                    text-align: left;
                                }
                            }

                            .pay {
                                width: 100%;
                                height: 44px;
                                margin-top: 22px;
                                position: relative;

                                .payRadio {
                                    margin-left: 156px;
                                    height: 16px;
                                    width: 16px;
                                    height: 16px;
                                    border-radius: 50%;
                                    border: 1px solid #707070;
                                    box-sizing: border-box;
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);

                                    .instal {
                                        width: 0.08rem;
                                        height: 0.08rem;
                                        border-radius: 50%;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        background-color: #fff;
                                    }
                                }

                                .selectStyle {
                                    border: 0;
                                    background-color: #f15764;
                                }

                                .payText {
                                    font-size: 16px;
                                    color: #333;
                                    line-height: 44px;
                                    float: left;
                                    margin-left: 196px;
                                }

                                .price {
                                    width: 142px;
                                    height: 100%;
                                    float: left;
                                    margin-left: 22px;
                                    position: relative;

                                    input {
                                        width: 100%;
                                        height: 100%;
                                        background-color: #f9f9f9;
                                        border: 1px solid #ccc;
                                        box-sizing: border-box;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        border-radius: 4px;
                                        outline: none;
                                        font-size: 16px;
                                        text-indent: 16px;
                                    }
                                }

                                .fictitious {
                                    font-size: 16px;
                                    color: #333;
                                    line-height: 44px;
                                    float: left;
                                    margin-left: 22px;
                                }
                            }

                            .fictitiousNum {
                                width: 100%;
                                height: 44px;
                                margin-top: 37px;
                                position: relative;

                                .line {
                                    width: 2px;
                                    height: 21px;
                                    background-color: #F15764;
                                    margin-left: 132px;
                                    position: absolute;
                                    float: left;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }

                                .fictitiousNumText {
                                    font-size: 16px;
                                    color: #333;
                                    margin-left: 146px;
                                    text-align: left;
                                    float: left;
                                    line-height: 44px;
                                }

                                .fictitiousInput {
                                    width: 142px;
                                    height: 100%;
                                    float: left;
                                    margin-left: 22px;
                                    position: relative;

                                    input {
                                        width: 100%;
                                        height: 100%;
                                        background-color: #F9F9F9;
                                        border: 1px solid #ccc;
                                        box-sizing: border-box;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        border-radius: 4px;
                                        outline: none;
                                        font-size: 16px;
                                        text-indent: 16px;
                                    }
                                }
                            }

                            .is_sell {
                                width: 100%;
                                height: 44px;
                                margin-top: 37px;
                                position: relative;

                                .line {
                                    width: 2px;
                                    height: 21px;
                                    background-color: #F15764;
                                    margin-left: 132px;
                                    position: absolute;
                                    float: left;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }

                                .isSellText {
                                    font-size: 16px;
                                    color: #333;
                                    margin-left: 146px;
                                    text-align: left;
                                    float: left;
                                    line-height: 44px;
                                }

                                .sellSwitch {
                                    width: 50px;
                                    height: 25px;
                                    border: 1px solid #ccc;
                                    box-sizing: border-box;
                                    border-radius: 13px;
                                    margin-top: .11rem;
                                    float: left;
                                    margin-left: 20px;
                                    cursor: pointer;
                                    position: relative;
                                    background-color: #f9f9f9;

                                    .switchInstal {
                                        width: 22px;
                                        height: 22px;
                                        box-sizing: border-box;
                                        border-radius: 50%;
                                        border: 1px solid #ccc;
                                        position: absolute;
                                        top: 50%;
                                        left: 0px;
                                        transform: translateY(-50%);
                                        background-color: #fff;
                                    }
                                }

                                .styleSell {
                                    background-color: #F15764;
                                    border: 0;

                                    .switchInstal {
                                        position: absolute;
                                        top: 50%;
                                        left: 28px;
                                        transform: translateY(-50%);
                                    }
                                }
                            }
                            .threeListTitle{
                                width: 100%;
                                height: 44px;
                                margin-top: 37px;
                                position: relative;

                                .line {
                                    width: 2px;
                                    height: 21px;
                                    background-color: #F15764;
                                    margin-left: 132px;
                                    position: absolute;
                                    float: left;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }

                                .threeText {
                                    font-size: 16px;
                                    color: #333;
                                    margin-left: 146px;
                                    text-align: left;
                                    float: left;
                                    line-height: 44px;
                                }
                            }
                            .three_list{
                                width: 300px;
                                max-height: 3rem;
                                border: 1px solid #ccc;
                                margin-left: 1.46rem;
                                overflow: auto;
                                position: absolute;
                                top: 460px;
                                z-index: 2;

                                .three_item{
                                    width: 100%;
                                    height: 34px;
                                    border-bottom: 1px solid #ccc;
                                    font-size: 16px;
                                    color: #333;
                                    line-height: .34rem;
                                    cursor: pointer;
                                }
                                .three_item:last-child{
                                    border: 0;
                                }
                                .selectLeaf{
                                    background-color: #F15764;
                                    color: #fff;
                                }
                            }
                            ::-webkit-scrollbar {
                                width: 0 !important
                            }
                        }
                    }

                    .centerSelect {
                        width: 100%;
                        height: 283px;
                        margin-top: 70px;
                        position: absolute;
                        top: 403px;
                        // left: 60px;
                        .selectContent {
                            width: 701px;
                            height: 100%;
                            position: relative;
                            margin-left: 60px;
                            // overflow: hidden;
                            padding-top: 48px;
                            box-sizing: border-box;
                            float: left;

                            .selectTop {
                                width: 100%;
                                height: 48px;
                                background-color: #fff;
                                box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
                                position: absolute;
                                top: 0;
                                left: 0;

                                .topName {
                                    font-size: 14px;
                                    color: #333;
                                    float: left;
                                    line-height: 48px;
                                    margin-left: 75px;
                                }

                                .topPay {
                                    font-size: 14px;
                                    color: #333;
                                    float: left;
                                    line-height: 48px;
                                    margin-left: 133px;
                                }

                                .topTime {
                                    font-size: 14px;
                                    color: #333;
                                    float: left;
                                    line-height: 48px;
                                    margin-left: 125px;
                                }

                                .topOperate {
                                    font-size: 14px;
                                    color: #333;
                                    float: left;
                                    line-height: 48px;
                                    margin-left: 115px;
                                }
                            }

                            .selectBot {
                                width: 100%;
                                height: 235px;
                                background-color: #fff;
                                box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
                                overflow: hidden;

                                .botContent {
                                    width: 100%;
                                    height: 167px;
                                    overflow: auto;

                                    .selectItem {
                                        width: 100%;
                                        height: 18px;
                                        margin-top: 17px;
                                        margin-bottom: 26px;
                                        margin-top: 17px;
                                        position: relative;

                                        .Selectname {
                                            width: 48px;
                                            font-size: 14px;
                                            color: #333;
                                            margin-left: 64px;
                                            float: left;
                                        }

                                        .Selectpay {
                                            width: 54px;
                                            font-size: 14px;
                                            color: #ff0000;
                                            margin-left: 119px;
                                            float: left;
                                        }

                                        .selectTime {
                                            width: 40px;
                                            font-size: 14px;
                                            color: #333;
                                            float: left;
                                            margin-left: 135px;
                                        }

                                        .selectEdit {
                                            width: 16px;
                                            height: 100%;
                                            background-size: 100% 16px;
                                            background-image: url('../assets/img/home/edit.png');
                                            background-repeat: no-repeat;
                                            background-position: center;
                                            margin-left: 118px;
                                            float: left;
                                            cursor: pointer;
                                        }

                                        .selectDelete {
                                            width: 14px;
                                            height: 100%;
                                            float: left;
                                            margin-left: 25px;
                                            background-image: url('../assets/img/home/delete.png');
                                            background-repeat: no-repeat;
                                            background-size: 100% 16px;
                                            background-position: center;
                                            cursor: pointer;
                                        }
                                    }
                                }

                                .botContent::-webkit-scrollbar {
                                    width: 0 !important
                                }

                                .addSelectItem {
                                    width: 239px;
                                    height: 20px;
                                    border: 1px solid #F15764;
                                    box-sizing: border-box;
                                    margin: 30px auto 0;
                                    font-size: 14px;
                                    color: #F15764;
                                    line-height: 16px;
                                    cursor: pointer;
                                }
                            }

                        }

                        .selectPop {
                            width: 513px;
                            height: 180px;
                            float: left;
                            position: absolute;
                            left: 761px;
                            top: 0;
                            z-index: 5;
                            background-color: #fff;
                            .popTitle {
                                width: 100%;
                                height: 48px;
                                box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
                                background-color: #fff;
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: 1;

                                .titleName {
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 48px;
                                    float: left;
                                    margin-left: 70px;
                                }

                                .titlePay {
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 48px;
                                    float: left;
                                    margin-left: 128px;
                                }

                                .titleTime {
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 48px;
                                    float: left;
                                    margin-left: 125px;
                                }
                            }

                            .popContent {
                                width: 100%;
                                height: 131px;
                                margin-top: 48px;
                                box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
                                padding-top: 10px;
                                box-sizing: border-box;

                                .popItem {
                                    width: 100%;
                                    height: 32px;

                                    // margin-top: 10px;
                                    .itemName {
                                        width: 87px;
                                        height: 32px;
                                        float: left;

                                        margin-left: 42px;
                                        position: relative;

                                        input {
                                            width: 100%;
                                            height: 100%;
                                            outline: none;
                                            border: 1px solid #CCCCCC;
                                            box-sizing: border-box;
                                            border-radius: 4px;
                                            text-align: center;
                                            font-size: 14px;
                                            line-height: 30px;
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                        }
                                    }

                                    .timePay {
                                        width: 87px;
                                        height: 32px;
                                        float: left;
                                        margin-left: 83px;
                                        position: relative;

                                        input {
                                            width: 100%;
                                            height: 100%;
                                            outline: none;
                                            border: 1px solid #CCCCCC;
                                            box-sizing: border-box;
                                            border-radius: 4px;
                                            text-align: center;
                                            font-size: 14px;
                                            line-height: 30px;
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                        }
                                    }

                                    .itemTime {
                                        width: 87px;
                                        height: 32px;
                                        float: left;
                                        margin-left: 95px;
                                        position: relative;

                                        input {
                                            width: 100%;
                                            height: 100%;
                                            outline: none;
                                            border: 1px solid #CCCCCC;
                                            box-sizing: border-box;
                                            border-radius: 4px;
                                            text-align: center;
                                            font-size: 14px;
                                            line-height: 30px;
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                        }
                                    }
                                }

                                .popBtn {
                                    width: 253px;
                                    height: 24px;
                                    margin: auto;
                                    margin-top: 44px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    justify-content: space-between;

                                    .sure {
                                        background-color: #F15764;
                                        width: 93px;
                                        height: 100%;
                                        font-size: 12px;
                                        color: #fff;
                                        line-height: 24px;
                                        border-radius: 4px;
                                        cursor: pointer;
                                    }

                                    .cancel {
                                        border: 1px solid #F15764;
                                        box-sizing: border-box;
                                        width: 93px;
                                        height: 100%;
                                        font-size: 12px;
                                        color: #F15764;
                                        line-height: 24px;
                                        border-radius: 4px;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }

                    .contentBtn {
                        width: 412px;
                        height: 38px;
                        position: absolute;
                        left: 50%;
                        bottom: .92rem;
                        transform: translateX(-50%);
                        display: flex;
                        flex-wrap: nowrap;
                        flex-direction: row;
                        justify-content: space-between;

                        .contentSure {
                            width: 153px;
                            height: 38px;
                            background-color: #F15764;
                            border-radius: 4px;
                            color: #fff;
                            font-size: 14px;
                            line-height: 38px;
                            cursor: pointer;
                        }

                        .contentCancel {
                            width: 153px;
                            height: 38px;
                            border: 1px solid #F15764;
                            box-sizing: border-box;
                            color: #F15764;
                            border-radius: 4px;
                            font-size: 14px;
                            line-height: 38px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
</style>