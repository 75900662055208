import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
import Home from '../views/home.vue'
import SubjectAdmin from '../views/subjectAdmin.vue'
import AddSubject from '../views/addSubject.vue'
import ChapterAdmin from '../views/chapterAdmin.vue'
import AddChapter from '../views/addChapter.vue'
import SectionAdmin from '../views/sectionAdmin.vue'
import AddSection from '../views/addSection.vue'
import QuestionAdmin from '../views/questionAdmin.vue'
import UserAdmin from '../views/userAdmin.vue'
import UserDetalis from '../views/userDetalis.vue'
import ItemUserDetalis from '../views/itemUserDetalis.vue'
import EditSubject from '../views/editSubject.vue'
import EditChapter from '../views/editChapter.vue'
import EditSection from '../views/editSection.vue'
import LeftHome from '../views/leftHome.vue'
import OrderAdmin from '../views/orderAdmin.vue'
import CodeAdmin from '../views/codeAdmin.vue'
import Feendback from '../views/ErrorFeedback.vue'
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/subjectAdmin',
        name: 'SubjectAdmin',
        component: SubjectAdmin
    },
    {
        path: '/addSubject',
        name: 'AddSubject',
        component: AddSubject
    },
    {
        path: '/chapterAdmin',
        name: 'ChapterAdmin',
        component: ChapterAdmin
    },
    {
        path: '/addChapter',
        name: 'AddChapter',
        component: AddChapter
    },
    {
        path: '/sectionAdmin',
        name: 'SectionAdmin',
        component: SectionAdmin
    },
    {
        path: '/addSection',
        name: 'AddSection',
        component: AddSection
    },
    {
        path: '/questionAdmin',
        name: 'QuestionAdmin',
        component: QuestionAdmin
    },
    {
        path: '/userDetalis',
        name: 'UserDetalis',
        component: UserDetalis
    },
    {
        path: '/userAdmin',
        name: 'UserAdmin',
        component: UserAdmin
    },
    {
        path: '/itemUserDetalis',
        name: 'ItemUserDetalis',
        component: ItemUserDetalis
    },
    {
        path: '/editSubject',
        name: 'EditSubject',
        component: EditSubject
    },
    {
        path: '/editChapter',
        name: 'EditChapter',
        component: EditChapter
    },
    {
        path: '/editSection',
        name: 'EditSection',
        component: EditSection
    },
    {
        path: '/leftHome',
        name: 'LeftHome',
        component: LeftHome
    },
    {
        path: '/orderAdmin',
        name: 'OrderAdmin',
        component: OrderAdmin
    },
    {
        path: '/codeAdmin',
        name: 'CodeAdmin',
        component: CodeAdmin
    },
    {
        path: '/feendback',
        name: 'Feendback',
        component: Feendback
    },
]

const router = new VueRouter({
    routes
})

export default router