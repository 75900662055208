<template>
  <div class="home">
    <top></top>
    <div class="homeContent">
      <left></left>
      <div class="right">
        <!-- <error></error> -->
        <div class="rightCenter">
          <p>工作台</p>
          <div class="title">
            <div class="titleCenter">
              <div class="centerText">实时统计</div>
            </div>
            <div class="titleContent">
              <div class="titleItem">
                <div class="itemTitle">
                  <div class="subjectImg"></div>
                  <div class="itemTitleText">
                    <div class="itemNumber">题库数量</div>
                    <div class="itemNum">{{subjectNumber}}</div>
                  </div>
                </div>
                <div class="itemBot">
                  <div class="itemLeft">本月新增:{{subjectMonth}}</div>
                  <div class="itemRight">今日新增:{{subjectDay}}</div>
                  <div class="line"></div>
                </div>
              </div>

              <div class="titleItem">
                <div class="itemTitle">
                  <div class="questionImg"></div>
                  <div class="itemTitleText">
                    <div class="itemNumber">题目数量</div>
                    <div class="itemNum">{{questionNumber}}</div>
                  </div>
                </div>
                <div class="itemBot">
                  <div class="itemLeft">本月新增:{{questionMonth}}</div>
                  <div class="itemRight">今日新增:{{questionDay}}</div>
                  <div class="line"></div>
                </div>
              </div>

              <div class="titleItem">
                <div class="itemTitle">
                  <div class="userImg"></div>
                  <div class="itemTitleText">
                    <div class="itemNumber">系统总用户</div>
                    <div class="itemNum">{{userNumber}}</div>
                  </div>
                </div>
                <div class="itemBot">
                  <div class="itemLeft">本月新增:{{userMonth}}</div>
                  <div class="itemRight">今日新增:{{userday}}</div>
                  <div class="line"></div>
                </div>
              </div>

              <div class="titleItem">
                <div class="itemTitle">
                  <div class="payImg"></div>
                  <div class="itemTitleText">
                    <div class="itemNumber">付费总人次</div>
                    <div class="itemNum">{{orderNumber}}</div>
                  </div>
                </div>
                <div class="itemBot">
                  <div class="itemLeft">本月新增:{{orderMonth}}</div>
                  <div class="itemRight">今日新增:{{orderDay}}</div>
                  <div class="line"></div>
                </div>
              </div>

              <div class="titleItem">
                <div class="itemTitle">
                  <div class="moneyImg"></div>
                  <div class="itemTitleText">
                    <div class="itemNumber">总成交金额</div>
                    <div class="itemNum">{{moneyNumber}}</div>
                  </div>
                </div>
                <div class="itemBot">
                  <div class="itemLeft">本月新增:{{moneyMonth}}</div>
                  <div class="itemRight">今日新增:{{moneyDay}}</div>
                  <div class="line"></div>
                </div>
              </div>

              <div class="titleItem">
                <div class="itemTitle">
                  <div class="activeImg"></div>
                  <div class="itemTitleText">
                    <div class="itemNumber">本月活跃用户</div>
                    <div class="itemNum">{{activeMonth}}</div>
                  </div>
                </div>
                <div class="itemBot">
                  <div class="itemLeft">本周活跃:{{activeWeek}}</div>
                  <div class="itemRight">今日活跃:{{activeDay}}</div>
                  <div class="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="echarts" >
            <div class="echartsTitle">
              <div class="titleText">展示图</div>
              <div class="all" :class="{borderColor:style==1?true:false}" @click="selectStyle(1)">历年</div>
              <div class="year" :class="{borderColor:style==4?true:false}" @click="selectStyle(4)">一年内</div>
              <div class="uppermonth" :class="{borderColor:style==3?true:false}" @click="selectStyle(3)">上个月</div>
              <div class="month" :class="{borderColor:style==2?true:false}" @click="selectStyle(2)">本月</div>
            </div>
            <div class="echatrtsImg" id="main"></div>
          </div>


          <div class="fastTitle">
            <div class="titleCenter">
              <div class="fastText">快速入口</div>
            </div>
          </div>
          <div class="fast">
            <div class="addSubject">
              <div class="center" @click="addSubject">
                <div class="centerTitle">添加题库</div>
                <div class="centerContent">快速添加题库内容</div>
              </div>
            </div>
            <div class="addChirte">
              <div class="center" @click="addChirte">
                <div class="centerTitle">添加章</div>
                <div class="centerContent">快速添加章内容</div>
              </div>
            </div>
            <div class="addSection">
              <div class="center" @click="addSection">
                <div class="centerTitle">添加节</div>
                <div class="centerContent">快速添加节内容</div>
              </div>
            </div>
            <div class="upSubject">
              <div class="center">
                <div class="centerTitle">上传考题</div>
                <div class="centerContent">上传考题实时更新</div>
              </div>
            </div>
            <div class="bg1">
              <div class="center">
                <div class="centerTitle">上传考题</div>
                <div class="centerContent">上传考题实时更新</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bg"></div> -->
  </div>
</template>

<script>
import * as echarts from 'echarts';
import top from '../components/nav.vue'
import left from '../components/left.vue'
import success from '../components/success.vue'
import error from '../components/error.vue'
export default {
  components:{
    top,left,success,error
  },
  
  data(){
    return{
      style:2,
      styleX:[],
      styleY:[],
      subjectNumber:'',//题库
      subjectMonth:'',
      subjectDay:'',
      questionNumber:'',//题目
      questionMonth:'',
      questionDay:'',
      userNumber:'',//用户
      userMonth:'',
      userday:'',
      orderNumber:'',//付费
      orderMonth:'',//
      orderDay:'',
      activeWeek:'',//活跃
      activeMonth:'',
      activeDay:'',
      moneyNumber:'',//金额
      moneyMonth:'',
      moneyDay:'',
      salesLists:[],
    }
  },
  methods:{
    addSubject(){
      this.$router.push({path:'/addSubject',query:{leftId:2,barId:1}})
    },
    addChirte(){
      this.$router.push({path:'/chapterAdmin',query:{leftId:2,barId:2}})
    },
    addSection(){
      this.$router.push({path:'/sectionAdmin',query:{leftId:2,barId:3}})
    },
    selectStyle(val){
      this.style = val;
      this.salesListBtn();
      if(val==1){
        this.salesListBtn();
      }
      if(val ==2){
        this.salesListBtn();
     }
      if(val ==3){
        this.salesListBtn();
      }
      if(val == 4){
       this.salesListBtn();
      }
    },
    exharts(){
      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
			      text: '成交金额走势图',
            x:'center',
            y:'auto',
            textStyle: { // 主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
              fontFamily: 'Arial',
              fontSize: 16,
              fontStyle: 'normal',
              fontWeight: 'normal',
          },
			  },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data:this.styleX,
          
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            smooth: true,
            data:this.styleY,
            symbol:'circle',
            symbolSize:[8,8],
            type: 'line',
            areaStyle: {
              normal: {
				  			color: 'rgba(255, 192, 146, 1)' //改变区域颜色
				  		}
            },
            itemStyle: {
				    normal: {
				        color: 'rgba(255, 106, 0, 1)'
				    }
				  },
          }
        ],
        tooltip : {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
          }
        }
}
      };

      option && myChart.setOption(option);
    },
    //首页统计信息
    homeNewsBtn(){
      this.$.homeNews().then(res=>{
        console.log(res,'首页统计信息==================================')
        this.subjectNumber = res.subjectInfo.count;
        this.subjectMonth = res.subjectInfo.month_increase;
        this.subjectDay = res.subjectInfo.day_increase;
        this.questionNumber = res.questionInfo.count;
        this.questionMonth = res.questionInfo.month_increase;
        this.questionDay = res.questionInfo.day_increase;
        this.userNumber = res.userInfo.count;
        this.userMonth = res.userInfo.month_increase;
        this.userday = res.userInfo.day_increase;
        this.orderNumber = res.orderInfo.count;
        this.orderMonth = res.orderInfo.month_increase;
        this.orderDay = res.orderInfo.day_increase;
        this.activeWeek = res.activeInfo.week_increase;
        this.activeMonth = res.activeInfo.month_increase;
        this.activeDay = res.activeInfo.day_increase;
        this.moneyNumber = res.moneyInfo.count;
        this.moneyMonth = res.moneyInfo.month_increase;
        this.moneyDay = res.moneyInfo.day_increase
      })
    },
    //获取销售统计
    salesListBtn(){
      var self = this;
      var formData = new FormData();
      formData.append('type',this.style);
      this.$.salesList(formData).then(res=>{
        console.log(res,'销售统计');
        this.salesLists = res.data;
        console.log(this.salesLists);
        if(this.style==2 ||this.style==3){
          self.styleX=[];
          self.styleY = [];
          this.salesLists.forEach((item)=>{
              self.styleX.push(item.date);
              self.styleY.push(item.money)
          })
        }
        if(this.style == 1){
          self.styleX=[];
          self.styleY = [];
          this.salesLists.forEach((item)=>{
              self.styleX.push(item.year);
              self.styleY.push(item.money)
          })
        }
        if(this.style == 4){
          self.styleX=[];
          self.styleY = [];
          this.salesLists.forEach((item)=>{
              self.styleX.push(item.month);
              self.styleY.push(item.money)
          })
        }
        this.exharts();
      })
    }
  },
  mounted(){
    this.exharts();
    this.homeNewsBtn();
    this.salesListBtn();
  }
}
</script>

<style lang='less' scoped>
  .home{
    width: 100%;
    // height: 100vh;
    background-color: #F2F2F2;
    overflow: hidden;
    .homeContent{
      width: 100%;
      height: 100vh;
      margin-top: 60px;
      background-color: #F2F2F2;
      display: flex;
      .right{
        flex: 1;
        height: 100%;
        margin-left: 195px;
        position: relative;
        .rightCenter{
          width: 1570px;
          overflow: hidden;
          margin: 30px auto;
          p{
            font-size: 18px;
            color: #333;
            text-align: left;
          }
          .title{
            width: 100%;
            height:246px;
            margin-top: 20px;
            background-color: #fff;
            margin-bottom: 30px;
            .titleCenter{
              width: 1520px;
              height: 57px;
              border-bottom: 1px solid rgba(112, 112, 112, .2);
              margin: auto;
              overflow: hidden;
              .centerText{
                width: .86rem;
                border-left: 2px solid #F15764;
                margin-top: 26px;
                font-size: 18px;
                line-height: 19px;
                color: #333;
              }
            }
            .titleContent{
              width: 1520px;
              height: 120px;
              // margin-top: 24px;
              margin:24px auto 0;
              // background-color: red;
              display: flex;
              flex-wrap: nowrap;
              flex-direction: row;
              justify-content: space-between;
              .titleItem{
                width: 224px;
                height: 120px;
                background-color: #fff;
                box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
                border-radius: 4px;
                .itemTitle{
                  width: 100%;
                  height: 54px;
                  margin-top: 20px;
                  .subjectImg{
                    width: 54px;
                    height: 100%;
                    float: left;
                    margin-left: 55px;
                    background-image: url('../assets/img/subject.png');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                  }
                  .questionImg{
                    width: 54px;
                    height: 100%;
                    float: left;
                    margin-left: 55px;
                    background-image: url('../assets/img/question.png');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                  }
                  .userImg{
                    width: 54px;
                    height: 100%;
                    float: left;
                    margin-left: 55px;
                    background-image: url('../assets/img/user.png');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                  }
                  .payImg{
                    width: 54px;
                    height: 100%;
                    float: left;
                    margin-left: 55px;
                    background-image: url('../assets/img/pay.png');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                  }
                  .moneyImg{
                    width: 54px;
                    height: 100%;
                    float: left;
                    margin-left: 55px;
                    background-image: url('../assets/img/money.png');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                  }
                  .activeImg{
                    width: 54px;
                    height: 100%;
                    float: left;
                    margin-left: 55px;
                    background-image: url('../assets/img/active.png');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                  }
                  .itemTitleText{
                    height: 100%;
                    float: left;
                    margin-left: 12px;
                    .itemNumber{
                      font-size: .12rem;
                      color: rgba(51, 51, 51, .2);
                      text-align: left;
                      margin-top: 7px;
                    }
                    .itemNum{
                      font-size: 20px;
                      color: #333;
                      text-align: left;
                      font-weight: 600;
                      margin-top: 8px;
                    }
                  }
                }
                .itemBot{
                  width: 100%;
                  height: 12px;
                  margin-top: 17px;
                  position: relative;
                  .itemLeft{
                    width: 50%;
                    height: 100%;
                    font-size: 12px;
                    color: rgba(51, 51, 51, .5);
                    float: left;
                  }
                  .itemRight{
                    width: 50%;
                    height: 100%;
                    font-size: 12px;
                    color: rgba(51, 51, 51, .5);
                    float: right;
                  }
                  .line{
                    width: 1px;
                    height: 100%;
                    background-color: rgba(112, 112, 112, .3);
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 0;
                  }
                }
              }
            }
          }
          .echarts{
            width: 100%;
            height: 360px;
            background-color: #fff;
            margin-top: 20px;
            margin-bottom: 20px;

            .echartsTitle{
              width: 1520px;
              height: 56px;
              border-bottom: 1px solid rgba(112, 112, 112, .2);
              margin: auto;
              overflow: hidden;
              .titleText{
                width: 68px;
                height: 18px;
                margin-top: 26px;
                font-size: 18px;
                border-left: 2px solid #F15764;
                float: left;
              }
              .all{
                height: 38px;
                margin-top: 18px;
                border-bottom: 3px solid transparent;
                box-sizing: border-box;
                font-size: 16px;
                line-height: 18px;
                color: #999999;
                float: right;
                cursor: pointer;
              }
              .uppermonth, .month, .year{
                height: 38px;
                margin-top: 18px;
                // border-bottom: 3px solid #F15764;
                border-bottom: 3px solid transparent;
                box-sizing: border-box;
                font-size: 16px;
                line-height: 18px;
                color: #999999;
                float: right;
                margin-right: 50px;
                cursor: pointer;
              }
              .borderColor{
                border-bottom: 3px solid #F15764;
                color: #333;
              }
            }
            .echatrtsImg{
              width: 100%;
              height: 280px;
              margin-top: .2rem;
            }
          }
          .fastTitle{
            width: 100%;
            height: 56px;
            background-color: #fff;
            .titleCenter{
              width: 1520px;
              height: 100%;
              border-bottom: 1px solid rgba(112, 112, 112, .2);
              box-sizing: border-box;
              margin: auto;
              overflow: hidden;
              .fastText{
                width: 86px;
                height: 19px;
                margin-top: 26px;
                border-left: 1px solid #F15764;
                font-size: 18px;
              }
            }
          }
          .fast{
            width: 100%;
            height: 190px;
            background-color: #fff;
            margin-bottom: 40px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: space-around;
            .addSubject{
              width: 240px;
              height: 94px;
              background-image: url('../assets/img/home/addSubject.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
              position: relative;
              margin-top: 25px;
              .center{
                width: 100%;
                height:94px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                cursor: pointer;
                .centerTitle{
                  font-size: 20px;
                  font-weight: 600;
                  margin-top: 18px;
                  text-indent: 20px;
                  text-align: left;
                  color: #fff;
                }
                .centerContent{
                  font-size:14px;
                  color: #fff;
                  margin-top: 16px;
                  text-align: left;
                  text-indent: 20px;
                }
              }
            }
            .addChirte{
              width: 240px;
              height: 94px;
              background-image: url('../assets/img/home/addChirte.png');
              background-repeat: no-repeat;
              background-size: 100% 94px;
              position: relative;
              margin-top: 25px;
              .center{
                width: 100%;
                height:94px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                cursor: pointer;
                .centerTitle{
                  font-size: 20px;
                  font-weight: 600;
                  margin-top: 18px;
                  text-indent:20px;
                  text-align: left;
                  color: #fff;
                }
                .centerContent{
                  font-size: 14px;
                  color: #fff;
                  margin-top: 16px;
                  text-align: left;
                  text-indent: 20px;
                }
              }
            }
            .upSubject{
              width: 240px;
              height: 94px;
              background-image: url('../assets/img/home/upsubject.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
              position: relative;
              margin-top: 25px;
              .center{
                width: 100%;
                height:94px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                cursor: pointer;
                .centerTitle{
                  font-size: 20px;
                  font-weight: 600;
                  margin-top: 18px;
                  text-indent:20px;
                  text-align: left;
                  color: #fff;
                }
                .centerContent{
                  font-size: 14px;
                  color: #fff;
                  margin-top: 16px;
                  text-align: left;
                  text-indent: 20px;
                }
              }
            }
            .addSection{
              width: 240px;
              height: 94px;
              background-image: url('../assets/img/home/addSection.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
              position: relative;
              margin-top: 25px;
              .center{
                width: 100%;
                height:94px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                cursor: pointer;
                .centerTitle{
                  font-size: 20px;
                  font-weight: 600;
                  margin-top: 18px;
                  text-indent: 20px;
                  text-align: left;
                  color: #fff;
                }
                .centerContent{
                  font-size: 14px;
                  color: #fff;
                  margin-top: 16px;
                  text-align: left;
                  text-indent: 20px;
                }
              }
            }
            .bg1{
              width: 240px;
              height: 94px;
              background-image: url('../assets/img/home/bg1.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
              margin-top: 25px;
              position: relative;
              .center{
                width: 100%;
                height:94px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                cursor: pointer;
                .centerTitle{
                  font-size: 20px;
                  font-weight: 600;
                  margin-top: 18px;
                  text-indent: 20px;
                  text-align: left;
                  color: #fff;
                }
                .centerContent{
                  font-size: 14px;
                  color: #fff;
                  margin-top: 16px;
                  text-align: left;
                  text-indent: 20px;
                }
              }
            }
          }
        }
      }
    }
    .bg{
      width: 100%;
      height: 10.8px;
      background-image: url('../assets/img/bg.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      opacity: .5;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
</style>