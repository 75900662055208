<template>
  <div class="userDetalis">
      <top></top>
      <div class="content">
          <left></left>
          <div class="contetnRight">
              <div class="center">
                <div class="title">系统管理</div>
                <div class="centerContent">
                    <div class="userNumberTitle">设置后台账号</div>
                    <div class="numberContetn">
                        <div class="numLeft">
                            <p>登录名/手机号</p>
                            <input type="text" v-model="phone">
                        </div>
                        <div class="numRight">
                            <p>密码</p>
                            <input type="text" v-model="password">
                        </div>
                    </div>
                    <div class="serviceTitle">设置企业客服</div>
                    <div class="serviceContent">
                        <div class="serviceLeft">
                            <p>企业ID</p>
                            <input type="text" v-model="ID">
                        </div>
                        <div class="serviceRight">
                            <p>客服url</p>
                            <input type="text" v-model="url">
                        </div>
                    </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import top from '../components/nav.vue'
import left from '../components/left.vue'
export default {
    components:{
        top,left
    },
    data(){
        return{
            phone:'18403320660',
            password:'123456',
            ID:'wwf3baea60eb919a1c',
            url:'https://work.weixin.qq.com/kfid/kfc5e2d7d6657e46541 ',
        }
    },
    methods:{
    }
}
</script>

<style lang='less' scoped>
    .userDetalis{
        width: 100%;
        height: 1080px;
        background-color: #f2f2f2;
        .content{
            width: 100%;
            height: 100vh;
            margin-top: 60px;
            display: flex;
            .contetnRight{
                flex: 1;
                margin-left: 195px;
                height: 100%;
                .center{
                    width: 1570px;
                    height: 100%;
                    margin: auto;
                    .title{
                        font-size: 20px;
                        color: #333;
                        text-align: left;
                        margin-top: 30px ;
                        margin-bottom: 14px;
                    }
                    .centerContent{
                        width: 15.70rem;
                        height: 9.02rem;
                        background-color: #fff;
                        border-radius: .1rem;
                        margin: .14rem auto;
                        overflow: hidden;
                        .userNumberTitle{
                            width: 14.3rem;
                            height: .68rem;
                            margin:.28rem auto .24rem;
                            border-bottom: 1px solid #ccc;
                            font-size: .22rem;
                            color: #333;
                            font-weight: 600;
                            line-height: .68rem;
                            text-align: left;
                        }
                         .numberContetn{
                            width: 13.1rem;
                            height: .8rem;
                            margin: auto;
                            // background-color: red;
                            .numLeft{
                                width: 5.4rem;
                                height: 100%;
                                float: left;
                                position: relative;
                                p{
                                    font-size: .16rem;
                                    color: #ccc;
                                    text-align: left;
                                }
                                input{
                                    width: 100%;
                                    height: .44rem;
                                    border: 1px solid #ccc;
                                    border-radius: 4px;
                                    position: absolute;
                                    left: 0;
                                    bottom: 0;
                                    outline: none;
                                    box-sizing: border-box;
                                    text-indent:.2rem;
                                    font-size: .18rem;
                                }
                            }
                            .numRight{
                                width: 5.4rem;
                                height: 100%;
                                float: right;
                                position: relative;
                                p{
                                    font-size: .16rem;
                                    color: #ccc;
                                    text-align: left;
                                }
                                input{
                                    width: 100%;
                                    height: .44rem;
                                    border: 1px solid #ccc;
                                    border-radius: 4px;
                                    position: absolute;
                                    left: 0;
                                    bottom: 0;
                                    outline: none;
                                    box-sizing: border-box;
                                    text-indent:.2rem;
                                    font-size: .18rem;
                                }
                            }
                        }
                        .serviceTitle{
                            width: 14.3rem;
                            height: .68rem;
                            margin:.38rem auto .24rem;
                            border-bottom: 1px solid #ccc;
                            font-size: .22rem;
                            color: #333;
                            font-weight: 600;
                            line-height: .68rem;
                            text-align: left;
                        }
                        .serviceContent{
                            width: 13.1rem;
                            height: .8rem;
                            margin: auto;
                            .serviceLeft{
                                width: 5.4rem;
                                height: 100%;
                                float: left;
                                position: relative;
                                p{
                                    font-size: .16rem;
                                    color: #ccc;
                                    text-align: left;
                                }
                                input{
                                    width: 100%;
                                    height: .44rem;
                                    border: 1px solid #ccc;
                                    border-radius: 4px;
                                    position: absolute;
                                    left: 0;
                                    bottom: 0;
                                    outline: none;
                                    box-sizing: border-box;
                                    text-indent:.2rem;
                                    font-size: .18rem;
                                }
                            }
                            .serviceRight{
                                width: 5.4rem;
                                height: 100%;
                                float: right;
                                position: relative;
                                p{
                                    font-size: .16rem;
                                    color: #ccc;
                                    text-align: left;
                                }
                                input{
                                    width: 100%;
                                    height: .44rem;
                                    border: 1px solid #ccc;
                                    border-radius: 4px;
                                    position: absolute;
                                    left: 0;
                                    bottom: 0;
                                    outline: none;
                                    box-sizing: border-box;
                                    text-indent:.2rem;
                                    font-size: .18rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>