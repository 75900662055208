<template>
  <div class="codeAdmin">
      <top-bar></top-bar>
      <div class="codeContent">
          <left-bar></left-bar>
          <div class="codeRight">
              <div class="rightContent">
                  <p>激活码管理</p>
                  <div class="centerContent">
                      <div class="contentTitle">
                          <div class="selectSubject" @click="selectSubjectBtn">
                              {{subjectName}}<span v-if="!subjectName">选择题库</span>
                              <div class="arrow"></div>
                              <div class="subjectBar" v-show="subjectBar">
                              <div class="noSubject" :class="{selectsubject:subjectItem==-1?true:false}" @click="noSubject">空</div>
                                  <div class="barItem" v-for="(item,index) in subjectList" :key="index" 
                                        :class="{selectsubject:subjectItem==index?true:false}" @click.stop="subjectItemBtn(item,index)">
                                      <div class="subjectTitle">{{item.title}}</div>
                                      <div class="subjectDetails">{{item.description}}</div>
                                  </div>
                              </div>
                          </div>
                          <div class="selectStyle" @click="selectStyleBtn">
                              {{styleName}}<span v-if="!styleName">选择状态</span>
                              <div class="arrow"></div>
                              <div class="styleBar" v-show="styleBar">
                                  <div class="styleItem" v-for="(item,index) in styleLis" :key="index" @click.stop="styleItemBtn(item,index)"
                                        :class="{selectStyleItem:selectStyle==index?true:false}">{{item.val}}</div>
                              </div>
                          </div>
                          <div class="establish" @click="establish">创建激活码</div>
                      </div>
                      <div class="listTitle">
                          <div class="ID">ID</div>
                          <div class="titleCode">激活码</div>
                          <div class="titleName">商品名称</div>
                          <div class="titleTime">生成时间</div>
                          <!-- <div class="titleStop">截止时间</div> -->
                          <div class="titleStyle">激活状态</div>
                          <div class="titleCycle">周期</div>
                          <div class="operate">操作</div>
                      </div>
                      <div class="listItem" v-for="(item,index) in codeList" :key="index">
                            <div class="itemId">{{index+1 + (currentPage-1)*7}}</div>
                          <div class="itemCode">{{item.code}}</div>
                          <div class="itemName">
                              <div class="subjectTitle">{{item.subject.title}}</div>
                              <div class="description">{{item.subject.description}}</div>
                              </div>
                          <div class="itemTime">{{item.created_at | formatDate}}</div>
                          <!-- <div class="itemStop">2023-04-16  17:58</div> -->
                          <div class="itemStyle1" v-if="item.status==1?true:false">未使用</div>
                          <div class="itemStyle2" v-if="item.status==2?true:false">已使用</div>
                          <div class="itemStyle3" v-if="item.status==3?true:false">已失效</div>
                          <div class="itemCycle">{{item.option!=null?item.option.title:'默认'}}</div>
                          <div class="delete" @click="deleteCode(item)" v-if="item.status==1?true:false"></div>
                      </div>
                      <page-bar :currentPage="currentPage" :total="total" :count="count" @jumpNumber="jumpNumber" @nextPage="nextPage" @prePage="prePage" @pageTo="pageTo"></page-bar>
                      <div class="loadingPanel" :style="{'opacity':loadingOpacity}" v-show="loadingOpacity<=0?false:true">
                        <img src="../assets/img/home/onLoad.gif" />
                    </div>
                </div>
              </div>
              <success-bar :tipsText="success" v-if="successShow"></success-bar>
              <error-bar :tipsText="error" v-if="errorShow"></error-bar>
          </div>
      </div>
      <div class="popBar" v-show="popBar">
          <div class="establishPop">
              <div class="title">
                  <div class="titleText">创建激活码</div>
                  <div class="close" @click="closeBtn"></div>
              </div>
              <div class="search1">
                  <div class="searchSubjectText">选择题库：</div>
                  <div class="searchSubjectInput" @click="selectSubjectPopBtn">
                      {{subjecPopText}}<span v-if="subjecPopText==''?true:false">请选择题库</span>
                      <div class="arrow"></div>
                      <div class="subjectBar" v-show="subjectPopBar">
                          <div class="noSubject" :class="{selectsubject:subjectPopItem==-1?true:false}" @click="noSubjectPopBtn">空</div>
                          <div class="barItem" v-for="(item,index) in subjectList" :key="index" 
                                :class="{selectsubject:subjectPopItem==index?true:false}" @click.stop="subjectItemPopBtn(item,index)">
                                <div class="subjectTitle">{{item.title}}</div>
                                <div class="subjectDetails">{{item.description}}</div>
                            </div>
                      </div>
                  </div>
                  <div class="selectTimeText">激活周期：</div>
                  <div class="cycleInput" @click="cycleBtn">
                      {{cyaleText}}<span v-if="cyaleText==''?true:false">请选择周期</span>
                      <div class="arrow"></div>
                    <div class="cycleBar" v-show="cycleBar">
                        <div class="cycleItem" v-for="(item,index) in optionsList" 
                            :key="index" :class="{selectcycle:selectcycle==index?true:false}" @click.stop="cycleItemBtn(item,index)">{{item.title}}</div>
                    </div>
                  </div>
              </div>
              <div class="search2">
                  <div class="create">显示激活码：</div>
                  <div class="createInput">{{activation}}</div>
              </div>
              <div class="btn">
                  <div class="sure"  @click="closeBtn">确定</div>
                  <div class="createBtn" @click="addCodeBtn">生成激活码</div>
              </div>
          </div>
      </div>
      <delete-bar @cancelBtn="cancelBtn" @sureBtn="sureBtn" :deleteContent="deleteContent" v-if="deleteShow"></delete-bar>
  </div>
</template>

<script>
import topBar from '../components/nav.vue'
import leftBar from '../components/left.vue'
import pageBar from '../components/pagebarContainer.vue'
import successBar from '../components/success.vue'
import errorBar from '../components/error.vue'
import deleteBar from '../components/delete.vue'
export default {
    components: {
        topBar, leftBar, pageBar, successBar, errorBar,deleteBar
    },
    filters: {
        formatDate(time) {
            var date = new Date(time);
            var y = date.getFullYear();
            var MM = date.getMonth() + 1;
                MM = MM < 10 ? "0" + MM : MM;
            var d = date.getDate();
                d = d < 10 ? "0" + d : d;
            var h = date.getHours();
                h = h < 10 ? "0" + h : h;
            var m = date.getMinutes();
                m = m < 10 ? "0" + m : m;
            var s = date.getSeconds();
            s = s < 10 ? "0" + s : s;
            return y + "-" + MM + "-" + d + " " + h + ":" + m;
        }
    },
    data(){
        return{
            deleteContent:'您确定要删除吗',
            success:'删除成功',
            error:'删除失败',
            successShow:false,
            errorShow:false,
            subjectList:[],
            subjectItem:-1,
            subjectPopItem:-1,
            subjectName:'',
            subjectBar:false,
            subjectId:'',
            styleLis:[{val:'未使用',id:1},{val:'已使用',id:2},{val:'作废',id:3},{val:'全部',id:4}],
            styleName:'全部',
            selectStyle:3,
            styleBar:false,
            styleId:4,
            popBar:false,
            subjecPopText:'',
            subjectPopId:'',
            subjectPopBar:false,
            optionsList:[],
            cycleBar:false,
            selectcycle:-1,
            cyaleText:'',
            optionsId:'',
            currentPage:1,
            total:30,
            count:7,
            codeList:[],
            activation:'',
            loadingOpacity: 1, //loading 透明度
            codeId:'',
            deleteShow:false,
        }
    },
    methods:{
        nextPage() {
            this.currentPage++;
            if (this.currentPage >= this.pageSize) {
                this.currentPage = this.pageSize;
            }
            this.activationListBtn();
        },
        pageTo(index) {
            this.currentPage = index;
            this.activationListBtn();
        },
        prePage() {
            this.currentPage--;
            if (this.currentPage <= 1) {
                this.currentPage = 1;
            }
            this.activationListBtn();
        },
        jumpNumber(val){
            if(val==''){
                val = 1
            }
            if(val>=Math.ceil(this.total / this.count)){
              val = Math.ceil(this.total / this.count)
          }
          this.currentPage = val/1;
          this.activationListBtn();
      },
        selectSubjectBtn(){
            this.subjectBar = !this.subjectBar;
        },
        subjectItemBtn(item,index){
            this.subjectName = item.title;
            this.subjectItem = index;
            this.subjectId = item.id;
            this.subjectBar = false;
            this.activationListBtn();
        },
        //弹窗选择题库
        selectSubjectPopBtn(){
            this.subjectPopBar = !this.subjectPopBar;
        },
        subjectItemPopBtn(item,index){
            this.subjecPopText = item.title;
            this.subjectPopItem = index;
            this.subjectPopId = item.id;
            this.subjectPopBar = false;
            console.log(this.subjectList);
            //获取库信息
            var param = new FormData();
            param.append('subject_id', this.subjectPopId);
            this.$.sunjectData(param).then(res=>{
                console.log(res,'库信息');
                this.optionsList = res.subjectInfo.options;
            })
        },
        noSubject(){
            this.subjectName = '';
            this.subjectId = '';
            this.subjectItem = -1;
            this.activationListBtn();
        },
        selectStyleBtn(){
            this.styleBar = !this.styleBar;
        },
        styleItemBtn(item,index){
            this.selectStyle = index;
            this.styleName = item.val;
            this.styleId = item.id;
            this.styleBar = false;
            this.activationListBtn();
        },
        closeBtn(){
            this.popBar = false;
            this.cyaleText = '';
            this.optionsId = '';
            this.subjecPopText = '';
            this.subjectPopId = '';
            this.activation = '';
            this.activationListBtn();
        },
        establish(){
            this.popBar = true;
        },
        //弹窗
        noSubjectPopBtn(){
            this.subjectPopItem = -1;
            this.subjecPopText = '';
        },
        //周期
        cycleItemBtn(item,index){
            this.cyaleText = item.title;
            this.selectcycle = index;
            this.optionsId = item.id;
            this.cycleBar = false;
            console.log(this.optionsId,this.subjectPopId)
        },
        cycleBtn(){
            this.cycleBar = !this.cycleBar;

        },
        //获取激活码列表
        activationListBtn(){
            var self = this;
            var param = new FormData();
            param.append('status',this.styleId);
            param.append('subject_id',this.subjectId);
            param.append('page',this.currentPage);
            param.append('count',this.count);
            this.$.activationList(param).then(res=>{
				
                this.loadingOpacity = 1;
                console.log(res,'jihuoma')
                this.codeList = res.codeInfo.data;
                this.total = res.codeInfo.total;
                var value = 100;
                var timer = setInterval(function() {
                    value -= 10;
                    self.loadingOpacity = value / 100;
                    if (value <= 0) {
                    clearInterval(timer);
                    }
                }, 10);
                // console.log(this.codeList,this.total)
            })
        },
        //生成激活码
        addCodeBtn(){
            var param = new FormData();
            param.append('subject_id',this.subjectPopId);
            param.append('option_id',this.optionsId);
            this.$.addActivation(param).then(res=>{
                console.log(res,'生成激活码')
                this.activation = res.codeInfo.code;
            })
        },
        //删除激活码
        deleteCode(item){
            this.codeId = item.id;
            this.deleteShow = true;
            this.deleteContent = '您确定要删除吗？';
        },
        //取消删除
        cancelBtn(){
            this.deleteShow = false;
        },
        //确认删除
        sureBtn(){
            this.deleteShow = false;
            var formData = new FormData();
            formData.append('id',this.codeId);
            this.$.deleteActivation(formData).then(res=>{
                console.log(res);
                if(res.code==200 && res.msg == 'ok'){
                    this.success ='删除成功';
                    this.successShow = true;
                    var self = this;
                        this.successShow= true;
                        var time1= setTimeout(function(){
                            self.successShow = false;
                            clearTimeout(time1)
                            self.activationListBtn();
                        },2000);
                }else{
                        var self = this;
                        this.errorShow = true;
                        var time2= setTimeout(function(){
                            self.erroeShow = false;
                            clearTimeout(time2)
                            self.activationListBtn();
                        },2000);
                    }
            })

        }
    },
    mounted(){
        //获取题库列表
        var param = new FormData();
        param.append('page',1);
        param.append('count',10);
        this.$.subjectList(param).then(res=>{
        console.log(res,'库信息')
        var total = res.subjectInfo.total;
        console.log(total)
        var formData = new FormData();
        formData.append('page',1);
        formData.append('count',total);
        this.$.subjectList(formData).then(res=>{
            console.log(res,'kuliebaio ')
            this.subjectList = res.subjectInfo.data;
            
        })
        })
        //获取激活码列表
        this.activationListBtn()
    }
}
</script>

<style lang="less" scoped>
    .codeAdmin{
        width: 100%;
        height: 100vh;
        background-color: #f2f2f2;
        .codeContent{
            width: 100%;
            // height: 100vh;
            margin-top: 60px;
            background-color: #F2F2F2;
            display: flex;
            .codeRight{
                flex: 1;
                height: 100%;
                margin-left: 195px;
                .rightContent{
                    width: 1570px;
                    height: 100%;
                    margin: auto;
                    p {
                        font-size: 20px;
                        color: #333;
                        text-align: left;
                        margin-top: 30px;
                        margin-bottom: 20px;
                    }
                    .centerContent{
                        width: 1570px;
                        height: 902px;
                        background-color: #ffff;
                        border-radius: 10px;
                        margin-top: 14px;
                        position: relative;
                        overflow: hidden;
                        .contentTitle{
                            width: 1490px;
                            height: 40px;
                            margin: 34px auto;
                            .selectSubject{
                                width: 260px;
                                height: 40px;
                                float: left;
                                border: 1px solid #ccc;
                                border-radius: 2px;
                                position: relative;
                                font-size: 18px;
                                color: #333;
                                text-align: left;
                                text-indent: 20px;
                                line-height: 40px;
                                cursor: pointer;
                                span{
                                    color: #ccc;
                                }
                                .arrow{
                                    position: absolute;
                                    width: 15px;
                                    height: 8px;
                                    background-image: url('../assets/img/home/botArrow.png');
                                    background-repeat: no-repeat;
                                    background-size: 100% 100% ;
                                    top: 16px;
                                    right: 20px;
                                }
                                .subjectBar{
                                    width: 100%;
                                    height: 350px;
                                    background-color: #fff;
                                    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
                                    position: absolute;
                                    top: 40px;
                                    border-radius: 2px;
                                    overflow: auto;
                                    .noSubject{
                                        width: 100%;
                                        height: 50px;
                                        font-size: 16px;
                                        color: #999;
                                        text-align: left;
                                        text-indent: 25px;
                                        line-height: 50px;
                                    }
                                    .selectsubject{
                                        background-color: #F15764;
                                        color: #fff;
                                        
                                    }
                                    .barItem{
                                        width: 100%;
                                        height: 50px;
                                        overflow: hidden;
                                        cursor: pointer;
                                        .subjectTitle{
                                            height: 20px;
                                            line-height: 20px;
                                            font-size: 16px;
                                            text-align: left;
                                            text-indent: 25px;
                                            margin-top: 8px;
                                        }
                                        .subjectDetails{
                                            height: 20px;
                                            line-height: 20px;
                                            font-size: 12px;
                                            text-align: left;
                                            text-indent: 25px;
                                            // margin-top: 6px;
                                        }
                                    }
                                    .selectsubject{
                                        background-color: #F15764;
                                        .subjectTitle{
                                            color: #fff;
                                        }
                                        .subjectDetails{
                                            color: #fff;
                                        }
                                        .subjectBar{
                                            color: #fff;
                                        }
                                    }
                                }
                                ::-webkit-scrollbar{
                                    width: 0;
                                }
                            }
                            .selectStyle{
                                width: 180px;
                                height: 40px;
                                float: left;
                                border: 1px solid #ccc;
                                border-radius: 2px;
                                position: relative;
                                margin-left: 60px;
                                font-size: 16px;
                                color: #333;
                                line-height: 40px;
                                cursor: pointer;
                                .arrow{
                                    position: absolute;
                                    width: 15px;
                                    height: 8px;
                                    background-image: url('../assets/img/home/botArrow.png');
                                    background-repeat: no-repeat;
                                    background-size: 100% 100% ;
                                    top: 16px;
                                    right: 20px;
                                }
                                .styleBar{
                                    width: 100%;
                                    height: 148px;
                                    background-color: #fff;
                                    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
                                    position: absolute;
                                    top: 40px;
                                    border-radius: 2px;
                                    overflow: auto;
                                    .styleItem{
                                        width: 100%;
                                        height: 36px;
                                        font-size: 16px;
                                        color: #333;
                                        cursor: pointer;
                                    }
                                    .selectStyleItem{
                                        background-color: #F15764;
                                        color: #fff;
                                    }
                                }
                            }
                            .establish{
                                width: 132px;
                                height: 40px;
                                background-color: #F15764;
                                border-radius: 4px;
                                float: right;
                                font-size: 18px;
                                color: #fff;
                                line-height: 40px;
                                cursor: pointer;
                            }
                        }
                        .listTitle{
                            width: 1490px;
                            height: 60px;
                            background-color: #f2f2f2;
                            margin-top: 32px;
                            margin: auto;
                            .ID{
                               font-size: 16px;
                                color: #333;
                                float: left;
                                margin-left: 26px;
                                line-height: 60px;
                            }
                            .titleCode{
                                font-size: 16px;
                                color: #333;
                                float: left;
                                margin-left: 130px;
                                line-height: 60px;
                            }
                            .titleName{
                                font-size: 16px;
                                color: #333;
                                float: left;
                                margin-left: 177px;
                                line-height: 60px;
                            }
                            .titleTime{
                                font-size: 16px;
                                color: #333;
                                float: left;
                                margin-left: 260px;
                                line-height: 60px;
                            }
                            // .titleStop{
                            //     font-size: 16px;
                            //     color: #333;
                            //     float: left;
                            //     margin-left: 221px;
                            //     line-height: 60px;
                            // }
                            .titleStyle{
                                font-size: 16px;
                                color: #333;
                                float: left;
                                margin-left: 180px;
                                line-height: 60px;
                            }
                            .titleCycle{
                                font-size: 16px;
                                color: #333;
                                float: left;
                                margin-left: 150px;
                                line-height: 60px;
                            }
                            .operate{
                                font-size: 16px;
                                color: #333;
                                float: right;
                                margin-right: 98px;
                                line-height: 60px;
                            }
                        }
                        .listItem{
                            width: 1490px;
                            height: 66px;
                            margin: 12px auto;
                            .itemId{
                                width: 62px;
                                height: 100%;
                                float: left;
                                font-size: 16px;
                                color: #333;
                                line-height: 66px;
                            }
                            .itemCode{
                                width: 92px;
                                height: 100%;
                                float: left;
                                margin-left: 87px;
                                font-size: 16px;
                                color: #333;
                                line-height: 66px;
                            }
                            .itemName{
                                width: 190px;
                                height: 100%;
                                float: left;
                                margin-left: 155px;
                                // font-size: 16px;
                                // color: #333;
                                // line-height: 66px;
                                // text-align: left;
                                // text-overflow: ellipsis;
                                // overflow: hidden;
                                // white-space: nowrap;
                                .subjectTitle{
                                    font-size: 16px;
                                    color: #333;
                                    line-height:33px;
                                    text-align: left;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                }
                                .description{
                                    font-size: 16px;
                                    color: #333;
                                    line-height:33px;
                                    text-align: left;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                }
                            }
                            .itemTime{
                                width: 210px;
                                height: 100%;
                                float: left;
                                margin-left: 65px;
                                font-size: 16px;
                                color: #333;
                                line-height: 66px;
                            }
                            .itemStop{
                                width: 210px;
                                height: 100%;
                                float: left;
                                margin-left: 80px;
                                font-size: 16px;
                                color: #333;
                                line-height: 66px;
                            }
                            .itemStyle1{
                                width: 84px;
                                height: 30px;
                                margin-top: 18px;
                                float: left;
                                background-color: #D8FAF5;
                                color: #33998F;
                                margin-left: 95px;
                                border-radius: 15px;
                                font-size: 16px;
                                line-height: 30px;
                            }
                            .itemStyle2{
                                width: 84px;
                                height: 30px;
                                margin-top: 18px;
                                float: left;
                                background-color: #FEEDEF;
                                color: #993333;
                                margin-left: 95px;
                                border-radius: 15px;
                                font-size: 16px;
                                line-height: 30px;
                            }
                            .itemStyle3{
                                width: 84px;
                                height: 30px;
                                margin-top: 18px;
                                float: left;
                                background-color: #FAD8D8;
                                color: #FF0000;
                                margin-left: 95px;
                                border-radius: 15px;
                                font-size: 16px;
                                line-height: 30px;
                            }
                            .itemCycle{
                                width: 80px;
                                height: 100%;
                                float: left;
                                margin-left: 118px;
                                font-size: 16px;
                                color: #333;
                                line-height: 66px;
                            }
                            .delete{
                                width: 18px;
                                height: 100%;
                                float: right;
                                margin-right: 98px;
                                background-image: url('../assets/img/home/delete.png');
                                background-repeat: no-repeat;
                                background-size: 100% 20px;
                                background-position: center;
                                cursor: pointer;
                            }
                        }
                        .loadingPanel {
                            position        : absolute;
                            width           : 100%;
                            height          : 709px;
                            background-color: white;
                            z-index         : 999;
                            pointer-events  : none;
                            display         : flex;
                            flex-wrap       : nowrap;
                            flex-direction  : column;
                            justify-content : center;
                            align-items     : center;
                            z-index         : 999;
                            pointer-events  : auto;
                            left            : 0;
                            top: 108px;

                            img {
                                width: .6rem;

                            }
                        }
                    }
                }
            }
        }
        .popBar{
            width: 100%;
            height: 100%;
            background-color: rgba(51, 51, 51, .3);
            position: fixed;
            top: 0;
            left: 0;
            z-index: 9999;
            .establishPop{
                width: 880px;
                height: 356px;
                background-color: #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                border-radius: 4px;
                .title{
                    width: 100%;
                    height: 50px;
                    border-bottom: 1px solid #ccc;
                    .titleText{
                        font-size: 20px;
                        color: #999;
                        float: left;
                        margin-left: 16px;
                        line-height: 50px;
                    }
                    .close{
                        width: 10px;
                        height: 100%;
                        float: right;
                        margin-right: 30px;
                        background-image: url('../assets/img/home/close.png');
                        background-repeat: no-repeat;
                        background-size: 100% 10px;
                        background-position: center;
                        cursor: pointer;
                    }
                }
                .search1{
                    width: 100%;
                    height: 44px;
                    margin-top: 29px;
                    // background-color: red;
                    .searchSubjectText{
                        font-size: 16px;
                        color: #999;
                        float: left;
                        margin-left: 55px;
                        line-height: 44px;
                    }
                    .searchSubjectInput{
                        width: 376px;
                        height: 44px;
                        border: 1px solid #ccc;
                        box-sizing: border-box;
                        float: left;
                        margin-left: 10px;
                        position: relative;
                        cursor: pointer;
                        font-size: 16px;
                        text-align: left;
                        line-height: 44px;
                        text-indent: 16px;
                        span{
                            color: #999;
                        }
                        .arrow{
                            position: absolute;
                            width: 15px;
                            height: 8px;
                            background-image: url('../assets/img/home/botArrow.png');
                            background-repeat: no-repeat;
                            background-size: 100% 100% ;
                            top: 16px;
                            right: 20px;
                        }
                        .subjectBar{
                            width: 100%;
                            height: 200px;
                            z-index: 2;
                            background-color: #fff;
                            position: absolute;
                            top: 44px;
                            left: 0;
                            box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
                            overflow: auto;
                            .noSubject{
                                width: 100%;
                                height: 50px;
                                font-size: 16px;
                                color: #999;
                                text-align: left;
                                text-indent: 25px;
                                line-height: 50px;
                            }
                            .selectsubject{
                                background-color: #F15764;
                                color: #fff;
                                
                            }
                            .barItem{
                                width: 100%;
                                height: 50px;
                                overflow: hidden;
                                cursor: pointer;
                                .subjectTitle{
                                    width: 100%;
                                    height: 20px;
                                    line-height: 20px;
                                    font-size: 16px;
                                    text-align: left;
                                    text-indent: 25px;
                                    margin-top: 8px;
                                }
                                .subjectDetails{
                                    width: 100%;
                                    height: 20px;
                                    line-height: 20px;
                                    font-size: 12px;
                                    text-align: left;
                                    text-indent: 25px;
                                    // margin-top: 6px;
                                }
                            }
                            .selectsubject{
                                background-color: #F15764;
                                .subjectTitle{
                                    color: #fff;
                                }
                                .subjectDetails{
                                    color: #fff;
                                }
                            }
                        }
                        ::-webkit-scrollbar{
                            width: 0;
                        }
                    }
                    .selectTimeText{
                        font-size: 16px;
                        color: #999;
                        float: left;
                        margin-left: 30px;
                        line-height: 44px;
                    }
                    .cycleInput{
                        width: 200px;
                        height: 44px;
                        border: 1px solid #ccc;
                        box-sizing: border-box;
                        float: left;
                        margin-left: 10px;
                        position: relative;
                        cursor: pointer;
                        font-size: 16px;
                        text-align: left;
                        line-height: 44px;
                        text-indent: 16px;
                        color: #333;
                        span{
                            color: #999;
                        }
                        .arrow{
                            position: absolute;
                            width: 15px;
                            height: 8px;
                            background-image: url('../assets/img/home/botArrow.png');
                            background-repeat: no-repeat;
                            background-size: 100% 100% ;
                            top: 16px;
                            right: 20px;
                        }
                        .cycleBar{
                            width: 100%;
                            max-height: 144px;
                            background-color: #fff;
                            box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
                            position: absolute;
                            top: 40px;
                            left: 0;
                            overflow: auto;
                            .cycleItem{
                                width: 100%;
                                height: 36px;
                                font-size: 16px;
                                color: #333;
                                line-height: 36px;
                                cursor: pointer;
                            }
                            .selectcycle{
                                background-color: #F15764;
                                color: #fff;
                            }
                        }
                        ::-webkit-scrollbar{
                            width: 0;
                        }
                    }
                }
                .search2{
                    width: 100%;
                    height: 44px;
                    margin-top: 30px;
                    // background-color: red;
                    .create{
                        font-size: 16px;
                        color: #999;
                        float: left;
                        margin-left: 39px;
                        line-height: 44px;
                    }
                    .createInput{
                        width: 376px;
                        height: 44px;
                        float: left;
                        margin-left: 10px;
                        background-color: #f2f2f2;
                        border-radius: 2px;
                        font-size: 16px;
                        color: #333;
                        text-align: left;
                        text-indent: 16px;
                        line-height: 44px;
                    }
                }
                .btn{
                    width: 300px;
                    height: 46px;
                    margin: 70px auto 0;
                    // background-color: red;
                    .sure{
                        width: 138px;
                        height: 100%;
                        border: 1px solid #F15764;
                        color: #F15764;
                        line-height: 46px;
                        font-size: 16px;
                        box-sizing: border-box;
                        float: left;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                    .createBtn{
                        width: 138px;
                        height: 100%;
                        background-color:#F15764;
                        color: #fff;
                        line-height: 46px;
                        font-size: 16px;
                        box-sizing: border-box;
                        float: right;
                        border-radius: 4px;
                    }
                }
            }
        }
    }
</style>