<template>
  <div class="chirteAdmin">
       <top-bar></top-bar>
      <div class="subjectContetn">
          <left-bar></left-bar>
          <div class="contentRight">
              <div class="center">
                <p><span @click="returnbtn">题库管理></span>章管理</p>
                <div class="content">
                    <div class="searchTitle">
                        <div class="selectSubject" @click="selectBtn">
                            <div class="nodate">{{subjectTitle}}</div>
                            <div class="botArrow"></div>
                            <div class="selectBar" v-show="selectBarShow">
                                <div class="setItem" v-for="(item,index) in subjectList" :key="index" 
                                            :class="{ActiveItem:activeItem==index?true:false}" @click="selectSubjectBtn(item,index)">
                                    <div class="selectName">{{item.title}}</div>
                                    <div class="selectDetalis">{{item.description}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="searchInput">
                            <input type="text" placeholder="快速搜索章" v-model="searchChapter" @keyup.enter="searchBtn">
                            <div class="searchImg" @click="searchBtn"></div>
                        </div>
                        <div class="addSubject" @click="addSubjectBtn">添加章</div>
                    </div>
                    <div class="listTitle">
                        <div class="titleName">题库名称</div>
                        <!-- <div class="titleNumber">购买数量</div> -->
                        <div class="titlePrice">价格</div>
                        <div class="titleStyle">类型</div>
                        <div class="titleTime">更新时间</div>
                        <div class="titleTotal">题总数</div>
                        <div class="titleOperate">操作</div>
                    </div>
                    <div class="listItem" v-for="(item,index) in chapterList" :key="index">
                        <div class="name">
                            <!-- <div class="nameImg"></div> -->
                            <div class="nameDetalis">
                                <div class="nick_name">{{item.title}}</div>
                                <div class="detalis">{{item.description}}</div>
                            </div>
                        </div>
                        <!-- <div class="number">1000</div> -->
                        <div class="price">{{item.is_free==1?'免费':'付费'}}</div>
                        <div class="style" v-if="item.type==1?true:false">习题</div>
                        <div class="style" v-if="item.type==2?true:false">真题</div>
                        <div class="style" v-if="item.type==3?true:false">模拟</div>
                        <div class="time">{{item.updated_at | formatDate }}</div>
                        <div class="total">{{item.number}}</div>
                        <div class="operate">
                            <div class="edit" @click="editChapterBtn(item)">编辑</div>
                            <!-- <div class="lower">下架</div> -->
                            <div class="lower" @click="deleteChapterBtn(item)">删除</div>
                        </div>
                    </div>
                    <page-bar :currentPage="currentPage" :total="total" :count="count" @nextPage="nextPage" @prePage="prePage" @jumpNumber="jumpNumber" @pageTo="pageTo"></page-bar>
                    <div class="loadingPanel" :style="{'opacity':loadingOpacity}" v-show="loadingOpacity<=0?false:true">
                        <img src="../assets/img/home/onLoad.gif" />
                    </div>
                </div>
              </div>
              <success-bar :tipsText="success" v-if="successShow"></success-bar>
              <error-bar :tipsText="error" v-if="errorShow"></error-bar>
          </div>
      </div>
      <delete-bar @cancelBtn="cancelBtn" @sureBtn="sureBtn" :deleteContent="deleteContent" v-if="deleteShow"></delete-bar>
  </div>
</template>

<script>
import topBar from '../components/nav.vue'
import leftBar from '../components/left.vue'
import pageBar from '../components/pagebarContainer.vue'
import successBar from '../components/success.vue'
import errorBar from '../components/error.vue'
import deleteBar from '../components/delete.vue'
export default {
    components:{
        topBar,leftBar,pageBar,successBar,errorBar,deleteBar
    },
    filters: {
        formatDate(time) {
            var date = new Date(time);
            var y = date.getFullYear();
            var MM = date.getMonth() + 1;
                MM = MM < 10 ? "0" + MM : MM;
            var d = date.getDate();
                d = d < 10 ? "0" + d : d;
            var h = date.getHours();
                h = h < 10 ? "0" + h : h;
            var m = date.getMinutes();
                m = m < 10 ? "0" + m : m;
            // var s = date.getSeconds();
            // s = s < 10 ? "0" + s : s;
            return y + "-" + MM + "-" + d + " " + h + ":" + m;
        }
    },
    data(){
        return{
            deleteContent:'您确定要删除吗',
            deleteShow:false,
            successShow:false,
            errorShow:false,
            success:'删除成功',
            error:'删除失败',
            activeItem:0,
            selectBarShow:false,
            currentPage:1,
            total:30,
            count:7,
            subjectList:[],
            subjectTitle:'',
            subjectId:'',
            chapterList:[],
            chapterId:'',
            searchChapter:'',
            loadingOpacity: 1, //loading 透明度
            
        }
    },
    methods:{
        returnbtn(){
            this.$router.push({path:'/subjectAdmin',query:{leftId:2}})
        },
        selectBtn(){
            this.selectBarShow = !this.selectBarShow 
        },
        addSubjectBtn(){
            this.$router.push({path:'/addChapter',query:{leftId:2,barId:2,subjectId:this.subjectId,subjectTitle:this.subjectTitle}})
        },
        nextPage() {
            this.currentPage++;
            if (this.currentPage >= this.pageSize) {
                this.currentPage = this.pageSize;
                
            }
            this.chapterListBtn()
        },
        pageTo(index) {
            this.currentPage = index;
            this.chapterListBtn()
        },
        prePage() {
            this.currentPage--;
            if (this.currentPage <= 1) {
                this.currentPage = 1;
            }
            this.chapterListBtn()
        },
        jumpNumber(val){
            if(val==''){
                val = 1
            }
            if(val>=Math.ceil(this.total / this.count)){
                val = Math.ceil(this.total / this.count)
            }
            this.currentPage = val/1;
            this.chapterListBtn()
        },
        editChapterBtn(item){
            this.$router.push({path:'editChapter',query:{leftId:2,barId:2,subjectId:item.subject_id,chapterId:item.id}})
        },
        //题库列表
        subjectListBtn(){
            var param =new FormData();
            var subjectAll = localStorage.getItem('subjectAll');
            param.append('page',1);
            param.append('count',subjectAll);
            this.$.subjectList(param).then(res=>{
                this.subjectList = res.subjectInfo.data;
                // this.subjectTitle = this.subjectList[0].title;
                // this.subjectId = this.subjectList[0].id;
                this.chapterListBtn();
            })
        },
        
        //选择题库
        selectSubjectBtn(item,index){
            // this.activeItem = index;
            localStorage.setItem('subjectTitle',item.title);
            localStorage.setItem('subjectId',item.id);
            localStorage.setItem('activeItem',index);
            this.subjectTitle = localStorage.getItem('subjectTitle');
            this.subjectId = localStorage.getItem('subjectId');
            this.activeItem = localStorage.getItem('activeItem');
            this.chapterListBtn();
            
        },
        //章列表
        chapterListBtn(){
            var self  = this
            var param = new FormData();
            param.append('subject_id',this.subjectId);
            param.append('page',this.currentPage);
            param.append('count',this.count);
            param.append('query',this.searchChapter);
            this.$.chapterList(param).then(res=>{
                this.loadingOpacity = 1;
                console.log(res,'=====================章 列表')
                this.total = res.chapterInfo.total;
                this.chapterList = res.chapterInfo.data;
                var value = 100;
                var timer = setInterval(function() {
                    value -= 10;
                    self.loadingOpacity = value / 100;
                    if (value <= 0) {
                    clearInterval(timer);
                    }
                }, 10);
 
            })
        },
        //搜索
        searchBtn(){
            this.chapterListBtn();
        },
        //删除章
        deleteChapterBtn(item){
            this.chapterId = item.id;
            this.deleteShow = true;
            this.deleteContent = '如果删除，将会删除该章下的所有内容，确定执行吗？'
        },
        cancelBtn(){
            this.deleteShow = false;
        },
        sureBtn(){
            
            this.deleteShow = false;
            var param = new FormData();
            param.append('id',this.chapterId)
            this.$.deleteChapter(param).then(res=>{
                console.log(res,'删除章')
                localStorage.removeItem('subjectTitle');
                localStorage.removeItem('subjectId');
                localStorage.removeItem('activeItem');
                this.chapterListBtn();
            })
        }
    },
    mounted(){
        this.subjectTitle = localStorage.getItem('subjectTitle');
        this.subjectId = localStorage.getItem('subjectId');
        this.activeItem = localStorage.getItem('activeItem');
        // 库列表
        this.subjectListBtn();
        
       
    }
}
</script>

<style lang='less' scoped>
    .chirteAdmin{
        width: 100%;
        height: 1080px;
        background-color: #F2F2F2;
        .subjectContetn{
            width: 100%;
            height: 100vh;
            margin-top: 60px;
            background-color: #F2F2F2;
            display: flex;
            .contentRight{
                flex: 1;
                margin-left: 195px;
                height: 1000px;
                .center{
                    width: 1570px;
                    height: 100%;
                    margin: auto;
                    
                    p{
                        font-size: 20px;
                        color: #333;
                        text-align: left;
                        margin-top: 24px;
                        margin-bottom: 20px;
                        cursor: pointer;
                        span{
                            color: #ccc;
                        }
                    }
                    .content{
                        width: 100%;
                        height: 902px;
                        background-color: #fff;
                        border-radius: 10px;
                        position: relative;
                        .searchTitle{
                            width: 1490px;
                            height: 107px;
                            border-bottom: 1px solid #ccc;
                            margin: auto;
                            // overflow: hidden;
                            .selectSubject{
                                width: 181px;
                                height: 44px;
                                border: 1px solid #ccc;
                                box-sizing: border-box;
                                border-radius: 4px;
                                float: left;
                                margin-right: 46px;
                                margin-top: 28px;
                                position: relative;
                                cursor: pointer;
                                .botArrow{
                                    width: 15px;
                                    height: 100%;
                                    position: absolute;
                                    right: 15px;
                                    top: 0;
                                    background-image: url('../assets/img/home/botArrow.png');
                                    background-repeat: no-repeat;
                                    background-size: 100% 9px;
                                    background-position: center;
                                }
                                .nodate{
                                    font-size: 14px;
                                    color: #333;
                                    line-height: 44px;
                                }
                                .selectBar{
                                    position: absolute;
                                    top: 50px;
                                    width: 289px;
                                    height: 375px;
                                    left: 0;
                                    z-index: 99999;
                                    background-color: #fff;
                                    border-radius: 10px;
                                    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
                                    overflow:auto;
                                    .setItem{
                                        width: 100%;
                                        height: 57px;
                                        margin-top:8px;
                                        overflow: hidden;
                                        .selectName{
                                            width: 100%;
                                            font-size: 14px;
                                            color: #333;
                                            margin-top: 7px;
                                            text-align: left;
                                            text-indent: 25px;
                                            text-overflow: ellipsis;
                                            overflow: hidden;
                                            white-space: nowrap;
                                        }
                                        .selectDetalis{
                                            width: 100%;
                                            font-size: .12rem;
                                            color: #999;
                                            margin-top: 6px;
                                            text-align: left;
                                            text-indent: 25px;
                                            text-overflow: ellipsis;
                                            overflow: hidden;
                                            white-space: nowrap;
                                        }
                                    }
                                    .ActiveItem{
                                        background-color: rgba(241, 241, 241, .5);
                                    }
                                }
                                ::-webkit-scrollbar{
                                    width: 2px;
                                    background-color: transparent;
                                }
                                ::-webkit-scrollbar-thumb{
                                    background-color: #CCCCCC;
                                    border-radius: .1rem;
                                }
                            }
                            .searchInput{
                                width: 387px;
                                height:44px;
                                margin-top: 28px;
                                position: relative;
                                float: left;
                                input{
                                    width: 100%;
                                    height: 100%;
                                    border: 1px solid #ccc;
                                    box-sizing: border-box;
                                    border-radius: 4px;
                                    float: left;
                                    outline:none;
                                    font-size: 14px;
                                    text-indent: 22px;
                                    line-height: 44px;
                                }
                                .searchImg{
                                    width: 24px;
                                    height: 100%;
                                    position: absolute;
                                    right: 21px;
                                    // background-color: #333;
                                    cursor: pointer;
                                    background-image: url('../assets/img/home/search.png');
                                    background-repeat: no-repeat;
                                    background-size: .24rem .24rem;
                                    background-position: center;
                                }
                            }
                            .addSubject{
                                width: 120px;
                                height: 38px;
                                border: 1px solid #F15764;
                                border-radius: 4px;
                                margin-top: 34px;
                                box-sizing: border-box;
                                font-size: 14px;
                                color: #F15764;
                                line-height: 38px;
                                float: right;
                                cursor: pointer;
                            }
                        }
                        .listTitle{
                            width: 1490px;
                            height: 60px;
                            background-color: #F2F2F2;
                            margin: 36px auto 30px;
                            .titleName{
                                font-size: 14px;
                                float: left;
                                margin-left:82px;
                                line-height: 60px;
                                color: #333;
                            }
                            // .titleNumber{
                            //     font-size: 14px;
                            //     float: left;
                            //     margin-left: 318px;
                            //     line-height: 60px;
                            //     color: #333;
                            // }
                            .titlePrice{
                                font-size: 14px;
                                float: left;
                                margin-left: 235px;
                                line-height: 60px;
                                color: #333;
                            }
                            .titleStyle{
                                font-size: 14px;
                                float: left;
                                margin-left: 186px;
                                line-height: 60px;
                                color: #333;
                            }
                            .titleTime{
                                font-size: 14px;
                                float: left;
                                margin-left: 211px;
                                line-height: 60px;
                                color: #333;
                            }
                            .titleTotal{
                                font-size: 14px;
                                float: left;
                                margin-left: 210px;
                                line-height: 60px;
                                color: #333;
                            }
                            .titleOperate{
                                font-size: 14px;
                                float: left;
                                margin-left: 206px;
                                line-height: 60px;
                                color: #333;
                            }
                        }
                        .listItem{
                            width: 1490px;
                            height: 70px;
                            margin: 10px auto ;
                            // background-color: #333;
                            .name{
                                width: 168px;
                                height: 100%;
                                margin-left: 34px;
                                float: left;
                                // .nameImg{
                                //     width: 160px;
                                //     height: 100%;
                                //     background-color: aquamarine;
                                //     float: left;
                                //     // margin-right: .32px;
                                // }
                                .nameDetalis{
                                    width: 168px;
                                    height: 100%;
                                    float: left;
                                    .nick_name{
                                        width: 100%;
                                        font-size: 14px;
                                        color: #333;
                                        text-align: left;
                                        margin-top: 20px;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        /* white-space: nowrap; */
									word-break: break-all;
									line-height: 16px;
                                    }
                                    .detalis{
                                        width: 100%;
                                        font-size: 12px;
                                        color: #999999;
                                        margin-top: 15px;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-align: left;
                                    }
                                }
                            }
                            .price{
                                width: 173px;
                                height: 100%;
                                float: left;
                                font-size: 14px;
                                color: #FF0000;
                                line-height: 60px;
                                float: left;
                                margin-left: 100px;
                            }
                            .style{
                                width: 54px;
                                height: 100%;
                                float: left;
                                font-size: 14px;
                                color: #333333;
                                line-height: 70px;
                                float: left;
                                margin-left: 102px;
                            }
                            .time{
                                width: 152px;
                                height: 100%;
                                float: left;
                                font-size: 14px;
                                color: #333;
                                line-height: 70px;
                                margin-left: 150px;
                            }
                            .total{
                                width: 40px;
                                height: 100%;
                                font-size: 14px;
                                color: #333;
                                line-height: 70px;
                                float: left;
                                margin-left: 160px;
                            }
                            .operate{
                                width: 124px;
                                height: 100%;
                                float: right;
                                margin-right: 56px;
                                display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                justify-content: space-around;
                                .edit, .lower, .lower{
                                    font-size: 14px;
                                    color: #5DB9FF;
                                    line-height: 70px;
                                    cursor: pointer;
                                }
                                
                            }
                        }
                        .loadingPanel {
                            position        : absolute;
                            width           : 100%;
                            height          : 709px;
                            background-color: white;
                            z-index         : 999;
                            pointer-events  : none;
                            display         : flex;
                            flex-wrap       : nowrap;
                            flex-direction  : column;
                            justify-content : center;
                            align-items     : center;
                            z-index         : 999;
                            pointer-events  : auto;
                            left            : 0;
                            top: 108px;

                            img {
                                width: .6rem;

                            }
                        }
                    }
                }
                
            }
        }
    }
</style>