import { render, staticRenderFns } from "./ErrorFeedback.vue?vue&type=template&id=0372a285&scoped=true&"
import script from "./ErrorFeedback.vue?vue&type=script&lang=js&"
export * from "./ErrorFeedback.vue?vue&type=script&lang=js&"
import style0 from "./ErrorFeedback.vue?vue&type=style&index=0&id=0372a285&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0372a285",
  null
  
)

export default component.exports