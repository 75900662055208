<template>
  <div class="subjectAdmin">
      <top-bar></top-bar>
      <div class="subjectContetn">
          <left-bar></left-bar>
          <div class="contentRight">
              <div class="center">
                <p>库管理</p>
                <div class="content">
                    <div class="searchTitle">
                        <div class="searchInput">
                            <input type="text" placeholder="快速搜索题库" v-model="searchSubject" @keyup.enter="searchBtn">
                            <div class="searchImg" @click="searchBtn"></div>
                        </div>
                        <div class="addSubject" @click="addSubjectBtn">添加题库</div>
                        <div class="updateSubject" @click="updateSubjectBtn">更新题库数量</div>
                    </div>
                    <div class="listTitle">
                        <div class="titleName">题库名称</div>
                        <div class="titleNumber">购买数量</div>
                        <div class="titlePrice">价格</div>
                        <div class="titleStyle">状态</div>
                        <div class="titleTime">更新时间</div>
                        <div class="titleTotal">题总数</div>
                        <div class="titleOperate">操作</div>
                    </div>
                    <div class="listItem" v-for="(item,index) in subjectList" :key="index">
                        <div class="name">
                            <div class="nameImg">
                                <img :src="CDN+item.thumb" alt="">
                            </div>
                            <div class="nameDetalis">
                                <div class="nick_name">{{item.title}}</div>
                                <div class="detalis">{{item.description}}</div>
                            </div>
                        </div>
                        <div class="number">{{item.sales+item.fake_sales}}</div>
                        <div class="price">{{item.price}}元</div>
                        <div class="style">{{item.is_sell==1?'已上架':'未上架'}}</div>
                        <div class="time">{{item.updated_at | formatDate }}</div>
                        <div class="total">{{item.number}}</div>
                        <div class="operate">
                            <div class="edit" @click="subjectEditBtn(item)">编辑</div>
                            <!-- <div class="lower">下架</div> -->
                            <div class="lower" @click="deleteSubjectBtn(item)">删除</div>
                        </div>
                    </div>
                    <page-bar :currentPage="currentPage" :total="total" :count="count" @nextPage="nextPage" @prePage="prePage" @pageTo="pageTo" @jumpNumber="jumpNumber"></page-bar>
                    <div class="loadingPanel" :style="{'opacity':loadingOpacity}" v-show="loadingOpacity<=0?false:true">
                        <img src="../assets/img/home/onLoad.gif" />
                    </div>
               </div>
              </div>
              <success-bar :tipsText="success" v-if="successShow"></success-bar>
              <error-bar :tipsText="error" v-if="errorShow"></error-bar>
          </div>
      </div>
      <delete-bar @cancelBtn="cancelDeleteBtn" @sureBtn="sureDeleteBtn" :deleteContent="deleteContent" v-if="deleteShow"></delete-bar>
      <div class="upDataPop" v-show="upDataPop">
        <div class="upDataContent">
            <div class="tips">提示信息</div>
            <div class="tipsText">更新时间较长，您确定要更新吗？</div>
            <div class="tipsBot">
                <div class="sure" @click="sureBtn">确定</div>
                <div class="cancel" @click="cancelBtn">取消</div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import topBar from '../components/nav.vue'
import leftBar from '../components/left.vue'
import pageBar from '../components/pagebarContainer.vue'
import Config from '../assets/js/img'
import successBar from '../components/success.vue'
import errorBar from '../components/error.vue'
import deleteBar from '../components/delete.vue'
export default {
    filters: {
        formatDate(time) {
            var date = new Date(time);
            var y = date.getFullYear();
            var MM = date.getMonth() + 1;
                MM = MM < 10 ? "0" + MM : MM;
            var d = date.getDate();
                d = d < 10 ? "0" + d : d;
            var h = date.getHours();
                h = h < 10 ? "0" + h : h;
            var m = date.getMinutes();
                m = m < 10 ? "0" + m : m;
            var s = date.getSeconds();
            s = s < 10 ? "0" + s : s;
            return y + "-" + MM + "-" + d + " " + h + ":" + m;
        }
    },
  components:{
    topBar,leftBar,
    pageBar,
    successBar,errorBar,deleteBar
  },
  data(){
      return{
          deleteContent:'您确定要删除吗',
          successShow:false,
          errorShow:false,
          success:'删除成功',
          error:'删除失败',
          currentPage:1,
          total:0,
          count:5,
          subjectList:[],
          CDN:Config.CDN,
          deleteShow:false,
          subjectId:'',
          searchSubject:'',
          upDataPop:false,
          loadingOpacity: 1, //loading 透明度
      }
  },

  methods:{
      nextPage() {
        this.currentPage++;
        if (this.currentPage >= this.pageSize) {
          this.currentPage = this.pageSize;
        }
        this.subjectListBtn();
      },
      pageTo(index) {
        this.currentPage = index;
        this.subjectListBtn();

      },
      prePage() {
        this.currentPage--;
        if (this.currentPage <= 1) {
          this.currentPage = 1;
        }
        this.subjectListBtn();
      },
      jumpNumber(val){
          if(val==''){
                val = 1
            }
            if(val>=Math.ceil(this.total / this.count)){
              val = Math.ceil(this.total / this.count)
          }
          this.currentPage = val/1;
          this.subjectListBtn();
      },
      addSubjectBtn(){
          this.$router.push({path:'/addSubject',query:{leftId:2,barId:1}})
      },
      subjectEditBtn(item){
          this.$router.push({path:'/editSubject',query:{leftId:2,barId:1,subjectId:item.id}})
      },
      deleteSubjectBtn(item){
          this.subjectId = item.id;
          this.deleteShow = true;
          this.deleteContent = '如果删除，将会删除该题库下的所有内容，确定执行吗？';
      },
      sureDeleteBtn(){
          this.deleteShow = false;
          var param = new FormData();
          param.append('id',this.subjectId)
          this.$.deleteSubject(param).then(res=>{
              console.log(res,'删除=-0-------');
             if(res.code==200 && res.msg == 'ok'){
                var self = this;
                    this.successShow= true;
                    var time1= setTimeout(function(){
                        self.successShow = false;
                        clearTimeout(time1)
                            self.$router.push({path:'/subjectAdmin',query:{leftId:2}})
                    },2000);
                }else{
                    this.errorShow = true;
                    var time2= setTimeout(function(){
                        self.errorShow = false;
                        clearTimeout(time2)
                            self.$router.push({path:'/subjectAdmin',query:{leftId:2}})
                    },2000);
                }
                this.subjectListBtn();
          })
      },
      cancelDeleteBtn(){
          this.deleteShow = false;
      },
      //题库列表
      subjectListBtn(){
          var self = this
          var param =new FormData();
        param.append('page',this.currentPage);
        param.append('count',this.count);
        param.append('query',this.searchSubject.trim())
        this.$.subjectList(param).then(res=>{
            this.loadingOpacity = 1;
            console.log(res)
            this.subjectList = res.subjectInfo.data;
            console.log(this.subjectList,'-------------');
            this.total = res.subjectInfo.total;
            localStorage.setItem('subjectAll',this.total);
            var value = 100;
            var timer = setInterval(function() {
                value -= 10;
                self.loadingOpacity = value / 100;
                if (value <= 0) {
                clearInterval(timer);
                }
            }, 10);
        })
      },
      //搜索
      searchBtn(){
          this.subjectListBtn();
      },
      //更新题库数量
      updateSubjectBtn(){
            this.upDataPop = true;
      },
      //确定更新
     sureBtn(){
         this.$.upDataSubject().then(res=>{
             console.log(res,'更新成功')
             this.upDataPop = false;
         })  
     },
     //取消
     cancelBtn(){
         this.upDataPop = false;
     }

  },
  mounted(){
      this.subjectListBtn();
       
      
  }

}
</script>
<style lang='less' scoped>
    .subjectAdmin{
        width: 100%;
        // height: 10.8px;
        background-color: #F2F2F2;
        overflow: hidden;
        .subjectContetn{
            width: 100%;
            height: 100%;
            margin-top: 60px;
            background-color: #F2F2F2;
            display: flex;
            .contentRight{
                flex: 1;
                margin-left: 195px;
                height: 100%;
                .center{
                    width: 1570px;
                    height: 100%;
                    margin: auto;
                    
                    p{
                        font-size: 20px;
                        color: #333;
                        text-align: left;
                        margin-top: 24px;
                        margin-bottom: 20px;
                    }
                    .content{
                        width: 100%;
                        height: 902px;
                        background-color: #fff;
                        border-radius: 10px;
                        position: relative;
                        .searchTitle{
                            width: 1490px;
                            height: 107px;
                            border-bottom: 1px solid #ccc;
                            margin: auto;
                            overflow: hidden;
                            .searchInput{
                                width: 387px;
                                height:44px;
                                margin-top: 28px;
                                position: relative;
                                float: left;
                                input{
                                    width: 100%;
                                    height: 100%;
                                    border: 1px solid #ccc;
                                    box-sizing: border-box;
                                    border-radius: 4px;
                                    float: left;
                                    outline:none;
                                    font-size: 14px;
                                    text-indent: 22px;
                                    line-height: 44px;
                                }
                                .searchImg{
                                    width: 24px;
                                    height: 100%;
                                    position: absolute;
                                    right: 21px;
                                    // background-color: #333;
                                    cursor: pointer;
                                    background-image: url('../assets/img/home/search.png');
                                    background-repeat: no-repeat;
                                    background-size: .24rem .24rem;
                                    background-position: center;
                                }
                            }
                            .addSubject{
                                width: 120px;
                                height: 38px;
                                border: 1px solid #F15764;
                                border-radius: 4px;
                                margin-top: 34px;
                                box-sizing: border-box;
                                font-size: 14px;
                                color: #F15764;
                                line-height: 38px;
                                float: right;
                                cursor: pointer;
                            }
                            .updateSubject{
                                width: 120px;
                                height: 38px;
                                background-color: #F15764;
                                border-radius: 4px;
                                margin-top: 34px;
                                font-size: 14px;
                                color: #fff;
                                line-height: 38px;
                                float: right;
                                margin-right: 20px;
                                cursor: pointer;
                            }
                        }
                        .listTitle{
                            width: 1490px;
                            height: 60px;
                            background-color: #F2F2F2;
                            margin: 36px auto 30px;
                            .titleName{
                                font-size: 14px;
                                float: left;
                                margin-left:82px;
                                line-height: 60px;
                                color: #333;
                            }
                            .titleNumber{
                                font-size: 14px;
                                float: left;
                                margin-left: 318px;
                                line-height: 60px;
                                color: #333;
                            }
                            .titlePrice{
                                font-size: 14px;
                                float: left;
                                margin-left: 117px;
                                line-height: 60px;
                                color: #333;
                            }
                            .titleStyle{
                                font-size: 14px;
                                float: left;
                                margin-left: 136px;
                                line-height: 60px;
                                color: #333;
                            }
                            .titleTime{
                                font-size: 14px;
                                float: left;
                                margin-left: 133px;
                                line-height: 60px;
                                color: #333;
                            }
                            .titleTotal{
                                font-size: 14px;
                                float: left;
                                margin-left: 140px;
                                line-height: 60px;
                                color: #333;
                            }
                            .titleOperate{
                                font-size: 14px;
                                float: left;
                                margin-left: 162px;
                                line-height: 60px;
                                color: #333;
                            }
                        }
                        .listItem{
                            width: 1490px;
                            height: 100px;
                            margin: 20px auto ;
                            // background-color: #333;
                            .name{
                                width: 360px;
                                height: 100%;
                                margin-left: 34px;
                                float: left;
                                .nameImg{
                                    width: 160px;
                                    height: 100%;
                                    background-color: aquamarine;
                                    float: left;
                                    position: relative;
                                    img{
                                        width: 100%;
                                        height: 100%;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                    }
                                }
                                .nameDetalis{
                                    width: 168px;
                                    height: 100%;
                                    float: right;
                                    .nick_name{
                                        width: 100%;
                                        font-size: 14px;
                                        color: #333;
                                        text-align: left;
                                        margin-top: 24px;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        white-space: nowrap;
                                    }
                                    .detalis{
                                        width: 100%;
                                        font-size: 12px;
                                        color: #999999;
                                        margin-top: 20px;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-align: left;
                                    }
                                }
                            }
                            .number{
                                width: 64px;
                                height: 100%;
                                font-size: 14px;
                                color: #333;
                                line-height: 100px;
                                margin-left: 55px;
                                float: left;
                            }
                            .price{
                                width: 66px;
                                height: 100%;
                                float: left;
                                font-size: 14px;
                                color: #FF0000;
                                line-height: 100px;
                                float: left;
                                margin-left: 100px;
                            }
                            .style{
                                width: 54px;
                                height: 100%;
                                float: left;
                                font-size: 14px;
                                color: #333333;
                                line-height: 100px;
                                float: left;
                                margin-left: 108px;
                            }
                            .time{
                                width: 152px;
                                height: 100%;
                                float: left;
                                font-size: 14px;
                                color: #333;
                                line-height: 100px;
                                margin-left: 78px;
                            }
                            .total{
                                width: 40px;
                                height: 100%;
                                font-size: 14px;
                                color: #333;
                                line-height: 100px;
                                float: left;
                                margin-left: 80px;
                            }
                            .operate{
                                width: 124px;
                                height: 100%;
                                float: right;
                                margin-right: 56px;
                                display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                justify-content: space-around;
                                .edit, .lower, .lower{
                                    font-size: 14px;
                                    color: #5DB9FF;
                                    line-height: 100px;
                                    cursor: pointer;
                                }
                                
                            }
                        }
                        .loadingPanel {
                            position        : absolute;
                            width           : 100%;
                            height          : 709px;
                            background-color: white;
                            z-index         : 999;
                            pointer-events  : none;
                            display         : flex;
                            flex-wrap       : nowrap;
                            flex-direction  : column;
                            justify-content : center;
                            align-items     : center;
                            z-index         : 999;
                            pointer-events  : auto;
                            left            : 0;
                            top: 108px;

                            img {
                                width: .6rem;

                            }
                        }
                    }
                }
                
            }
        }
        .upDataPop{
            width: 100%;
            height: 100vh;
            background-color: rgba(51, 51, 51, .3);
            position: fixed;
            top: 0;
            left: 0;
            z-index: 99999;
            .upDataContent{
                width: 564px;
                height: 171px;
                border-radius: 4px;
                background-color: #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                .tips{
                    font-size: 22px;
                    color: #333;
                    margin-top: 20px;
                    text-align: left;
                    text-indent: 24px;
                }
                .tipsText{
                    font-size: 16px;
                    color: #666;
                    margin-top: 20px;
                    text-align: left;
                    text-indent: 24px;
                }
                .tipsBot{
                    width: 188px;
                    height: 36px;
                    margin-top: 35px;
                    float: right;
                    margin-right: 34px;
                    .sure{
                        width: 84px;
                        height: 100%;
                        background-color: #E80510;
                        font-size: 16px;
                        color: #fff;
                        line-height: 36px;
                        float: left;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                    .cancel{
                        width: 84px;
                        height: 100%;
                        border:1px solid #E80510;
                        box-sizing: border-box;
                        font-size: 16px;
                        color: #E80510;
                        line-height: 34px;
                        float: right;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
</style>